import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import { IDataStructure } from "../../services/backend/DataSchemaService";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { useCallback } from "react";
import SaveIcon from "@mui/icons-material/Save";
import styled from "@emotion/styled";

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
export interface MigrationScriptEditorProps {
  visible: boolean;
  script: string;
  dataStructure: IDataStructure;
  onClose: () => void;
  onSave: () => void;
  setScriptData: (value: string) => void;
}

const MigrationScriptEditor = ({
  visible,
  script,
  dataStructure,
  onClose,
  onSave,
  setScriptData,
}: MigrationScriptEditorProps) => {
  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  const codeMirrorChangeHandler = useCallback((value: string) => {
    setScriptData(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={visible} onClose={dialogCloseHandler} fullScreen>
      <DialogTitle>{`Migration script for the data structure: ${dataStructure.name}`}</DialogTitle>
      <DialogContent>
        <Header>
          <Button variant="contained" startIcon={<SaveIcon />} onClick={onSave} sx={{ marginLeft: 1 }}>
            Save
          </Button>
          <div style={{ flexGrow: 1 }} />
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </Header>
        <CodeMirror
          value={script}
          height="82vh"
          width="97vw"
          extensions={[javascript()]}
          onChange={codeMirrorChangeHandler}
          style={{ marginTop: 16 }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default MigrationScriptEditor;
