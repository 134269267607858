import { fetchWithErrorHandling, buildGetOptions, buildPostOptions, buildDeleteOptions } from "../FetchUtils";
import { useAuthUser } from "react-auth-kit";
import { useContext } from "react";
import { AppContext } from "../../utils/AppContext";

export enum LoadTesterState {
  UNKNOWN = "unknown",
  RUNNING = "running",
  TERMINATED = "terminated",
  PENDING_TERMINATION = "pending_termination",
  PENDING_INITIALIZATION = "pending_initialization",
}

export interface ILoadTesterBase {
  instanceName: string;
  scriptArgs: string;
  townsfolkClientZip: string;
  batchScriptZip: string;
  instanceType: string;
  username: string;
  autoShutdownTime: number;
}

export interface ILoadTesterInfo extends ILoadTesterBase {
  logName: string;
  state: LoadTesterState;
  launchTime: number;
  publicIpAddress: string;
  branch: string;
}

export interface ILoadTesterParams extends ILoadTesterBase {
  password: string;
  vpcId: string;
  subnetId: string;
  keypair: string;
  branch?: string;
}

export const list = async (accessToken: string, baseURL: string) => {
  return fetchWithErrorHandling(baseURL + "v1/load-tester/list", buildGetOptions(accessToken));
};

export const create = async (accessToken: string, baseURL: string, params: ILoadTesterParams) => {
  return fetchWithErrorHandling(baseURL + `v1/load-tester/create`, buildPostOptions(accessToken, params));
};

export const terminate = async (accessToken: string, baseURL: string, instanceName: string) => {
  return fetchWithErrorHandling(baseURL + `v1/load-tester/terminate/${instanceName}`, buildDeleteOptions(accessToken));
};

export const uploadClientZip = async (accessToken: string, baseURL: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const options = buildPostOptions(accessToken, null, formData);
  return fetchWithErrorHandling(baseURL + `v1/load-tester/upload-client-zip`, options);
};

export const uploadBatchScript = async (accessToken: string, baseURL: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const options = buildPostOptions(accessToken, null, formData);
  return fetchWithErrorHandling(baseURL + `v1/load-tester/upload-batch-script`, options);
};

export const useLoadTesterService = () => {
  const authUser = useAuthUser();
  const accessToken = authUser()?.access_token;
  const appContext = useContext(AppContext);

  const getBaseURL = () => appContext?.selectedEnvironment?.baseURL || "http://localhost:3001/";

  return {
    list: () => list(accessToken, getBaseURL()),
    create: (params: ILoadTesterParams) => create(accessToken, getBaseURL(), params),
    terminate: (instanceName: string) => terminate(accessToken, getBaseURL(), instanceName),
    uploadClientZip: (file: File) => uploadClientZip(accessToken, getBaseURL(), file),
    uploadBatchScript: (file: File) => uploadBatchScript(accessToken, getBaseURL(), file),
  };
};

export const canBeTerminated = (t: ILoadTesterInfo) => {
  return t.state === LoadTesterState.RUNNING || t.state === LoadTesterState.PENDING_TERMINATION;
};
