import React, { useState, useEffect, useContext, useMemo } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LaunchIcon from "@mui/icons-material/Launch";
import { AppContext } from "utils/AppContext";
import { useSystemService } from "../../services/backend/SystemService";
import { Button } from "@mui/material";

const SwaggerDoc = () => {
  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const systemService = useSystemService();

  useEffect(() => {
    const getSwaggerAccessData = async (): Promise<void> => {
      const auth = await systemService.swaggerAccess();
      setLogin(auth.login);
      setPassword(auth.password);
    };

    (async () => getSwaggerAccessData())();
  }, [systemService]);

  const context = useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Backend API Documentation");
    context?.setTargetAPI("environment");
  }, [context]);

  const baseURL = useMemo(() => context?.selectedEnvironment?.baseURL || "http://localhost:3001/", [context]);

  return (
    <>
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined">
          <CardContent>
            <Typography mb={2} variant="h5" color="text.secondary" gutterBottom>
              Access to the Swagger/OpenAPI documentation:
            </Typography>
            <div style={{ flexDirection: "row", display: "flex", width: "180px" }}>
              <Typography variant="body1">Login:</Typography>
              <div style={{ flexGrow: 1 }} />
              <Typography variant="body1" color="text.secondary">
                {login}
              </Typography>
            </div>
            <div style={{ flexDirection: "row", display: "flex", width: "180px" }}>
              <Typography variant="body1">Password:</Typography>
              <div style={{ flexGrow: 1 }} />
              <Typography variant="body1" color="text.secondary">
                {password}
              </Typography>
            </div>
            <Button
              variant="contained"
              startIcon={<LaunchIcon />}
              sx={{ marginTop: 1 }}
              onClick={() => {
                window.open(`${baseURL}api-docs#/White-Label`);
              }}
            >
              Open in a new window
            </Button>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default SwaggerDoc;
