/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect, useState } from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { BackendImagesTable } from "./BackendImagesTable";
import { BackendImageFormData, BackendImageForm } from "./BackendImageForm";
import { YesNoPopup } from "../../../components/YesNoPopup";
import { IBackendImage, useBackendImageService } from "../../../services/registry/BackendImageService";
import { AppContext } from "utils/AppContext";

const BackendImages = () => {
  const backendImageService = useBackendImageService();

  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [rows, setRows] = useState<IBackendImage[]>([]);
  const [selectedItem, setSelectedItem] = useState<IBackendImage | undefined>(undefined);
  const [backendImageFormVisible, setBackendImageFormVisible] = useState<boolean>(false);
  const [deletePopupVisible, setDeletePopupVisible] = useState<boolean>(false);

  const fetchRows = async () => {
    try {
      const { list } = await backendImageService.listBackendImages();
      setRows(list as IBackendImage[]);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not fetch the list of backend images.");
      setAlertSeverity("error");
    }
  };

  useEffect(() => {
    (async () => fetchRows())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addButtonClickHandler = () => {
    setBackendImageFormVisible(true);
  };

  const backendImageFormDismissHandler = () => {
    setBackendImageFormVisible(false);
  };

  const backendImageFormAcceptHandler = async (data: BackendImageFormData) => {
    try {
      await backendImageService.addBackendImage(data.name, data.commitHash, data.branch);
      setBackendImageFormVisible(false);
      await fetchRows();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not add the new backend image.");
      setAlertSeverity("error");
    }
  };

  const deletePopupNoHandler = () => {
    setDeletePopupVisible(false);
  };

  const deletePopupYesHandler = async () => {
    if (!selectedItem) {
      return;
    }
    try {
      await backendImageService.removeBackendImage(selectedItem.id);
      setDeletePopupVisible(false);
      fetchRows();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not remove the selected backend image.");
      setAlertSeverity("error");
    }
  };

  const removeMenuClickHandler = () => {
    setDeletePopupVisible(true);
  };

  const showDeploymentsMenuClickHandler = () => {
    // TODO
  };

  const alertCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(undefined);
  };
  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Backend Images");
    context?.setTargetAPI("registry");
  }, [context]);
  return (
    <>
      <BackendImagesTable
        rows={rows}
        setSelectedItem={setSelectedItem}
        onAddButtonClick={addButtonClickHandler}
        onRemoveMenuClick={removeMenuClickHandler}
        onShowDeploymentsMenuClick={showDeploymentsMenuClickHandler}
      />
      <BackendImageForm
        visible={backendImageFormVisible}
        onDismiss={backendImageFormDismissHandler}
        onAccept={backendImageFormAcceptHandler}
      />
      <YesNoPopup
        visible={deletePopupVisible}
        title={"Remove Backend Image"}
        message={`Are you sure you want to remove the Backend Image named <b>${selectedItem?.name}</b> with ID <b>${selectedItem?.id}</b>?<br/>
        If you remove this Backend Image, backend Environments won't be able to use it anymore.`}
        onNo={deletePopupNoHandler}
        onYes={deletePopupYesHandler}
      />
      <Snackbar open={alertMessage !== undefined} autoHideDuration={5000} onClose={alertCloseHandler}>
        <Alert onClose={alertCloseHandler} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BackendImages;
