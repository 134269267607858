import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import { codeChallengeKeyPrefix } from "../../services/auth/AuthService";
import { getTokens } from "../../services/aws/CognitoService";
import Routes from "../../components/routing/Routes";
import { Background } from "../../components/Background";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { BackendError } from "../../services/FetchUtils";

const LoginCallback = () => {
  const navigate = useNavigate();
  const signIn = useSignIn();

  const getCognitoTokens = async (): Promise<void> => {
    const errorMessage = "Failed to login, try again.";
    const navigateOptions = { state: { errorMessage } };

    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const authorizationCode = searchParams.get("code") || "";

    const state = searchParams.get("state");
    const codeVerifier = sessionStorage.getItem(`${codeChallengeKeyPrefix}-${state}`) || "";

    if (!authorizationCode) {
      navigate(Routes.landing, navigateOptions);
    }

    try {
      const fetchedTokens = await getTokens(authorizationCode, codeVerifier);
      console.log("getCognitoTokens", { fetchedTokens });
      if (
        signIn({
          token: fetchedTokens.access_token,
          expiresIn: fetchedTokens.expires_in,
          tokenType: fetchedTokens.token_type,
          refreshToken: fetchedTokens.refresh_token,
          refreshTokenExpireIn: fetchedTokens.expires_in,
          authState: fetchedTokens,
        })
      ) {
        const requestedRoute = sessionStorage.getItem("requestedRoute");
        // clear the session storage if login is successful
        // this is a workaround of React rendering twice
        // two code verifiers are being stored in development mode
        // one of them will fail and one will be successful
        // doing sessionStorage.clear(`${codeChallengeKeyPrefix}-${state}`)
        // removes the successful code verifier, but the other one
        // will be present in the session, sessionStorage.clear()
        // ensures both code verifier are removed
        for (const key of Object.keys(sessionStorage)) {
          if (key.startsWith(codeChallengeKeyPrefix)) {
            sessionStorage.removeItem(key);
          }
        }
        sessionStorage.setItem("lastSuccessfulLoginTime", Date.now().toString());
        navigate(requestedRoute ?? Routes.home);
      } else {
        navigate(Routes.landing, navigateOptions);
      }
    } catch (err) {
      if (err instanceof BackendError) {
        // strict mode causing issue here because the API is called twice
        // one call is successful, but one fails with 400 (invalid_grant)
        // there is no way if the first or second request will be the one that fails
        // we could disable strict mode to make this work in dev
        // for now the "invalid_grant" error is being ignored
        const res = err.getResponse();
        const data = await res.json();
        if (!(res.status === 400 && data.error === "invalid_grant")) {
          navigate(Routes.landing, navigateOptions);
        }
      }

      navigate(Routes.landing, navigateOptions);
    }
  };

  useEffect(() => {
    (async () => getCognitoTokens())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Background>
      <LoadingOverlay />
    </Background>
  );
};

export default LoginCallback;
