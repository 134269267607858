import { useState, useMemo, MouseEvent } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Badge, Box, Button, IconButton, Stack, TablePagination, TableSortLabel, Toolbar } from "@mui/material";
import { DateTime } from "luxon";
import { Order, stableSort, getComparator } from "utils/Table";
import { IWad, WadState } from "services/registry/WadsService";
import AddIcon from "@mui/icons-material/Add";
import { visuallyHidden } from "@mui/utils";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SimpleDialogList from "components/SimpleDialogList";
import ChipsArray from "components/ChipsArray";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";

interface HeadCell {
  id: keyof IWad | string;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    label: "ID",
    padding: "none",
  },
  {
    id: "labels",
    label: "Labels",
  },
  {
    id: "files",
    label: "Files",
  },
  {
    id: "state",
    label: "State",
  },
  {
    id: "createdTime",
    label: "Creation Date",
  },
  {
    id: "actions",
    label: "",
  },
];

interface WadsTableHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IWad | string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const WadsTableHead = ({ order, orderBy, onRequestSort }: WadsTableHeadProps) => {
  const createSortHandler = (property: keyof IWad | string) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
interface WadsTableToolbarProps {
  onAddButtonClick: () => void;
}

const WadsTableToolbar = ({ onAddButtonClick }: WadsTableToolbarProps) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Button variant="outlined" startIcon={<AddIcon />} onClick={onAddButtonClick}>
        Add
      </Button>
    </Toolbar>
  );
};

export interface WadsTableProps {
  rows: IWad[];
  selectedItem: IWad | undefined;
  setSelectedItem: React.Dispatch<React.SetStateAction<IWad | undefined>>;
  onAddButtonClick: () => Promise<void>;
  onEditButtonClick: () => void;
  onRemoveButtonClick: (option: boolean) => void;
  onPublishButtonClick: (option: boolean) => void;
}

export const WadsTable = ({
  rows,
  selectedItem,
  setSelectedItem,
  onAddButtonClick,
  onEditButtonClick,
  onRemoveButtonClick,
  onPublishButtonClick,
}: WadsTableProps) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof IWad | string>("createdTime");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openLabelDialog, setOpenLabelDialog] = useState(false);
  const requestSortHandler = (event: MouseEvent<unknown>, property: keyof IWad | string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showWadFiles = (row: IWad) => {
    setSelectedItem(row);
    setOpenLabelDialog(true);
  };

  const handleCloseLabelDialog = () => {
    setOpenLabelDialog(false);
  };

  const handleEditClick = (event: React.MouseEvent<unknown>, row: IWad) => {
    setSelectedItem(row);
    onEditButtonClick();
  };

  const handleRemoveClick = (evt: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, row: IWad) => {
    setSelectedItem(row);
    onRemoveButtonClick(true);
  };

  const handlePublishClick = (evt: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, row: IWad) => {
    setSelectedItem(row);
    onPublishButtonClick(true);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [rows, order, orderBy, page, rowsPerPage]);

  const isSelected = (row: IWad, selectedItem: IWad | undefined) => selectedItem !== undefined && row.id === selectedItem.id;

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <WadsTableToolbar onAddButtonClick={onAddButtonClick} />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} size="small">
              <WadsTableHead order={order} orderBy={orderBy} onRequestSort={requestSortHandler} rowCount={rows.length} />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const labelId = `wads-table-checkbox-${index}`;
                  const creationDate = DateTime.fromSeconds(row.createdTime).toLocaleString(
                    DateTime.DATETIME_SHORT_WITH_SECONDS,
                  );

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      onClick={() => setSelectedItem(row)}
                      tabIndex={-1}
                      key={row.id}
                      selected={isSelected(row, selectedItem)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="normal" />
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.id}
                      </TableCell>
                      <TableCell align="left">
                        <ChipsArray
                          data={[...row.labels]}
                          setChipData={() => {
                            return;
                          }}
                          showDelete={false}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            showWadFiles(row);
                          }}
                        >
                          <Badge badgeContent={row.files.length} color="primary" showZero>
                            <ListAltIcon fontSize="inherit" />
                          </Badge>
                        </IconButton>
                      </TableCell>
                      <TableCell align="left">{row.state}</TableCell>
                      <TableCell align="left">{creationDate}</TableCell>
                      <TableCell>
                        <Stack spacing={1} direction="row" justifyContent="flex-end">
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<EditIcon />}
                            onClick={(event) => handleEditClick(event, row)}
                            disabled={row.state === WadState.PUBLISHED}
                          >
                            Edit
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteIcon />}
                            onClick={(evt) => handleRemoveClick(evt, row)}
                            disabled={row.state === WadState.PUBLISHED}
                          >
                            Remove
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<PublishIcon />}
                            disabled={row.state === WadState.PUBLISHED}
                            onClick={(evt) => handlePublishClick(evt, row)}
                          >
                            Publish
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={pageChangeHandler}
            onRowsPerPageChange={rowsPerPageChangeHandler}
          />
        </Paper>
      </Box>
      <SimpleDialogList
        title="Files"
        listItems={selectedItem?.files.map((f) => f.name)}
        emptyListMessage="No files to display"
        open={openLabelDialog}
        onClose={handleCloseLabelDialog}
      />
    </>
  );
};
