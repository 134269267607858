import * as React from "react";
import { IBackendImage } from "../../../services/registry/BackendImageService";
import { BackendImagePicker } from "../../../components/BackendImagePicker";
import { FormHelperText, MenuItem } from "@mui/material";
import { useState } from "react";
import TextField from "@mui/material/TextField";

export interface DeploymentPopupProps {
  visible: boolean;
  onCancel: () => void;
  onDeploy: (backendImage: IBackendImage, backendFlavor: string) => Promise<void>;
}

export const DeploymentPopup = ({ visible, onCancel, onDeploy }: DeploymentPopupProps) => {
  const flavors = [
    { key: "COMPLETE", label: "Complete monolith with all modules" },
    { key: "REGISTRY", label: "Registry outpost only" },
    { key: "CITADEL", label: "Main citadel without any outpost" },
    { key: "DATA_PERSISTENCE", label: "Data-persistence outpost with the backup system" },
  ];
  const [flavor, setFlavor] = useState<string | undefined>(undefined);
  const [flavorError, setFlavorError] = useState<string | undefined>(undefined);

  const actionButtonClickHandler = (backendImage: IBackendImage) => {
    if (!flavor) {
      setFlavorError("You must select an flavor");
      return;
    } else {
      onDeploy(backendImage, flavor);
    }
  };

  return (
    <BackendImagePicker
      visible={visible}
      dialogTitle="Deploy Backend Image to Environment"
      actionButtonLabel="Deploy"
      onCancel={onCancel}
      onAction={actionButtonClickHandler}
    >
      <>
        <TextField
          value={flavor}
          onChange={(e) => setFlavor(e.target.value)}
          select
          label="Flavor"
          style={{ marginTop: "20px", width: "40%" }}
        >
          {flavors.map((flavor) => (
            <MenuItem key={flavor.key} value={flavor.key}>
              {flavor.label}
            </MenuItem>
          ))}
        </TextField>
        {flavorError && <FormHelperText error={true}>{flavorError}</FormHelperText>}
      </>
    </BackendImagePicker>
  );
};
