/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect, useState } from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { AppContext } from "utils/AppContext";
import { GameServerActionsTable } from "./GameServerActionsTable";
import { IGameServerAction, useGameServerService } from "../../services/backend/GameServerService";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { GameServerActionSendForm, IGameServerActionFormData } from "./GameServerActionSendForm";
import SimpleDialogList from "../../components/SimpleDialogList";

/* eslint-disable  @typescript-eslint/no-unused-vars */
const GameServerActions = () => {
  const gameServerService = useGameServerService();

  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [rows, setRows] = useState<IGameServerAction[]>([]);
  const [gameServerActionSendFormVisible, setGameServerActionSendFormVisible] = useState<boolean>(false);
  const [statesByServerDialogVisible, setStatesByServerDialogVisible] = useState<boolean>(false);
  const [statesByServer, setStatesByServer] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchRows = async () => {
    try {
      const { list } = await gameServerService.listActions();
      setRows(list as IGameServerAction[]);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not fetch the list of game server actions.");
      setAlertSeverity("error");
    }
  };

  const refreshButtonClickHandler = async () => {
    setLoading(true);
    await fetchRows();
    setLoading(false);
  };

  const gameServerActionSendFormAcceptHandler = async (data: IGameServerActionFormData) => {
    try {
      setLoading(true);
      setGameServerActionSendFormVisible(false);
      await gameServerService.sendAction(data.action, data.serverIds, data.playerIds, data.extraArgs);
      await fetchRows();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not send that game server action.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const alertCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(undefined);
  };

  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Game Servers");
    context?.setTargetAPI("environment");
    (async () => fetchRows())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const viewStatesByServerButtonClickHandler = (actionData: IGameServerAction) => {
    if (actionData) {
      setStatesByServer(
        Object.entries(actionData.statesByServerId).map((e) => {
          const [serverId, actionState] = e;
          return `${serverId} => ${actionState}`;
        }),
      );
    } else {
      setStatesByServer([]);
    }
    setStatesByServerDialogVisible(true);
  };

  return (
    <>
      <GameServerActionsTable
        rows={rows}
        onSendButtonClick={() => setGameServerActionSendFormVisible(true)}
        onRefreshButtonClick={refreshButtonClickHandler}
        onViewStatesByServerButtonClick={viewStatesByServerButtonClickHandler}
      />
      <GameServerActionSendForm
        visible={gameServerActionSendFormVisible}
        onDismiss={() => setGameServerActionSendFormVisible(false)}
        onAccept={gameServerActionSendFormAcceptHandler}
      />
      <SimpleDialogList
        title="States by Server"
        listItems={statesByServer}
        emptyListMessage="Nothing to display"
        open={statesByServerDialogVisible}
        onClose={() => setStatesByServerDialogVisible(false)}
      />
      <Snackbar open={alertMessage !== undefined} autoHideDuration={5000} onClose={alertCloseHandler}>
        <Alert onClose={alertCloseHandler} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {loading && <LoadingOverlay />}
    </>
  );
};

export default GameServerActions;
