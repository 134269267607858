import { useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, CircularProgress, Grid, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { IPackage } from "services/registry/PackageService";

export type PkgFormType = "new" | "edit";

export interface PkgFormData {
  id: string;
  wads: string[];
}

export interface PkgFormProps {
  visible: boolean;
  type: PkgFormType;
  initialData: IPackage | undefined;
  onDismiss: () => void;
  onAccept: (data: PkgFormData) => Promise<void>;
  wadsFilter: string[];
}

export const PackageForm = ({ visible, type, initialData, onDismiss, onAccept, wadsFilter }: PkgFormProps) => {
  const [loading, setLoading] = useState(false);
  const [pkgWads, setPkgWads] = useState<string[] | undefined>(initialData?.wads ? [...initialData.wads] : []);
  const refNewWad = useRef<TextFieldProps>(null);

  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  const okButtonClickHandler = async () => {
    const id = initialData?.id ?? "";
    if (!id) {
      return;
    }

    setLoading(true);
    await onAccept({ id, wads: pkgWads ?? [] });
    setLoading(false);
  };

  function removeWad(item: string): void {
    const newArray = pkgWads?.filter((p) => p !== item);
    setPkgWads(newArray ? [...newArray] : []);
  }

  interface WadListProps {
    content: string[];
  }
  const WadList = ({ content }: WadListProps) => {
    return (
      <List dense={true}>
        {content && content.length ? (
          content.map((s, idx) => {
            return (
              <ListItem
                key={idx}
                secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={() => removeWad(s)}>
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={s} />
              </ListItem>
            );
          })
        ) : (
          <ListItem disableGutters>
            <ListItemText primary="No wads to display" />
          </ListItem>
        )}
      </List>
    );
  };

  const addWad = () => {
    if (!refNewWad.current?.value) return;
    const selectedWads = pkgWads ?? [];
    const newValue = refNewWad.current.value as string;

    if (selectedWads.includes(newValue)) return;

    selectedWads.push(newValue);
    setPkgWads([...selectedWads]);
  };

  return (
    <Dialog open={visible} onClose={dialogCloseHandler}>
      <DialogTitle>{(type === "new" ? "New" : "Edit") + " Package"}</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <DialogContentText>Please fill up the information below:</DialogContentText>
            <Typography component={"div"} variant="body1" color="text.secondary">
              <fieldset>
                <legend>Wads</legend>
                <Grid container alignItems="center" justifyContent={"flex-end"}>
                  <Grid item xs={10}>
                    <Autocomplete
                      id="wads"
                      freeSolo
                      options={wadsFilter}
                      renderInput={(params) => <TextField {...params} inputRef={refNewWad} />}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={addWad}>
                      {" "}
                      <AddIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <WadList content={pkgWads ?? []} />
                  </Grid>
                </Grid>
              </fieldset>
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss} color="error" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={okButtonClickHandler} disabled={loading}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
