export interface IPlayerMetadata {
  id: string;
  schemaVersion: number;
  version: number;
  hasAcceptedUserAgreement: boolean;
  reportStatus: ReportStatus;
  banReason: string;
  registrationDate?: number;
  lastLoginDate?: number;
  serverId?: string;
  playerId?: string;
  isQaUser?: boolean;
}

export enum ReportStatus {
  Banned = "banned",
  None = "none",
  Reported = "reported",
}
