/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { ReactNode, useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import { AlertColor, Button, CircularProgress, FormControl, FormHelperText, InputAdornment } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { IServerImage, useServerImageService } from "../services/registry/ServerImageService";
import { Notify } from "./Notify";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { DateTime } from "luxon";
import ListItemButton from "@mui/material/ListItemButton";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";

export interface ServerImagePickerProps {
  visible: boolean;
  dialogTitle: string;
  actionButtonLabel: string;
  onCancel: () => void;
  onAction: (serverImage: IServerImage) => void;
  children?: ReactNode;
}

export const ServerImagePicker = ({
  visible,
  dialogTitle,
  actionButtonLabel,
  onCancel,
  onAction,
  children,
}: ServerImagePickerProps) => {
  const serverImageService = useServerImageService();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [serverImages, setServerImages] = useState<IServerImage[]>([]);
  const [filteredServerImages, setFilteredServerImages] = useState<IServerImage[]>([]);
  const [serverImageError, setServerImageError] = useState<string | undefined>(undefined);
  const [selectedItem, setSelectedItem] = useState<IServerImage | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    (async () => fetchServerImages())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredServerImages(
      serverImages.filter(
        (img) => img.name.includes(searchValue) || img.id.includes(searchValue) || img.branch.includes(searchValue),
      ),
    );
  }, [searchValue, serverImages]);

  const fetchServerImages = async () => {
    try {
      const res = await serverImageService.listServerImages();
      const list = res.list as IServerImage[];
      const seen = new Set<string>();
      setServerImages(
        list
          .sort((a, b) => b.creationDate - a.creationDate)
          .filter((img) => (seen.has(img.name) ? false : seen.add(img.name))),
      );
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to load backend images.");
      setAlertSeverity("error");
    }
  };

  const actionButtonClickHandler = async () => {
    if (!selectedItem) {
      setServerImageError("You must select a Backend Image");
      return;
    } else {
      setLoading(true);
      setServerImageError(undefined);
      await onAction(selectedItem);
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={visible} fullWidth maxWidth="md">
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  id="search-input"
                  label="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  value={searchValue}
                  onChange={(event) => setSearchValue(event.target.value)}
                  style={{ marginBottom: 12, width: "100%" }}
                />
                <Paper elevation={0} style={{ maxHeight: 300, overflow: "auto" }}>
                  <List dense disablePadding>
                    {filteredServerImages.map((img) => {
                      const creationDate = DateTime.fromSeconds(img.creationDate).toLocaleString(
                        DateTime.DATETIME_SHORT_WITH_SECONDS,
                      );
                      return (
                        <ListItem key={img.id} value={img.id} disableGutters>
                          <ListItemButton
                            selected={selectedItem !== undefined && selectedItem.id === img.id}
                            onClick={() => setSelectedItem(img)}
                          >
                            <ListItemText primary={img.name} secondary={`[${img.id}] ${creationDate}`} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
                {serverImageError && <FormHelperText error={true}>{serverImageError}</FormHelperText>}
                {children}
              </FormControl>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel} color="error" disabled={loading}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={actionButtonClickHandler} disabled={loading}>
            {actionButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
    </>
  );
};
