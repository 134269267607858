import * as React from "react";
import { useState, useMemo, MouseEvent, ChangeEvent, Dispatch, SetStateAction } from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import Toolbar from "@mui/material/Toolbar";
import { Autocomplete, Button, Stack, TextField, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import { getComparator, Order, stableSort } from "../../utils/Table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IBookmarkedPlayer } from "./IBookmarkedPlayer";
import SelectAllIcon from "@mui/icons-material/SelectAll";

interface HeadCell {
  id: keyof IBookmarkedPlayer | string;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    label: "Player ID",
    padding: "none",
  },
  {
    id: "labels",
    label: "Labels",
  },
  {
    id: "actions",
    label: "",
  },
];

interface PlayersListHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IBookmarkedPlayer | string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const PlayersListHead = ({ order, orderBy, onRequestSort }: PlayersListHeadProps) => {
  const createSortHandler = (property: keyof IBookmarkedPlayer | string) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface PlayersListToolbarProps {
  playerIds: string[];
  onAddButtonClick: (id: string) => void;
}

const PlayersListToolbar = ({ playerIds, onAddButtonClick }: PlayersListToolbarProps) => {
  const [idValue, setIdValue] = useState<string | null>(null);
  const [inputIdValue, setInputIdValue] = useState("");

  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Autocomplete
        value={idValue}
        id="player-id"
        freeSolo
        options={playerIds}
        renderInput={(params) => <TextField {...params} label="Player ID" sx={{ minWidth: "240px" }} />}
        onChange={(event, newValue: string | null) => {
          setIdValue(newValue);
        }}
        inputValue={inputIdValue}
        onInputChange={(event, newInputValue) => setInputIdValue(newInputValue)}
        size="small"
      />
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => onAddButtonClick(inputIdValue)}
        sx={{ marginLeft: 1, height: "40px" }}
        disabled={!inputIdValue}
      >
        Add
      </Button>
    </Toolbar>
  );
};

export interface PlayersListProps {
  rows: IBookmarkedPlayer[];
  knownPlayerIds: string[];
  selectedItem: IBookmarkedPlayer | undefined;
  setSelectedItem: Dispatch<SetStateAction<IBookmarkedPlayer | undefined>>;
  onAddButtonClick: (id: string) => void;
  onEditButtonClick: (player: IBookmarkedPlayer) => void;
  onRemoveButtonClick: (player: IBookmarkedPlayer) => void;
  onBatchOperationsMenuButtonClick: () => void;
}

export const PlayersList = ({
  rows,
  knownPlayerIds,
  selectedItem,
  setSelectedItem,
  onAddButtonClick,
  onEditButtonClick,
  onRemoveButtonClick,
  onBatchOperationsMenuButtonClick,
}: PlayersListProps) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof IBookmarkedPlayer | string>("creationDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const requestSortHandler = (event: MouseEvent<unknown>, property: keyof IBookmarkedPlayer | string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [rows, order, orderBy, page, rowsPerPage]);

  const handleClick = (row: IBookmarkedPlayer, action: (player: IBookmarkedPlayer) => void) => {
    setSelectedItem(row);
    action(row);
  };

  const isSelected = (row: IBookmarkedPlayer, selectedItem: IBookmarkedPlayer | undefined) =>
    selectedItem !== undefined && row.id === selectedItem.id;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <PlayersListToolbar playerIds={knownPlayerIds} onAddButtonClick={onAddButtonClick} />
        <TableContainer>
          <Table sx={{ minWidth: 350 }} size="small">
            <PlayersListHead order={order} orderBy={orderBy} onRequestSort={requestSortHandler} rowCount={rows.length} />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `players-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    onClick={() => setSelectedItem(row)}
                    tabIndex={-1}
                    key={row.id}
                    selected={isSelected(row, selectedItem)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="normal" />
                    <TableCell component="th" id={labelId} scope="row" padding="none" style={{ fontFamily: "monospace" }}>
                      {row.id}
                    </TableCell>
                    <TableCell align="left">{row.labels}</TableCell>
                    <TableCell>
                      <Stack spacing={1} direction="row" justifyContent="flex-end">
                        <IconButton size="small" onClick={() => handleClick(row, onEditButtonClick)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton size="small" color="error" onClick={() => handleClick(row, onRemoveButtonClick)}>
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={pageChangeHandler}
          onRowsPerPageChange={rowsPerPageChangeHandler}
        />
        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
          <Button
            variant="contained"
            startIcon={<SelectAllIcon />}
            onClick={onBatchOperationsMenuButtonClick}
            sx={{ marginLeft: 1, height: "40px" }}
          >
            Batch Operations...
          </Button>
        </Toolbar>
      </Paper>
    </Box>
  );
};
