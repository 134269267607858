import { getRegistryURL } from "./Registry";
import { buildGetOptions, fetchWithErrorHandling, buildDeleteOptions, buildPostOptions } from "../FetchUtils";
import { useAuthUser } from "react-auth-kit";

export interface IBackendImage {
  id: string;
  name: string;
  creationDate: number;
  commitHash: string;
  branch: string;
}

export const listBackendImages = async (accessToken: string) => {
  return fetchWithErrorHandling(getRegistryURL() + "/v1/registry/backend-image/list", buildGetOptions(accessToken));
};

export const addBackendImage = async (accessToken: string, name: string, commitHash: string, branch: string) => {
  return fetchWithErrorHandling(
    getRegistryURL() + "/v1/registry/backend-image/add",
    buildPostOptions(accessToken, { name, commitHash, branch }),
  );
};

export const removeBackendImage = async (accessToken: string, id: string) => {
  return fetchWithErrorHandling(
    getRegistryURL() + "/v1/registry/backend-image/remove/" + id,
    buildDeleteOptions(accessToken),
  );
};

export const useBackendImageService = () => {
  const authUser = useAuthUser();

  return {
    listBackendImages: () => listBackendImages(authUser()?.access_token),
    addBackendImage: (name: string, commitHash: string, branch: string) =>
      addBackendImage(authUser()?.access_token, name, commitHash, branch),
    removeBackendImage: (id: string) => removeBackendImage(authUser()?.access_token, id),
  };
};
