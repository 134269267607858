const Routes = {
  login: "/login",
  loginCallback: "/login/callback",
  landing: "/",
  home: "/home",
  profile: "/profile",
  environments: "/environments",
  staticData: "/static-data",
  clientBuilds: "/client-builds",
  backendImages: "/backend-images",
  serverImages: "/server-images",
  dataSchemaEditor: "/data-schema-editor",
  dataStructureEditor: "/data-schema-editor/data-structure-editor",
  managedParameters: "/managed-parameters",
  gameServers: "/game-servers",
  dataEntityEditor: "/data-entity-editor",
  loadTesters: "/load-testers",
  logging: "/logging",
  cwLogging: "cwlogs",
  integrationTests: "integration-tests",
  swaggerDoc: "/swagger-doc",
  players: "/players",
  gameServerActions: "/game-server-actions",
  artAssetsIdsGenerator: "/art-assets-ids-generator",
  artAssetsIdsBrowser: "/art-assets-ids-browser",
  artAssetsIdsRawSheet: "/art-assets-ids-raw-sheet",
};

export default Routes;
