/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { LoadTestersTable } from "./LoadTestersTable";
import { LoadTesterForm, ILoadTesterFormData } from "./LoadTesterForm";
import { YesNoPopup } from "../../components/YesNoPopup";
import {
  canBeTerminated,
  ILoadTesterInfo,
  ILoadTesterParams,
  useLoadTesterService,
} from "../../services/backend/LoadTesterService";
import { AppContext } from "utils/AppContext";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { useNavigate } from "react-router-dom";
import Routes from "../../components/routing/Routes";
import { FileUpload, FileUploadData } from "../../components/FileUpload";
import { LogPopup } from "../../components/LogPopup";

const LoadTesters = () => {
  const loadTesterService = useLoadTesterService();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [rows, setRows] = useState<ILoadTesterInfo[]>([]);
  const [selectedItems, setSelectedItems] = useState<ILoadTesterInfo[]>([]);
  const [loadTesterFormVisible, setLoadTesterFormVisible] = useState<boolean>(false);
  const [terminatePopupVisible, setTerminatePopupVisible] = useState<boolean>(false);
  const [uploadClientZipFormVisible, setUploadClientZipFormVisible] = useState(false);
  const [uploadBatchScriptFormVisible, setUploadBatchScriptFormVisible] = useState(false);
  const [logPopupVisible, setLogPopupVisible] = useState(false);
  const listTimeoutId = useRef<any>(undefined);

  const fetchRows = async () => {
    try {
      const { list } = await loadTesterService.list();
      setRows(list as ILoadTesterInfo[]);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not fetch the list of load testers.");
      setAlertSeverity("error");
    }
    if (listTimeoutId.current) {
      clearTimeout(listTimeoutId.current);
    }
    listTimeoutId.current = setTimeout(fetchRows, 15000);
  };

  const createButtonClickHandler = () => {
    setSelectedItems([]);
    setLoadTesterFormVisible(true);
  };

  const cloneMenuClickHandler = () => {
    setLoadTesterFormVisible(true);
  };

  const loadTesterFormDismissHandler = () => {
    setLoadTesterFormVisible(false);
  };

  const createLoadTester = async (params: ILoadTesterParams) => {
    try {
      await loadTesterService.create(params);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not create the load-tester.");
      setAlertSeverity("error");
    }
  };

  const loadTesterFormAcceptHandler = async (data: ILoadTesterFormData) => {
    setLoading(true);
    setLoadTesterFormVisible(false);
    const params: ILoadTesterParams = {
      instanceName: data.name,
      scriptArgs: data.scriptArgs,
      townsfolkClientZip: data.townsfolkClientZip,
      batchScriptZip: data.batchScriptZip,
      instanceType: data.instanceType,
      username: data.username,
      autoShutdownTime: data.autoShutdownDelay ? Date.now() + 60000 * data.autoShutdownDelay : 0,
      password: data.password,
      vpcId: data.vpcId,
      subnetId: data.subnetId,
      keypair: data.keypair,
      branch: data.branch,
    };
    if (data.batchCreate) {
      let psid = data.firstPlayerNumber;
      const pidIncrement = Math.ceil(
        (data.lastPlayerNumber - data.firstPlayerNumber + 1) / (data.lastMachineNumber - data.firstMachineNumber + 1),
      );
      for (let mid = data.firstMachineNumber; mid <= data.lastMachineNumber; mid++) {
        let peid = psid + pidIncrement - 1;
        if (peid > data.lastPlayerNumber) {
          peid = data.lastPlayerNumber;
        }
        params.instanceName = data.name.replaceAll("%mid%", mid.toString());
        params.scriptArgs = data.scriptArgs.replaceAll("%psid%", psid.toString()).replaceAll("%peid%", peid.toString());
        await createLoadTester(params);
        psid = peid + 1;
      }
    } else {
      await createLoadTester(params);
    }
    await fetchRows();
    setLoading(false);
  };

  const refreshButtonClickHandler = async () => {
    setLoading(true);
    await fetchRows();
    setLoading(false);
  };

  const uploadClientZipButtonClickHandler = () => {
    setUploadClientZipFormVisible(true);
  };

  const uploadBatchScriptButtonClickHandler = () => {
    setUploadBatchScriptFormVisible(true);
  };

  const terminatePopupNoHandler = () => {
    setTerminatePopupVisible(false);
  };

  const terminatePopupYesHandler = async () => {
    if (selectedItems.length === 0) {
      return;
    }
    try {
      setLoading(true);
      setTerminatePopupVisible(false);
      for (const t of selectedItems) {
        if (canBeTerminated(t)) {
          await loadTesterService.terminate(t.instanceName);
        }
      }
      await fetchRows();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not terminate the selected load tester.");
      setAlertSeverity("error");
    }
    setLoading(false);
  };

  const terminateButtonClickHandler = () => {
    setTerminatePopupVisible(true);
  };

  const showLogViewClickHandler = () => {
    navigate(Routes.logging, selectedItems.length === 1 ? { state: { name: selectedItems[0].logName } } : {});
  };

  const closeLogPopupClickHandler = () => {
    setLogPopupVisible(false);
  };

  const showLogsMenuClickHandler = () => {
    setLogPopupVisible(true);
  };

  const downloadRDPFileMenuClickHandler = () => {
    if (selectedItems.length !== 1) {
      return;
    }
    const element = document.createElement("a");
    const content =
      "auto connect:i:1\n" +
      `full address:s:${selectedItems[0].publicIpAddress}\n` +
      `username:s:${selectedItems[0].username}`;
    const file = new Blob([content], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${selectedItems[0].instanceName}.rdp`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const uploadClientZipFormAcceptHandler = async (data: FileUploadData) => {
    try {
      setLoading(true);
      setUploadClientZipFormVisible(false);
      if (data.file) {
        await loadTesterService.uploadClientZip(data.file);
      }
      setAlertMessage(`File "${data.file?.name}" uploaded successfully.`);
      setAlertSeverity("success");
    } catch (e: any) {
      setAlertMessage(e.debugMessage || `Could not upload file "${data.file?.name}".`);
      setAlertSeverity("error");
    }
    setLoading(false);
  };

  const uploadBatchScriptFormAcceptHandler = async (data: FileUploadData) => {
    try {
      setLoading(true);
      setUploadBatchScriptFormVisible(false);
      if (data.file) {
        await loadTesterService.uploadBatchScript(data.file);
      }
      setAlertMessage(`File "${data.file?.name}" uploaded successfully.`);
      setAlertSeverity("success");
    } catch (e: any) {
      setAlertMessage(e.debugMessage || `Could not upload file "${data.file?.name}".`);
      setAlertSeverity("error");
    }
    setLoading(false);
  };

  const alertCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(undefined);
  };

  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Load Testers");
    context?.setTargetAPI("environment");
    if (listTimeoutId.current) {
      clearTimeout(listTimeoutId.current);
      listTimeoutId.current = undefined;
    }
    if (context?.selectedEnvironment) {
      (async () => fetchRows())();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.selectedEnvironment]);

  return (
    <>
      <LoadTestersTable
        rows={rows}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        onCreateButtonClick={createButtonClickHandler}
        onTerminateButtonClick={terminateButtonClickHandler}
        onRefreshButtonClick={refreshButtonClickHandler}
        onUploadClientZipButtonClick={uploadClientZipButtonClickHandler}
        onUploadBatchScriptButtonClick={uploadBatchScriptButtonClickHandler}
        onCloneMenuClick={cloneMenuClickHandler}
        onShowLogsMenuClick={showLogsMenuClickHandler}
        onDownloadRDPFileMenuClick={downloadRDPFileMenuClickHandler}
      />
      {selectedItems.length === 1 && (
        <LogPopup
          visible={logPopupVisible}
          logName={selectedItems[0].logName}
          onClose={closeLogPopupClickHandler}
          onShowLogView={showLogViewClickHandler}
        />
      )}
      <LoadTesterForm
        initialData={selectedItems.length > 0 ? selectedItems[0] : undefined}
        visible={loadTesterFormVisible}
        onDismiss={loadTesterFormDismissHandler}
        onAccept={loadTesterFormAcceptHandler}
      />
      <YesNoPopup
        visible={terminatePopupVisible}
        title={"Terminate Load Tester"}
        message={`Are you sure you want to terminate the Load Tester(s) named <b>${selectedItems
          .map((t) => t.instanceName)
          .join(", ")}</b>?`}
        onNo={terminatePopupNoHandler}
        onYes={terminatePopupYesHandler}
      />
      <FileUpload
        visible={uploadClientZipFormVisible}
        title="Upload Client ZIP"
        filters=".zip"
        fileUploadConf={{
          showExistingFiles: false,
          existingFiles: [],
          showSetLabels: false,
          initialLabels: [],
        }}
        onDismiss={() => setUploadClientZipFormVisible(false)}
        onAccept={uploadClientZipFormAcceptHandler}
      />
      <FileUpload
        visible={uploadBatchScriptFormVisible}
        title="Upload Batch Script"
        filters={".zip" /* TODO: accept bat instead files later */}
        fileUploadConf={{
          showExistingFiles: false,
          existingFiles: [],
          showSetLabels: false,
          initialLabels: [],
        }}
        onDismiss={() => setUploadBatchScriptFormVisible(false)}
        onAccept={uploadBatchScriptFormAcceptHandler}
      />
      <Snackbar open={alertMessage !== undefined} autoHideDuration={5000} onClose={alertCloseHandler}>
        <Alert onClose={alertCloseHandler} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {loading && <LoadingOverlay />}
    </>
  );
};

export default LoadTesters;
