/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect, useState } from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { EnvironmentState, IEnvironment, useEnvironmentService } from "../../../services/registry/EnvironmentService";
import { EnvironmentsTable } from "./EnvironmentsTable";
import { EnvironmentCard } from "./EnvironmentCard";
import { EnvironmentForm, EnvironmentFormData, EnvironmentFormType } from "./EnvironmentForm";
import { YesNoPopup } from "../../../components/YesNoPopup";
import { IBackendImage } from "../../../services/registry/BackendImageService";
import { DeploymentPopup } from "./DeploymentPopup";
import { AppContext } from "utils/AppContext";
import { useNavigate } from "react-router-dom";
import Routes from "../../../components/routing/Routes";
import { useIsAuthenticated } from "../../../services/auth/AuthService";

interface IEnvironmentExtraData {
  isDefault: boolean | undefined;
  state: EnvironmentState;
  publicIpAddress: string | undefined;
  privateIpAddress: string | undefined;
  gameServerCount: number;
  activePackageId: string | undefined;
  nextRefreshTime: number;
}
const envExtraData = new Map<string, IEnvironmentExtraData>();
const unknownEnvExtraData: IEnvironmentExtraData = {
  isDefault: undefined,
  state: EnvironmentState.UNKNOWN,
  publicIpAddress: undefined,
  privateIpAddress: undefined,
  gameServerCount: 0,
  activePackageId: undefined,
  nextRefreshTime: 0,
};
const ENV_EXTRA_DATA_REFRESH_SLOW_INTERVAL = 90000;
const ENV_EXTRA_DATA_REFRESH_REGULAR_INTERVAL = 30000;
const ENV_EXTRA_DATA_REFRESH_FAST_INTERVAL = 5000;
const ENV_EXTRA_DATA_LOOP_REFRESH_INTERVAL = 500;

const Environments = () => {
  const navigate = useNavigate();

  const environmentService = useEnvironmentService();
  const isAuthenticated = useIsAuthenticated();

  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [rows, setRows] = useState<IEnvironment[]>([]);
  const [selectedItem, setSelectedItem] = useState<IEnvironment | undefined>(undefined);
  const [environmentFormVisible, setEnvironmentFormVisible] = useState<boolean>(false);
  const [environmentFormType, setEnvironmentFormType] = useState<EnvironmentFormType>("new");
  const [terminatePopupVisible, setTerminatePopupVisible] = useState<boolean>(false);
  const [destroyDevPopupVisible, setDestroyDevPopupVisible] = useState<boolean>(false);
  const [revivePopupVisible, setRevivePopupVisible] = useState<boolean>(false);
  const [defaultPopupVisible, setDefaultPopupVisible] = useState<boolean>(false);
  const [stateRefreshTick, setStateRefreshTick] = useState<number>(0);
  const [visibleRows, setVisibleRows] = useState<IEnvironment[]>([]);
  const [deploymentPopupVisible, setDeploymentPopupVisible] = useState<boolean>(false);

  const replaceRows = (list: IEnvironment[]) => {
    for (const env of list) {
      // Populating with known extra data.
      const extraData = envExtraData.get(env.name);
      if (extraData) {
        env.isDefault = extraData.isDefault;
        env.state = extraData.state;
        env.publicIpAddress = extraData.publicIpAddress;
        env.privateIpAddress = extraData.privateIpAddress;
        env.gameServerCount = extraData.gameServerCount;
        env.activePackageId = extraData.activePackageId;
      }
    }
    setRows(list);
    if (selectedItem) {
      // Refreshing the selected item if some data changed.
      let match = false;
      for (const env of list) {
        if (env.name === selectedItem.name) {
          setSelectedItem(env);
          match = true;
        }
      }
      if (!match) {
        // Or unselecting if the selected item does not exist anymore.
        setSelectedItem(undefined);
      }
    }
  };

  const fetchRows = async () => {
    try {
      const { list } = await environmentService.listEnvironments();
      for (const env of list) {
        // Keeping track of the isDefault property
        const extraData = envExtraData.get(env.name) || { ...unknownEnvExtraData };
        extraData.isDefault = env.isDefault;
        envExtraData.set(env.name, extraData);
      }
      replaceRows(list as IEnvironment[]);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not fetch the list of environments.");
      setAlertSeverity("error");
    }
  };

  useEffect(() => {
    let stateRefreshTick = 0;
    const stateRefreshIntervalId = setInterval(() => {
      setStateRefreshTick(++stateRefreshTick);
    }, ENV_EXTRA_DATA_LOOP_REFRESH_INTERVAL);
    return () => {
      clearInterval(stateRefreshIntervalId);
    };
  }, []);

  const refreshRowState = (envName: string) => {
    const extraData = envExtraData.get(envName) || { ...unknownEnvExtraData };
    extraData.state = EnvironmentState.REFRESHING;
    extraData.nextRefreshTime = Date.now() + ENV_EXTRA_DATA_REFRESH_FAST_INTERVAL;
    envExtraData.set(envName, extraData);
    replaceRows(Array.from(rows));
  };

  const updateRowWithState = (env: IEnvironment) => {
    let interval = ENV_EXTRA_DATA_REFRESH_SLOW_INTERVAL;
    if (
      env.state === EnvironmentState.PENDING_INITIALIZATION ||
      env.state === EnvironmentState.PENDING_TERMINATION ||
      env.state === EnvironmentState.INITIALIZED ||
      env.state === EnvironmentState.REFRESHING
    ) {
      interval = ENV_EXTRA_DATA_REFRESH_FAST_INTERVAL;
    } else if (
      env.state === EnvironmentState.UNHEALTHY ||
      env.state === EnvironmentState.RUNNING ||
      env.name === selectedItem?.name
    ) {
      interval = ENV_EXTRA_DATA_REFRESH_REGULAR_INTERVAL;
    }
    const extraData = envExtraData.get(env.name) || { ...unknownEnvExtraData };
    extraData.state = env.state || EnvironmentState.UNKNOWN;
    extraData.publicIpAddress = env.publicIpAddress || "N/A";
    extraData.privateIpAddress = env.privateIpAddress || "N/A";
    if (env.isDefault !== undefined) {
      extraData.isDefault = env.isDefault;
    }
    if (env.gameServerCount !== undefined) {
      extraData.gameServerCount = env.gameServerCount;
    }
    if (env.activePackageId !== undefined) {
      extraData.activePackageId = env.activePackageId;
    }
    extraData.nextRefreshTime = Date.now() + interval;
    envExtraData.set(env.name, extraData);
    const updatedRows = Array.from(rows);
    for (const i in updatedRows) {
      if (updatedRows[i].name === env.name) {
        updatedRows[i] = env;
      }
    }
    replaceRows(updatedRows);
  };

  const evalEnvStateState = async (envName: string) => {
    refreshRowState(envName);
    try {
      const envWithState = await environmentService.evalEnvironmentState(envName);
      updateRowWithState(envWithState);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || `Could not eval the state of the environment "${envName}".`);
      setAlertSeverity("warning");
    }
  };

  useEffect(() => {
    if (stateRefreshTick === 0) {
      fetchRows();
      setStateRefreshTick(1);
    } else {
      const time = Date.now();
      if (selectedItem) {
        const extraData = envExtraData.get(selectedItem.name) || unknownEnvExtraData;
        if (time > extraData.nextRefreshTime || extraData.publicIpAddress === undefined) {
          evalEnvStateState(selectedItem.name);
          return;
        }
      }
      const rowsToRefresh = visibleRows.filter(
        (t) => time > (envExtraData.get(t.name) || unknownEnvExtraData).nextRefreshTime,
      );
      if (rowsToRefresh.length > 0) {
        evalEnvStateState(rowsToRefresh[0].name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRefreshTick, visibleRows]);

  const newButtonClickHandler = () => {
    setEnvironmentFormVisible(true);
    setEnvironmentFormType("new");
  };

  const pageChangeHandler = (page: number, rowsPerPage: number, visibleRows: IEnvironment[]) => {
    setVisibleRows(visibleRows);
  };

  const editButtonClickHandler = () => {
    setEnvironmentFormVisible(true);
    setEnvironmentFormType("edit");
  };

  const environmentFormDismissHandler = () => {
    setEnvironmentFormVisible(false);
  };

  const environmentFormAcceptHandler = async (data: EnvironmentFormData) => {
    setEnvironmentFormVisible(false);
    if (environmentFormType === "new") {
      if (data.useTerraform) {
        try {
          await environmentService.createDevEnvironment(data.name, data.stage);
        } catch (e: any) {
          setAlertMessage(e.debugMessage || "Could not create the new environment.");
          setAlertSeverity("error");
        }
      } else {
        try {
          await environmentService.addEnvironment(data.name, data.baseURL, data.stage);
        } catch (e: any) {
          setAlertMessage(e.debugMessage || "Could not add the new environment.");
          setAlertSeverity("error");
        }
      }
    } else {
      try {
        await environmentService.editEnvironment(data.name, data.baseURL, data.stage);
      } catch (e: any) {
        setAlertMessage(e.debugMessage || "Could not edit the selected environment.");
        setAlertSeverity("error");
      }
    }
    await fetchRows();
    const extraData = envExtraData.get(data.name) || { ...unknownEnvExtraData };
    extraData.state = EnvironmentState.REFRESHING;
    extraData.nextRefreshTime = Date.now() + ENV_EXTRA_DATA_REFRESH_FAST_INTERVAL;
    envExtraData.set(data.name, extraData);
  };

  const terminateButtonClickHandler = () => {
    setTerminatePopupVisible(true);
  };

  const terminatePopupNoHandler = () => {
    setTerminatePopupVisible(false);
  };

  const terminatePopupYesHandler = async () => {
    if (!selectedItem) {
      return;
    }
    setTerminatePopupVisible(false);
    if (selectedItem.stage <= 7 && selectedItem.state !== EnvironmentState.UNKNOWN) {
      setDestroyDevPopupVisible(true);
    } else {
      try {
        await environmentService.terminateEnvironment(selectedItem.name);
      } catch (e: any) {
        setAlertMessage(e.debugMessage || "Could not terminate the selected environment.");
        setAlertSeverity("error");
      }
    }
    refreshRowState(selectedItem.name);
  };

  const destroyDevPopupNoHandler = () => {
    setDestroyDevPopupVisible(false);
  };

  const destroyDevPopupYesHandler = async () => {
    if (!selectedItem) {
      return;
    }
    setDestroyDevPopupVisible(false);
    try {
      await environmentService.destroyDevEnvironment(selectedItem.name);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not terminate the selected environment.");
      setAlertSeverity("error");
    }
    refreshRowState(selectedItem.name);
  };

  const reviveButtonClickHandler = () => {
    setRevivePopupVisible(true);
  };

  const revivePopupNoHandler = () => {
    setRevivePopupVisible(false);
  };

  const revivePopupYesHandler = async () => {
    if (!selectedItem) {
      return;
    }
    try {
      await environmentService.createDevEnvironment(selectedItem.name, selectedItem.stage);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not revive the selected environment.");
      setAlertSeverity("error");
    }
    setRevivePopupVisible(false);
    refreshRowState(selectedItem.name);
  };

  const defaultButtonClickHandler = () => {
    setDefaultPopupVisible(true);
  };

  const defaultPopupNoHandler = () => {
    setDefaultPopupVisible(false);
  };

  const defaultPopupYesHandler = async () => {
    if (!selectedItem) {
      return;
    }
    setDefaultPopupVisible(false);
    try {
      await environmentService.setDefaultEnvironment(selectedItem.name);
      fetchRows();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not set the selected environment as default.");
      setAlertSeverity("error");
    }
  };

  const alertCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(undefined);
  };

  const refreshButtonClickHandler = () => {
    for (const t of Array.from(envExtraData.values())) {
      t.state = EnvironmentState.REFRESHING;
      t.nextRefreshTime = 0;
    }
  };

  const deployBackendImageButtonClickHandler = () => {
    setDeploymentPopupVisible(true);
  };

  const deployButtonClickHandler = async (backendImage: IBackendImage, backendFlavor: string) => {
    if (!selectedItem) {
      return;
    }
    try {
      setDeploymentPopupVisible(false);
      await environmentService.deployToDevEnvironment(selectedItem.name, backendImage.id, backendFlavor);
      const extraData = envExtraData.get(selectedItem.name) || { ...unknownEnvExtraData };
      extraData.state = EnvironmentState.DEPLOYING;
      extraData.nextRefreshTime = Date.now() + ENV_EXTRA_DATA_REFRESH_REGULAR_INTERVAL;
      envExtraData.set(selectedItem.name, extraData);
      replaceRows(Array.from(rows));
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not deploy to the selected environment.");
      setAlertSeverity("error");
    }
  };

  const changeActivePackageButtonClickHandler = () => {
    window.open("https://app.growthbook.io/features/active-package-id");
  };

  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Environments");
    context?.setTargetAPI("registry");
  }, [context]);

  const showGameServersButtonClickHandler = () => {
    context?.setSelectedEnvironment(selectedItem);
    navigate(Routes.gameServers);
  };

  return (
    <>
      <EnvironmentsTable
        rows={rows}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        onNewButtonClick={newButtonClickHandler}
        onRefreshButtonClick={refreshButtonClickHandler}
        onPageChange={pageChangeHandler}
      />
      {selectedItem && (
        <EnvironmentCard
          content={selectedItem}
          onEditButtonClick={editButtonClickHandler}
          onTerminateButtonClick={terminateButtonClickHandler}
          onReviveButtonClick={reviveButtonClickHandler}
          onSetDefaultButtonClick={defaultButtonClickHandler}
          onDeployBackendImageButtonClick={deployBackendImageButtonClickHandler}
          onChangeActivePackageButtonClick={changeActivePackageButtonClickHandler}
          onShowGameServersButtonClick={showGameServersButtonClickHandler}
        />
      )}
      <EnvironmentForm
        visible={environmentFormVisible}
        type={environmentFormType}
        initialData={environmentFormType === "edit" ? selectedItem : undefined}
        onDismiss={environmentFormDismissHandler}
        onAccept={environmentFormAcceptHandler}
      />
      <YesNoPopup
        visible={terminatePopupVisible}
        title={"Terminate Environment"}
        message={`Are you sure you want to terminate the Environment named <b>${selectedItem?.name}</b>?<br/>
        If you terminate this Environment, you will permanently block any client from accessing it.`}
        onNo={terminatePopupNoHandler}
        onYes={terminatePopupYesHandler}
      />
      <YesNoPopup
        visible={destroyDevPopupVisible}
        title={"Destroy Environment"}
        message={`Do you want to destroy all AWS resources associated to the Terraform state of this Environment?`}
        onNo={destroyDevPopupNoHandler}
        onYes={destroyDevPopupYesHandler}
      />
      <YesNoPopup
        visible={revivePopupVisible}
        title={"Revive Environment"}
        message={`Do you want to revive this Environment, including all AWS resources associated to it?`}
        onNo={revivePopupNoHandler}
        onYes={revivePopupYesHandler}
      />
      <YesNoPopup
        visible={defaultPopupVisible}
        title={"Set Environment as default"}
        message={`Are you sure you want to set the Environment named <b>${selectedItem?.name}</b> as default?<br/>
        Doing so will redirect all unidentified clients to this Environment.`}
        onNo={defaultPopupNoHandler}
        onYes={defaultPopupYesHandler}
      />
      {deploymentPopupVisible && (
        <DeploymentPopup
          visible={deploymentPopupVisible}
          onCancel={() => setDeploymentPopupVisible(false)}
          onDeploy={deployButtonClickHandler}
        />
      )}
      <Snackbar open={alertMessage !== undefined} autoHideDuration={5000} onClose={alertCloseHandler}>
        <Alert onClose={alertCloseHandler} severity={alertSeverity} sx={{ width: "100%" }}>
          {`${alertMessage} ; isAuthenticated: ${isAuthenticated}`}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Environments;
