import React from "react";

export const BlueIndicator = ({ scale }: { scale: number }) => {
  const size = 128 * scale;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      version="1"
      preserveAspectRatio="xMaxYMid meet"
      viewBox={`0 0 128 128`}
    >
      <defs>
        <linearGradient id="blue-lg28">
          <stop offset="0" stopColor="#0000b0" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient id="blue-lg59">
          <stop offset="0" stopColor="#545454" stopOpacity="1"></stop>
          <stop offset="0.946" stopColor="#545454" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#c5c5c5" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="blue-lg94">
          <stop offset="0" stopColor="#00f" stopOpacity="1"></stop>
          <stop offset="0.612" stopColor="#0000a2" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#66f" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient
          id="blue-lg66"
          x1="6.929"
          x2="6.929"
          y1="121.071"
          y2="8.961"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#blue-lg94"
        ></linearGradient>
        <radialGradient
          id="blue-rg12"
          cx="62.984"
          cy="65.016"
          r="54.18"
          fx="62.984"
          fy="65.016"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#blue-lg59"
        ></radialGradient>
        <linearGradient
          id="blue-lg22"
          x1="31.5"
          x2="31.5"
          y1="63"
          y2="12"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#blue-lg28"
        ></linearGradient>
      </defs>
      <path
        fill="url(#rg12)"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        strokeDasharray="none"
        strokeDashoffset="0"
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="3.75"
        d="M117.164 65.016c0 29.923-24.257 54.18-54.18 54.18s-54.18-24.257-54.18-54.18 24.257-54.18 54.18-54.18 54.18 24.257 54.18 54.18z"
        opacity="1"
        transform="translate(5.725 4.032)"
      ></path>
      <path
        fill="url(#blue-lg66)"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="#000074"
        strokeDasharray="none"
        strokeDashoffset="0"
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="3.75"
        d="M117.164 65.016c0 29.923-24.257 54.18-54.18 54.18s-54.18-24.257-54.18-54.18 24.257-54.18 54.18-54.18 54.18 24.257 54.18 54.18z"
        opacity="1"
        transform="translate(1.693)"
      ></path>
      <path
        fill="url(#blue-lg22)"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        strokeDasharray="none"
        strokeDashoffset="0"
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="3.75"
        d="M95.5 37.5c0 14.083-14.327 25.5-32 25.5-17.673 0-32-11.417-32-25.5S45.827 12 63.5 12c17.673 0 32 11.417 32 25.5z"
        opacity="1"
        transform="translate(1 2)"
      ></path>
    </svg>
  );
};
