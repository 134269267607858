import * as React from "react";
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useMemo, useState } from "react";
import { DateTime } from "luxon";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import Toolbar from "@mui/material/Toolbar";
import { Button, Menu, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import { MoreVert } from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { getComparator, Order, stableSort } from "../../utils/Table";
import { canBeTerminated, ILoadTesterInfo, LoadTesterState } from "../../services/backend/LoadTesterService";
import { LoadTesterStateIndicator } from "./LoadTesterStateIndicator";
import Checkbox from "@mui/material/Checkbox";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface HeadCell {
  id: keyof ILoadTesterInfo;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
}

const headCells: readonly HeadCell[] = [
  {
    id: "instanceName",
    label: "Name",
    padding: "none",
  },
  {
    id: "townsfolkClientZip",
    label: "Townsfolk Client ZIP",
  },
  {
    id: "batchScriptZip",
    label: "Batch Script ZIP",
  },
  {
    id: "scriptArgs",
    label: "Script Args",
  },
  {
    id: "launchTime",
    label: "Launch Time",
    align: "right",
  },
  {
    id: "autoShutdownTime",
    label: "Auto-shutdown Time",
    align: "right",
  },
  {
    id: "publicIpAddress",
    label: "Public IP Address",
    align: "right",
  },
  {
    id: "state",
    label: "State",
    align: "right",
  },
];

interface LoadTestersTableHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof ILoadTesterInfo) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const LoadTestersTableHead = ({ order, orderBy, onRequestSort }: LoadTestersTableHeadProps) => {
  const createSortHandler = (property: keyof ILoadTesterInfo) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="normal" />
      </TableRow>
    </TableHead>
  );
};

interface LoadTestersTableToolbarProps {
  onCreateButtonClick: () => void;
  onTerminateButtonClick: () => void;
  onRefreshButtonClick: () => void;
  onUploadClientZipButtonClick: () => void;
  onUploadBatchScriptButtonClick: () => void;
  terminateButtonDisabled: boolean;
}

const LoadTestersTableToolbar = ({
  onCreateButtonClick,
  onTerminateButtonClick,
  onRefreshButtonClick,
  onUploadClientZipButtonClick,
  onUploadBatchScriptButtonClick,
  terminateButtonDisabled,
}: LoadTestersTableToolbarProps) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Button variant="outlined" startIcon={<AddIcon />} onClick={onCreateButtonClick}>
        Create
      </Button>
      <Button
        variant="outlined"
        startIcon={<DeleteIcon />}
        onClick={onTerminateButtonClick}
        sx={{ marginLeft: 1 }}
        disabled={terminateButtonDisabled}
      >
        Terminate
      </Button>
      <Button variant="outlined" startIcon={<RefreshIcon />} onClick={onRefreshButtonClick} sx={{ marginLeft: 1 }}>
        Refresh
      </Button>
      <Button
        variant="outlined"
        startIcon={<CloudUploadIcon />}
        onClick={onUploadClientZipButtonClick}
        sx={{ marginLeft: 1 }}
      >
        Upload Client ZIP
      </Button>
      <Button
        variant="outlined"
        startIcon={<CloudUploadIcon />}
        onClick={onUploadBatchScriptButtonClick}
        sx={{ marginLeft: 1 }}
      >
        Upload Batch Script
      </Button>
    </Toolbar>
  );
};

export interface LoadTestersTableProps {
  rows: ILoadTesterInfo[];
  selectedItems: ILoadTesterInfo[];
  setSelectedItems: Dispatch<SetStateAction<ILoadTesterInfo[]>>;
  onCreateButtonClick: () => void;
  onTerminateButtonClick: () => void;
  onRefreshButtonClick: () => void;
  onUploadClientZipButtonClick: () => void;
  onUploadBatchScriptButtonClick: () => void;
  onCloneMenuClick: () => void;
  onShowLogsMenuClick: () => void;
  onDownloadRDPFileMenuClick: () => void;
}

export const LoadTestersTable = ({
  rows,
  selectedItems,
  setSelectedItems,
  onCreateButtonClick,
  onTerminateButtonClick,
  onRefreshButtonClick,
  onUploadClientZipButtonClick,
  onUploadBatchScriptButtonClick,
  onCloneMenuClick,
  onShowLogsMenuClick,
  onDownloadRDPFileMenuClick,
}: LoadTestersTableProps) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof ILoadTesterInfo>("instanceName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [menuAnchor, setMenuAnchor] = useState<Element | undefined>(undefined);
  const [includeTerminated, setIncludeTerminated] = useState(false);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredRows = useMemo(() => {
    return rows.filter((t) => includeTerminated || t.state != LoadTesterState.TERMINATED);
  }, [rows, includeTerminated]);

  const visibleRows = useMemo(() => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    return stableSort(filteredRows as any, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [filteredRows, order, orderBy, page, rowsPerPage]);

  const requestSortHandler = (event: MouseEvent<unknown>, property: keyof ILoadTesterInfo) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const selectionChangeHandler = (event: ChangeEvent<HTMLInputElement>, row: ILoadTesterInfo, index: number) => {
    const toggledRows = [];
    if ((event.nativeEvent as PointerEvent).shiftKey) {
      let closestToggledRowIndex = -999;
      for (let i = 0; i < visibleRows.length; i++) {
        if (Math.abs(index - i) < Math.abs(index - closestToggledRowIndex)) {
          if (event.target.checked === selectedItems.includes(visibleRows[i])) {
            closestToggledRowIndex = i;
          }
        }
      }
      if (closestToggledRowIndex < 0) {
        closestToggledRowIndex = 0;
      }
      const min = Math.min(index, closestToggledRowIndex);
      const max = Math.max(index, closestToggledRowIndex);
      for (let i = min; i <= max; i++) {
        toggledRows.push(visibleRows[i]);
      }
    } else {
      toggledRows.push(row);
    }
    const newlySelectedItems = [];
    if (event.target.checked) {
      newlySelectedItems.push(...selectedItems);
      for (const row of toggledRows) {
        if (newlySelectedItems.findIndex((t) => t.instanceName === row.instanceName) === -1) {
          newlySelectedItems.push(row);
        }
      }
    } else {
      for (const item of selectedItems) {
        if (toggledRows.findIndex((t) => t.instanceName === item.instanceName) === -1) {
          newlySelectedItems.push(item);
        }
      }
    }
    setSelectedItems(newlySelectedItems);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const menuOpenHandler = (event: MouseEvent<HTMLElement>, item: ILoadTesterInfo) => {
    setMenuAnchor(event.target as Element);
    setSelectedItems([item]);
  };

  const menuCloseHandler = () => {
    setMenuAnchor(undefined);
  };

  const cloneMenuClickHandler = () => {
    onCloneMenuClick();
    menuCloseHandler();
  };

  const removeMenuClickHandler = () => {
    onTerminateButtonClick();
    menuCloseHandler();
  };

  const showLogsMenuClickHandler = () => {
    onShowLogsMenuClick();
    menuCloseHandler();
  };

  const downloadRDPFileMenuClickHandler = () => {
    onDownloadRDPFileMenuClick();
    menuCloseHandler();
  };

  const isSelected = (row: ILoadTesterInfo, selectedItems: ILoadTesterInfo[]) =>
    selectedItems.findIndex((t) => t.instanceName === row.instanceName) > -1;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <LoadTestersTableToolbar
          onCreateButtonClick={onCreateButtonClick}
          onTerminateButtonClick={onTerminateButtonClick}
          onRefreshButtonClick={onRefreshButtonClick}
          onUploadClientZipButtonClick={onUploadClientZipButtonClick}
          onUploadBatchScriptButtonClick={onUploadBatchScriptButtonClick}
          terminateButtonDisabled={
            selectedItems.length === 0 || selectedItems.length > selectedItems.filter(canBeTerminated).length
          }
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="small">
            <LoadTestersTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={requestSortHandler}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const launchTime = DateTime.fromMillis(row.launchTime).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
                const autoShutdownTime = row.autoShutdownTime
                  ? DateTime.fromMillis(row.autoShutdownTime).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
                  : "N/A";
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.instanceName}
                    selected={isSelected(row, selectedItems)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isSelected(row, selectedItems)}
                        onChange={(event) => selectionChangeHandler(event, row, index)}
                      />
                    </TableCell>
                    <TableCell component="th" style={{ fontFamily: "monospace" }} scope="row" padding="none">
                      {row.instanceName}
                    </TableCell>
                    <TableCell component="th" style={{ fontFamily: "monospace" }} scope="row" padding="none">
                      {row.townsfolkClientZip}
                    </TableCell>
                    <TableCell component="th" style={{ fontFamily: "monospace" }} scope="row" padding="none">
                      {row.batchScriptZip}
                    </TableCell>
                    <TableCell component="th" style={{ fontFamily: "monospace" }} scope="row" padding="none">
                      {row.scriptArgs}
                    </TableCell>
                    <TableCell align="right">{launchTime}</TableCell>
                    <TableCell align="right">{autoShutdownTime}</TableCell>
                    <TableCell align="right" style={{ fontFamily: "monospace" }}>
                      {row.publicIpAddress}
                    </TableCell>
                    <TableCell align="right">
                      <LoadTesterStateIndicator state={row.state || LoadTesterState.UNKNOWN} />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={(e) => menuOpenHandler(e, row)}>
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center" }}>
            <Checkbox
              color="primary"
              checked={includeTerminated}
              onChange={() => setIncludeTerminated(!includeTerminated)}
            />
            <Typography variant="body2">Show terminated environments</Typography>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={pageChangeHandler}
            onRowsPerPageChange={rowsPerPageChangeHandler}
          />
        </div>
        <Menu anchorEl={menuAnchor} keepMounted open={menuAnchor !== undefined} onClose={() => menuCloseHandler()}>
          <MenuItem onClick={() => cloneMenuClickHandler()}>
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Clone</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => removeMenuClickHandler()}
            disabled={selectedItems.length === 1 && selectedItems[0].state != LoadTesterState.RUNNING}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Terminate</Typography>
          </MenuItem>
          <MenuItem onClick={() => showLogsMenuClickHandler()}>
            <ListItemIcon>
              <FormatListBulletedIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Show Logs</Typography>
          </MenuItem>
          <MenuItem onClick={() => downloadRDPFileMenuClickHandler()}>
            <ListItemIcon>
              <ConnectedTvIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Download RDP File</Typography>
          </MenuItem>
        </Menu>
      </Paper>
    </Box>
  );
};
