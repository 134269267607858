import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import { json } from "@codemirror/lang-json";
import CodeMirror from "@uiw/react-codemirror";

interface JSONViewerProps {
  data: string;
  visible: boolean;
  onClose: () => void;
}
export default function JsonViewer({ data, visible, onClose }: JSONViewerProps) {
  return (
    <Dialog open={visible} onClose={onClose} PaperProps={{ sx: { width: "100%", maxWidth: "80vw!important" } }}>
      <DialogContent>
        {data && <CodeMirror value={data} editable={false} height={`80vh`} extensions={[json()]} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
