import { useState, useMemo, MouseEvent } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Badge, Box, Button, IconButton, Stack, TablePagination, TableSortLabel, Toolbar } from "@mui/material";
import { DateTime } from "luxon";
import { Order, stableSort, getComparator } from "utils/Table";
import AddIcon from "@mui/icons-material/Add";
import { visuallyHidden } from "@mui/utils";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SimpleDialogList from "components/SimpleDialogList";
import { IPackage, PackageState } from "services/registry/PackageService";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";

interface HeadCell {
  id: keyof IPackage | string;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    label: "ID",
    padding: "none",
  },
  {
    id: "wads",
    label: "Wads",
  },
  {
    id: "state",
    label: "State",
  },
  {
    id: "createdTime",
    label: "Creation Date",
  },
  {
    id: "actions",
    label: "",
  },
];

interface PackagesTableHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IPackage | string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const PackagesTableHead = ({ order, orderBy, onRequestSort }: PackagesTableHeadProps) => {
  const createSortHandler = (property: keyof IPackage | string) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
interface PackagesTableToolbarProps {
  onAddButtonClick: () => void;
}

const PackagesTableToolbar = ({ onAddButtonClick }: PackagesTableToolbarProps) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Button variant="outlined" startIcon={<AddIcon />} onClick={onAddButtonClick}>
        Add
      </Button>
    </Toolbar>
  );
};

export interface PackagesTableProps {
  rows: IPackage[];
  selectedItem: IPackage | undefined;
  setSelectedItem: React.Dispatch<React.SetStateAction<IPackage | undefined>>;
  onAddButtonClick: () => void;
  onEditButtonClick: () => void;
  onRemoveButtonClick: (option: boolean) => void;
  onPublishButtonClick: (option: boolean) => void;
}

export const PackagesTable = ({
  rows,
  selectedItem,
  setSelectedItem,
  onAddButtonClick,
  onEditButtonClick,
  onRemoveButtonClick,
  onPublishButtonClick,
}: PackagesTableProps) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof IPackage | string>("createdTime");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openLabelDialog, setOpenLabelDialog] = useState(false);

  const requestSortHandler = (event: MouseEvent<unknown>, property: keyof IPackage | string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showPackageWads = (row: IPackage) => {
    setSelectedItem(row);
    setOpenLabelDialog(true);
  };

  const handleCloseLabelDialog = () => {
    setOpenLabelDialog(false);
  };

  const handleEditClick = (event: React.MouseEvent<unknown>, row: IPackage) => {
    setSelectedItem(row);
    onEditButtonClick();
  };

  const handleRemoveClick = (evt: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, row: IPackage) => {
    setSelectedItem(row);
    onRemoveButtonClick(true);
  };

  const handlePublishClick = (evt: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, row: IPackage) => {
    setSelectedItem(row);
    onPublishButtonClick(true);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [rows, order, orderBy, page, rowsPerPage]);

  const isSelected = (row: IPackage, selectedItem: IPackage | undefined) =>
    selectedItem !== undefined && row.id === selectedItem.id;

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <PackagesTableToolbar onAddButtonClick={onAddButtonClick} />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} size="small">
              <PackagesTableHead order={order} orderBy={orderBy} onRequestSort={requestSortHandler} rowCount={rows.length} />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const labelId = `Packages-table-checkbox-${index}`;
                  const creationDate = DateTime.fromSeconds(row.createdTime).toLocaleString(
                    DateTime.DATETIME_SHORT_WITH_SECONDS,
                  );

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      onClick={() => setSelectedItem(row)}
                      tabIndex={-1}
                      key={row.id}
                      selected={isSelected(row, selectedItem)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="normal" />
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.id}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            showPackageWads(row);
                          }}
                        >
                          <Badge badgeContent={row.wads.length ?? 0} color="primary" showZero>
                            <ListAltIcon fontSize="inherit" />
                          </Badge>
                        </IconButton>
                      </TableCell>
                      <TableCell align="left">{row.state}</TableCell>
                      <TableCell align="left">{creationDate}</TableCell>
                      <TableCell>
                        <Stack spacing={1} direction="row" justifyContent="flex-end">
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<EditIcon />}
                            onClick={(event) => handleEditClick(event, row)}
                            disabled={row.state === PackageState.PUBLISHED}
                          >
                            Edit
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteIcon />}
                            onClick={(evt) => handleRemoveClick(evt, row)}
                            disabled={row.state === PackageState.PUBLISHED}
                          >
                            Remove
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<PublishIcon />}
                            disabled={row.state === PackageState.PUBLISHED}
                            onClick={(evt) => handlePublishClick(evt, row)}
                          >
                            Publish
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={pageChangeHandler}
            onRowsPerPageChange={rowsPerPageChangeHandler}
          />
        </Paper>
      </Box>
      <SimpleDialogList
        title="Wads"
        listItems={selectedItem?.wads}
        emptyListMessage="No files to display"
        open={openLabelDialog}
        onClose={handleCloseLabelDialog}
      />
    </>
  );
};
