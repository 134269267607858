import { useContext } from "react";
import { useAuthUser } from "react-auth-kit";
import { fetchWithErrorHandling, buildPostOptions } from "../FetchUtils";
import { AppContext } from "../../utils/AppContext";
import { IDataEntity } from "./DataSchemaService";

export const listDataStructures = async (baseURL: string, accessToken: string) => {
  return fetchWithErrorHandling(baseURL + `v1/data/list-data-structures`, buildPostOptions(accessToken));
};

export const listDataEntitiesId = async (baseURL: string, accessToken: string, dataStructureName: string) => {
  return fetchWithErrorHandling(baseURL + `v1/data/${dataStructureName}/load-ids`, buildPostOptions(accessToken));
};

export const loadDataEntity = async (baseURL: string, accessToken: string, dataStructureName: string, id: string) => {
  return fetchWithErrorHandling(baseURL + `v1/data/${dataStructureName}/load/${id}`, buildPostOptions(accessToken));
};

export const storeDataEntity = async (baseURL: string, accessToken: string, dataStructureName: string, content: unknown) => {
  return fetchWithErrorHandling(baseURL + `v1/data/${dataStructureName}/store`, buildPostOptions(accessToken, content));
};

export const archiveDataEntity = async (
  baseURL: string,
  accessToken: string,
  dataStructureName: string,
  structureTfid: string,
) => {
  return fetchWithErrorHandling(
    baseURL + `v1/data/${dataStructureName}/archive/${structureTfid}`,
    buildPostOptions(accessToken),
  );
};

export const reviveDataEntity = async (
  baseURL: string,
  accessToken: string,
  dataStructureName: string,
  structureTfid: string,
) => {
  return fetchWithErrorHandling(
    baseURL + `v1/data/${dataStructureName}/revive/${structureTfid}`,
    buildPostOptions(accessToken),
  );
};

export const exportData = async (
  baseURL: string,
  accessToken: string,
  ids: string[],
): Promise<{ list: IDataEntity[]; errors: string[] }> => {
  return fetchWithErrorHandling(baseURL + `v1/data/export`, buildPostOptions(accessToken, { ids }));
};

export const importData = async (baseURL: string, accessToken: string, data: IDataEntity[]) => {
  return fetchWithErrorHandling(baseURL + `v1/data/import`, buildPostOptions(accessToken, { data }));
};

export const loadAll = async (baseURL: string, accessToken: string, dataStructureName: string) => {
  return fetchWithErrorHandling(baseURL + `v1/data/${dataStructureName}/load-all`, buildPostOptions(accessToken));
};

export const loadAllActive = async (baseURL: string, accessToken: string, dataStructureName: string) => {
  return fetchWithErrorHandling(baseURL + `v1/data/${dataStructureName}/load-all-active`, buildPostOptions(accessToken));
};

export const useDataPersistenceService = () => {
  const getBaseURL = () => appContext?.selectedEnvironment?.baseURL || "http://localhost:3001/";
  const appContext = useContext(AppContext);
  const authUser = useAuthUser();
  const accessToken = authUser()?.access_token;

  return {
    listDataStructures: () => listDataStructures(getBaseURL(), accessToken),
    loadDataEntity: (dataStructureName: string, id: string) =>
      loadDataEntity(getBaseURL(), accessToken, dataStructureName, id),
    listDataEntitiesId: (dataStructureName: string) => listDataEntitiesId(getBaseURL(), accessToken, dataStructureName),
    storeDataEntity: (dataStructureName: string, content: unknown) =>
      storeDataEntity(getBaseURL(), accessToken, dataStructureName, content),
    archiveDataEntity: (dataStructureName: string, tfid: string) =>
      archiveDataEntity(getBaseURL(), accessToken, dataStructureName, tfid),
    reviveDataEntity: (dataStructureName: string, tfid: string) =>
      reviveDataEntity(getBaseURL(), accessToken, dataStructureName, tfid),
    exportData: (ids: string[]) => exportData(getBaseURL(), accessToken, ids),
    importData: (data: IDataEntity[]) => importData(getBaseURL(), accessToken, data),
    loadAll: (dataStructureName: string) => loadAll(getBaseURL(), accessToken, dataStructureName),
    loadAllActive: (dataStructureName: string) => loadAllActive(getBaseURL(), accessToken, dataStructureName),
  };
};
