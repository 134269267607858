import * as React from "react";
import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DataObjectIcon from "@mui/icons-material/DataObject";
import DoneIcon from "@mui/icons-material/Done";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ErrorIcon from "@mui/icons-material/Error";
import VisibilityIcon from "@mui/icons-material/Visibility";

export interface IRunTestDatasetEntityReport {
  id: string;
  name: string;
  tfidType: number;
  fromVersion: number;
  toVersion: number;
  initialEntity: object;
  migratedEntity: object;
  migrations: IRunTestDatasetMigrationReport[];
}

export interface IRunTestDatasetMigrationReport {
  fromVersion: number;
  toVersion: number;
  error: string;
  entity: object;
}

export interface RunTestDatasetResultsProps {
  visible: boolean;
  branch: string;
  dataset: string;
  report: IRunTestDatasetEntityReport[];
  onClose: () => void;
}

const RunTestDatasetResult = ({ visible, branch, dataset, report, onClose }: RunTestDatasetResultsProps) => {
  const [selectedEntity, setSelectedEntity] = useState<IRunTestDatasetEntityReport | undefined>(undefined);

  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  const optionLabel = (option: IRunTestDatasetEntityReport) => {
    const migrationsCount = option.migrations.length;
    const errorsCount = option.migrations.filter((o) => o.error !== null).length;
    if (errorsCount > 0) {
      return `${option.id} - ${option.name} (has errors)`;
    }
    return `${option.id} - ${option.name} (${migrationsCount} migrations)`;
  };

  const formatEntityData = (entityData: object, title: string) => {
    return (
      <Box>
        <Card variant="outlined">
          <CardContent>
            <Typography gutterBottom={true} mb={2}>
              {title}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              <pre>{JSON.stringify(entityData, null, 2)}</pre>
            </Typography>
          </CardContent>
        </Card>
      </Box>
    );
  };

  const formatError = (error: string) => {
    return <Typography color="error">{error}</Typography>;
  };

  const reportCounts = () => {
    let migrationCount = 0;
    let entitiesWithErrors = 0;
    const entityCount = report.length;
    for (const entityReport of report) {
      migrationCount += entityReport.migrations.length;
      if (entityReport.migrations.filter((e) => e.error).length) {
        entitiesWithErrors += 1;
      }
    }

    return (
      <div style={{ width: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "row", pb: 1, mb: 2 }}>
          <Chip icon={<DataObjectIcon />} color="default" label={`Entities tested: ${entityCount}`} sx={{ mr: 1 }} />
          <Chip icon={<EqualizerIcon />} color="info" label={`Total migrations: ${migrationCount}`} sx={{ mr: 1 }} />
          <Chip
            icon={<DoneIcon />}
            color="success"
            label={`Entities successful: ${entityCount - entitiesWithErrors}`}
            sx={{ mr: 1 }}
          />
          <Chip icon={<ErrorIcon />} color="error" label={`Entities with errors: ${entitiesWithErrors}`} />
        </Box>
      </div>
    );
  };

  const entityReportSummary = (entity: IRunTestDatasetEntityReport) => {
    const migrationWithError = entity.migrations.find((m) => m.error);
    let reportSummaryLabel = `From schema version ${entity.fromVersion} to ${entity.toVersion}`;
    if (entity.fromVersion === entity.toVersion) {
      reportSummaryLabel = `Schema version already latest (${entity.fromVersion})`;
    }
    return (
      <Accordion key={`${entity.id}-summary-${entity.fromVersion}-${entity.toVersion}`} defaultExpanded>
        <AccordionSummary
          expandIcon={<VisibilityIcon />}
          aria-controls="panel1-content"
          id={`${entity.id}-summary-${entity.fromVersion}-${entity.toVersion}`}
        >
          <Typography>
            <Chip
              icon={migrationWithError ? <ErrorIcon /> : <DoneIcon />}
              color={migrationWithError ? "error" : "success"}
              label={reportSummaryLabel}
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid sm={12} md={6}>
              {formatEntityData(entity.initialEntity, `Initial data (schema version ${entity.fromVersion}):`)}
            </Grid>
            <Grid sm={12} md={6}>
              {entity.migratedEntity &&
                formatEntityData(entity.migratedEntity, `Resulting data (schema version ${entity.toVersion}):`)}
              {migrationWithError && (
                <Box>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography gutterBottom={true} mb={2}>
                        Error migrating from {migrationWithError.fromVersion} to {migrationWithError.toVersion}:
                      </Typography>
                      {formatError(migrationWithError.error)}
                    </CardContent>
                  </Card>
                </Box>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const entityMigrationReport = (entity: IRunTestDatasetEntityReport) => {
    return entity.migrations.map((migration: IRunTestDatasetMigrationReport) => {
      return (
        <Accordion key={`${entity.id}-${migration.fromVersion}-${migration.toVersion}`}>
          <AccordionSummary
            expandIcon={<VisibilityIcon />}
            aria-controls="panel1-content"
            id={`${entity.id}-${migration.fromVersion}-${migration.toVersion}`}
          >
            <Typography>
              <Chip
                icon={migration.error ? <ErrorIcon /> : <DoneIcon />}
                color={migration.error ? "error" : "success"}
                label={`From schema version ${migration.fromVersion} to ${migration.toVersion}`}
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {migration.entity && formatEntityData(migration.entity, "Migration result:")}
            {migration.error && formatError(migration.error)}
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  return (
    <Dialog open={visible} onClose={dialogCloseHandler} fullScreen>
      <DialogTitle>{`Results for Test Dataset ${dataset} on branch ${branch}`}</DialogTitle>
      <Button
        variant="contained"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        Close
      </Button>
      <DialogContent>
        {reportCounts()}

        <Autocomplete
          disablePortal
          id="dataset-entities"
          options={report}
          getOptionLabel={optionLabel}
          renderInput={(params) => <TextField {...params} label="Dataset Entities" />}
          onChange={(event, value) => (value ? setSelectedEntity(value) : setSelectedEntity(undefined))}
          sx={{ width: 480, mb: 2 }}
        />
        {selectedEntity && (
          <>
            {entityReportSummary(selectedEntity)}

            {selectedEntity.migrations.length > 0 && (
              <>
                <Typography variant="h6" gutterBottom={true} sx={{ mt: 2 }}>
                  Step-by-step Migrations
                </Typography>
                {entityMigrationReport(selectedEntity)}
              </>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RunTestDatasetResult;
