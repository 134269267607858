import React, { useMemo } from "react";
import { RedIndicator } from "./indicators/RedIndicator";
import { YellowIndicator } from "./indicators/YellowIndicator";
import { GreenIndicator } from "./indicators/GreenIndicator";
import { BlueIndicator } from "./indicators/BlueIndicator";
import { GrayIndicator } from "./indicators/GrayIndicator";

export enum LedIndicatorColor {
  GRAY = 0,
  RED = 1,
  YELLOW = 2,
  GREEN = 3,
  BLUE = 4,
}

export interface LedIndicatorProps {
  color: LedIndicatorColor;
  scale: number;
}

export const LedIndicator = ({ color, scale }: LedIndicatorProps) => {
  const IndicatorClass = useMemo(() => {
    switch (color) {
      case LedIndicatorColor.RED:
        return RedIndicator;
      case LedIndicatorColor.YELLOW:
        return YellowIndicator;
      case LedIndicatorColor.GREEN:
        return GreenIndicator;
      case LedIndicatorColor.BLUE:
        return BlueIndicator;
      case LedIndicatorColor.GRAY:
        return GrayIndicator;
    }
  }, [color]);
  return <IndicatorClass scale={scale} />;
};
