/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useLoggingService } from "../services/backend/LoggingService";
import { useEffect, useRef, useState } from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";

export interface LogPopupProps {
  visible: boolean;
  logName: string;
  onClose: () => void;
  onShowLogView: () => void;
}

export const LogPopup = ({ visible, logName, onClose, onShowLogView }: LogPopupProps) => {
  const loggingService = useLoggingService();

  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [logData, setLogData] = useState<string | undefined>(undefined);
  const loadTimeoutId = useRef<any>(undefined);

  const loadNextLogChunk = async (logName: string, timestamp: number, data: string) => {
    try {
      const output = await loggingService.loadFrom(logName, timestamp);
      const i = output.indexOf(",");
      if (i > -1) {
        const newTimestamp = parseInt(output.substring(0, i)) + 1;
        if (newTimestamp > 1) {
          const newData = data + output.substring(i + 1);
          setLogData(newData);
          loadTimeoutId.current = setTimeout(loadNextLogChunk, 3000, logName, newTimestamp, newData);
        } else {
          loadTimeoutId.current = setTimeout(loadNextLogChunk, 3000, logName, timestamp, data);
        }
      } else {
        setLogData(undefined);
      }
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not continue to load the log file.");
      setAlertSeverity("error");
    }
  };

  const loadLog = async (logName: string) => {
    try {
      const output = await loggingService.load(logName);
      const i = output.indexOf(",");
      if (i > -1) {
        const timestamp = parseInt(output.substring(0, i)) + 1;
        const data = output.substring(i + 1);
        setLogData(data);
        loadTimeoutId.current = setTimeout(loadNextLogChunk, 3000, logName, timestamp, data);
      } else {
        setLogData(undefined);
      }
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not load the log file.");
      setAlertSeverity("error");
    }
  };

  const alertCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(undefined);
  };

  useEffect(() => {
    if (loadTimeoutId.current) {
      clearTimeout(loadTimeoutId.current);
      loadTimeoutId.current = undefined;
    }
    if (!visible) {
      setLogData(undefined);
      return;
    }
    (async () => loadLog(logName))();
    return () => {
      if (loadTimeoutId.current) {
        clearTimeout(loadTimeoutId.current);
        loadTimeoutId.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, logName]);

  return (
    <Dialog open={visible} onClose={onClose} PaperProps={{ sx: { width: "100%", maxWidth: "80vw!important" } }}>
      <DialogTitle id="alert-dialog-title">{logName}</DialogTitle>
      <DialogContent>
        {logData && <CodeMirror value={logData} editable={false} height={`80vh`} extensions={[json()]} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" autoFocus>
          Close
        </Button>
        <Button onClick={onShowLogView}>Show Log View</Button>
      </DialogActions>
      <Snackbar open={alertMessage !== undefined} autoHideDuration={5000} onClose={alertCloseHandler}>
        <Alert onClose={alertCloseHandler} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
