import { fetchWithErrorHandling, buildGetOptions } from "../FetchUtils";
import { useAuthUser } from "react-auth-kit";
import { useContext } from "react";
import { AppContext } from "utils/AppContext";

export const swaggerAccess = async (accessToken: string, baseURL: string) => {
  return fetchWithErrorHandling(baseURL + "v1/system/swagger-access", buildGetOptions(accessToken));
};

export const useSystemService = () => {
  const authUser = useAuthUser();
  const accessToken = authUser()?.access_token;
  const appContext = useContext(AppContext);

  const getBaseURL = () => appContext?.selectedEnvironment?.baseURL || "http://localhost:3001/";

  return {
    swaggerAccess: () => swaggerAccess(accessToken, getBaseURL()),
  };
};
