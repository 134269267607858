// Adapted for our needs from https://github.com/react-atomic/react-atomic-organism/blob/main/packages/organism-react-d3-uml/src/ui/molecules/LineDefaultLayout.jsx

import React from "react";
import { Line as LineGraph, Group } from "organism-react-graph";
import { build } from "react-atomic-molecule";
import { BaseLayout } from "organism-react-d3-uml";

class LineLayout extends BaseLayout {
  static defaultProps = {
    markerEnd: "url(#marker-arrow-head)",
    curve: true,
  };

  getEl() {
    return this.el;
  }

  getLinePoint(len, maxPercent) {
    const lEl = this.lineEl;
    if (lEl) {
      const totalLen = lEl.getTotalLength();
      const pcntLen = Math.floor((maxPercent * totalLen) / 100);
      const thisLen = len > pcntLen ? pcntLen : len;
      if (totalLen) {
        return lEl.getPointAtLength(thisLen);
      }
    }
  }

  handleEl = (el) => {
    this.el = el;
  };

  handleLineEl = (el) => {
    if (el) {
      this.lineEl = el;
    }
  };

  render() {
    const { id, start, end, onMouseEnter, onMouseLeave, curve, ...other } = this.props;
    const compLine = build(<LineGraph start={start} end={end} curve={curve} />);
    return (
      <Group name={id} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} refCb={this.handleEl}>
        {compLine({
          ...other,
          refCb: this.handleLineEl,
          className: "line",
          style: Styles.line,
        })}
      </Group>
    );
  }
}

export default LineLayout;

const Styles = {
  line: {
    stroke: "#bbb",
    strokeWidth: 1.5,
    fill: "none",
  },
  area: {
    strokeLinecap: "round",
    stroke: "#999",
    strokeWidth: 15,
    strokeOpacity: 0,
    fill: "none",
    cursor: "default",
  },
  hover: {
    strokeOpacity: ".1",
  },
};
