export const sha256 = async (str: string): Promise<ArrayBuffer> => {
  return crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));
};

export const generateNonce = async (): Promise<string> => {
  const hash = await sha256(crypto.getRandomValues(new Uint32Array(4)).toString());
  const hashArray = Array.from(new Uint8Array(hash));

  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
};
