/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import {
  AlertColor,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Notify } from "./Notify";
import { IDataStructure, useDataSchemaService } from "../services/backend/DataSchemaService";

export interface DataStructurePickerProps {
  visible: boolean;
  branch: string;
  dialogTitle: string;
  actionButtonLabel: string;
  onCancel: () => void;
  onAction: (dataStructure: IDataStructure) => Promise<void>;
}

export const DataStructurePicker = ({
  visible,
  branch,
  dialogTitle,
  actionButtonLabel,
  onCancel,
  onAction,
}: DataStructurePickerProps) => {
  const dataSchemaService = useDataSchemaService();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [dataStructures, setDataStructures] = useState<IDataStructure[]>([]);
  const refDataStructure = useRef<SelectProps>(null);
  const [dataStructureError, setDataStructureError] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => fetchDataStructures())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataStructures = async () => {
    try {
      const { dataStructures } = await dataSchemaService.loadBranch(branch);
      setDataStructures(dataStructures);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to load available data-structures.");
      setAlertSeverity("error");
    }
  };

  const actionButtonClickHandler = async () => {
    if (!refDataStructure.current?.value) {
      setDataStructureError("You must select a data-structure");
      return;
    } else {
      setLoading(true);
      setDataStructureError(undefined);
      const selectedDataStructureName = refDataStructure.current.value as string;
      const dataStructure = dataStructures.find((env) => env.name === selectedDataStructureName);
      if (dataStructure) {
        await onAction(dataStructure);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={visible}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <FormControl sx={{ m: 1, minWidth: 540 }}>
                <InputLabel id="data-structure-label">Type (data-structure)</InputLabel>
                <Select
                  labelId="data-structure-label"
                  id="data-structure-selector"
                  defaultValue=""
                  inputRef={refDataStructure}
                  label="Type (data-structure)"
                  error={dataStructureError !== undefined}
                >
                  {dataStructures.map((ds) => (
                    <MenuItem key={ds.name} value={ds.name}>
                      {ds.name}
                    </MenuItem>
                  ))}
                </Select>
                {dataStructureError && <FormHelperText error={true}>{dataStructureError}</FormHelperText>}
              </FormControl>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel} color="error" disabled={loading}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={actionButtonClickHandler} disabled={loading}>
            {actionButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
    </>
  );
};
