import React, { useState, useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { getUserInfo, ICognitoUserInfo } from "../../services/aws/CognitoService";
import { Background } from "../../components/Background";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { AppContext } from "utils/AppContext";

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<ICognitoUserInfo>({});

  const authUser = useAuthUser();

  const getCognitoUserInfo = async (): Promise<void> => {
    const accessToken = authUser()?.access_token || "";
    const fetchedUserInfo = await getUserInfo(accessToken);
    if (fetchedUserInfo.email) {
      setUserInfo(fetchedUserInfo);
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => getCognitoUserInfo())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderProfileData = () => {
    return (
      <>
        <Box sx={{ minWidth: 275 }}>
          <Card variant="outlined">
            <CardContent>
              <Typography mb={2} variant="h5" color="text.secondary" gutterBottom>
                Profile
              </Typography>
              <Typography variant="body1">E-mail:</Typography>
              <Typography variant="body2" color="text.secondary">
                {userInfo.email}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </>
    );
  };
  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Profile");
    context?.setTargetAPI("registry");
  }, [context]);
  return (
    <>
      <Background blur="20px" opacity="25%" zIndex={-1} />
      <>{loading ? <LoadingOverlay /> : renderProfileData()}</>
    </>
  );
};

export default Profile;
