/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Card, CardContent, CardHeader, Grid, AlertColor, CircularProgress } from "@mui/material";
import { Notify } from "components/Notify";
import { YesNoPopup } from "components/YesNoPopup";
import React, { useEffect, useState } from "react";
import { IPackage, usePackagesService } from "services/registry/PackageService";
import { IWad, useWadService } from "services/registry/WadsService";
import { AppContext } from "utils/AppContext";
import { PackageForm, PkgFormData, PkgFormType } from "views/Registry/StaticData/PackageForm";
import { PackagesTable } from "views/Registry/StaticData/PackageTable";
import { WadsTable } from "views/Registry/StaticData/WadsTable";
import { FileUpload, FileUploadData } from "../../../components/FileUpload";

const StaticData = () => {
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const wadService = useWadService();
  const packagesService = usePackagesService();
  const [wads, setWads] = useState<IWad[]>([]);
  const [packages, setPackages] = useState<IPackage[]>([]);
  const [selectedWad, setSelectedWad] = useState<IWad | undefined>(undefined);
  const [selectedPkg, setSelectedPkg] = useState<IPackage | undefined>(undefined);
  const [wadFormVisible, setWadFormVisible] = useState(false);
  const [pkgFormVisible, setPkgFormVisible] = useState(false);
  const [pkgFormType, setPkgFormType] = useState<PkgFormType>("edit");
  const [loadingWads, setLoadingWads] = useState(true);
  const [loadingPkgs, setLoadingPkgs] = useState(true);
  const [deletePopupWadVisible, setDeletePopupWadVisible] = useState(false);
  const [deletePopupPkgVisible, setDeletePopupPkgVisible] = useState(false);
  const [publishPopupWadVisible, setPublishPopupWadVisible] = useState(false);
  const [publishPopupPkgVisible, setPublishPopupPkgVisible] = useState(false);

  useEffect(() => {
    (async () => fetchWads())();
    (async () => fetchPackages())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchWads = async () => {
    try {
      setLoadingWads(true);
      const { list } = await wadService.listWads([]);
      setWads(list);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to get Wads.");
      setAlertSeverity("error");
    } finally {
      setLoadingWads(false);
    }
  };

  const fetchPackages = async () => {
    try {
      setLoadingPkgs(true);
      const { list } = await packagesService.listPackages();
      setPackages(list);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to get Packages.");
      setAlertSeverity("error");
    } finally {
      setLoadingPkgs(false);
    }
  };

  const getUniqueLabels = (): string[] => {
    const set = new Set<string>();
    // eslint-disable-next-line array-callback-return
    wads.map((row) => {
      if (row.labels && row.labels.length > 0) return row.labels.map((l) => set.add(l));
    });

    return Array.from(set);
  };
  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Wads & Packages");
    context?.setTargetAPI("registry");
  }, [context]);

  async function handleRemoveWad(id: string | undefined): Promise<void> {
    if (!id) return;
    try {
      setDeletePopupWadVisible(false);
      setLoadingWads(true);
      await wadService.remove(id);
      await fetchWads();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to remove the Wad.");
      setAlertSeverity("error");
    } finally {
      setLoadingWads(false);
    }
  }

  async function handleAddWad(): Promise<void> {
    try {
      setLoadingWads(true);
      await wadService.create();
      await fetchWads();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to create a Wad.");
      setAlertSeverity("error");
    } finally {
      setLoadingWads(false);
    }
  }

  async function handleAcceptWad(data: FileUploadData): Promise<void> {
    if (!selectedWad) {
      return;
    }

    try {
      if (data.labels) {
        await wadService.setLabels(selectedWad.id, data.labels);
      }
      if (data.file) {
        await wadService.fileUpload(selectedWad.id, data.file);
      }
      setWadFormVisible(false);
      setLoadingWads(true);
      await fetchWads();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Error editing Wad");
      setAlertSeverity("error");
    } finally {
      setLoadingWads(false);
    }
  }

  async function handlePublishWad(id: string | undefined): Promise<void> {
    if (!id) return;
    try {
      setLoadingWads(true);
      await wadService.publish(id);
      await fetchWads();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Error publishing the Wad.");
      setAlertSeverity("error");
    } finally {
      setLoadingWads(false);
      setPublishPopupWadVisible(false);
    }
  }

  async function handleRemovePkg(id: string | undefined): Promise<void> {
    if (!id) return;
    try {
      setDeletePopupPkgVisible(false);
      setLoadingPkgs(true);
      await packagesService.remove(id);
      await fetchPackages();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to remove the Package.");
      setAlertSeverity("error");
    } finally {
      setLoadingPkgs(false);
    }
  }

  async function handleAddPkg(): Promise<void> {
    try {
      setLoadingPkgs(true);
      await packagesService.create();
      await fetchPackages();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to create a Package.");
      setAlertSeverity("error");
    } finally {
      setLoadingPkgs(false);
    }
  }

  async function handleAcceptPkg(data: PkgFormData): Promise<void> {
    try {
      await packagesService.setWads(data.id, data.wads);
      setPkgFormVisible(false);
      setLoadingPkgs(true);
      await fetchPackages();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Error editing Package");
      setAlertSeverity("error");
    } finally {
      setLoadingPkgs(false);
    }
  }

  async function handlePublishPkg(id: string | undefined): Promise<void> {
    if (!id) return;
    try {
      setLoadingPkgs(true);
      await packagesService.publish(id);
      await fetchPackages();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Error publishing the Package.");
      setAlertSeverity("error");
    } finally {
      setLoadingPkgs(false);
      setPublishPopupPkgVisible(false);
    }
  }

  function handleEditPkg(): void {
    setPkgFormType("edit");
    setPkgFormVisible(true);
  }

  const wadFileNames = () => {
    if (!selectedWad?.files) {
      return [];
    }

    return selectedWad.files.map((file) => file.name);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div style={{ position: "relative" }}>
            {loadingWads && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  background: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1,
                }}
              />
            )}
            <Card>
              <CardHeader title="Wads" />
              <CardContent>
                {loadingWads && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                )}
                <WadsTable
                  rows={wads}
                  selectedItem={selectedWad}
                  setSelectedItem={setSelectedWad}
                  onAddButtonClick={handleAddWad}
                  onEditButtonClick={() => setWadFormVisible(true)}
                  onRemoveButtonClick={setDeletePopupWadVisible}
                  onPublishButtonClick={setPublishPopupWadVisible}
                />
              </CardContent>
            </Card>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ position: "relative" }}>
            {loadingPkgs && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  background: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1,
                }}
              />
            )}
            <Card>
              <CardHeader title="Packages" />
              <CardContent>
                {loadingPkgs && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                )}
                <PackagesTable
                  rows={packages}
                  selectedItem={selectedPkg}
                  setSelectedItem={setSelectedPkg}
                  onAddButtonClick={handleAddPkg}
                  onEditButtonClick={handleEditPkg}
                  onRemoveButtonClick={setDeletePopupPkgVisible}
                  onPublishButtonClick={setPublishPopupPkgVisible}
                />
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
      <FileUpload
        visible={wadFormVisible}
        title="Edit Wad"
        fileUploadConf={{
          showExistingFiles: true,
          existingFiles: wadFileNames(),
          showSetLabels: true,
          initialLabels: getUniqueLabels(),
        }}
        onDismiss={() => setWadFormVisible(false)}
        onAccept={handleAcceptWad}
      />
      {pkgFormVisible && (
        <PackageForm
          type={pkgFormType}
          initialData={selectedPkg}
          visible={pkgFormVisible}
          onAccept={handleAcceptPkg}
          onDismiss={() => setPkgFormVisible(false)}
          wadsFilter={wads.map((w) => w.id)}
        />
      )}
      <YesNoPopup
        visible={deletePopupWadVisible}
        title={"Remove Wad"}
        message={`Are you sure you want to remove the Wad with ID <b>${selectedWad?.id}</b>?<br/>`}
        onNo={() => setDeletePopupWadVisible(false)}
        onYes={() => handleRemoveWad(selectedWad?.id)}
      />
      <YesNoPopup
        visible={deletePopupPkgVisible}
        title={"Remove Package"}
        message={`Are you sure you want to remove the Package with ID <b>${selectedPkg?.id}</b>?<br/>`}
        onNo={() => setDeletePopupPkgVisible(false)}
        onYes={() => handleRemovePkg(selectedPkg?.id)}
      />
      <YesNoPopup
        visible={publishPopupWadVisible}
        title={"Publish Wad"}
        message={`Are you sure you want to publish the Wad with ID <b>${selectedWad?.id}</b>?<br/>
                If you publish this Wad, you won't be able to edit or remove it later.`}
        onNo={() => setPublishPopupWadVisible(false)}
        onYes={() => handlePublishWad(selectedWad?.id)}
      />
      <YesNoPopup
        visible={publishPopupPkgVisible}
        title={"Publish Package"}
        message={`Are you sure you want to publish the Package with ID <b>${selectedPkg?.id}</b>?<br/>
                If you publish this Package, you won't be able to edit or remove it later.`}
        onNo={() => setPublishPopupPkgVisible(false)}
        onYes={() => handlePublishPkg(selectedPkg?.id)}
      />
    </>
  );
};

export default StaticData;
