/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useState, useCallback, useEffect } from "react";
import { Alert, AlertColor, Button, FormControl, InputLabel, MenuItem, Select, Snackbar } from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { IDataEntity, IDataStructure, ITestDataset, useDataSchemaService } from "../../services/backend/DataSchemaService";
import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DownloadIcon from "@mui/icons-material/Download";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { validateIntegerField, validateTfidField } from "../../utils/Validation";
import DeleteIcon from "@mui/icons-material/Delete";
import { YesNoPopup } from "../../components/YesNoPopup";
import { StringInputPopup } from "../../components/StringInputPopup";
import { DataStructurePicker } from "../../components/DataStructurePicker";

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export interface TestDataEditorProps {
  visible: boolean;
  branch: string;
  testDataset: ITestDataset;
  onClose: () => void;
  onUpdate: (testDataset: ITestDataset) => void;
}

const TestDatasetEditor = ({ visible, branch, testDataset, onClose, onUpdate }: TestDataEditorProps) => {
  const dataSchemaService = useDataSchemaService();

  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [selectedEntity, setSelectedEntity] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [entityDataJson, setEntityDataJson] = useState<string>("{}");
  const [importFormVisible, setImportFormVisible] = useState<boolean>(false);
  const [deletePopupVisible, setDeletePopupVisible] = useState<boolean>(false);
  const [dataStructurePickerVisible, setDataStructurePickerVisible] = useState<boolean>(false);

  const alertCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(undefined);
  };

  const newEntityButtonClickHandler = () => {
    setDataStructurePickerVisible(true);
  };

  const importEntityButtonClickHandler = () => {
    setImportFormVisible(true);
  };

  const importFormDismissHandler = () => {
    setImportFormVisible(false);
  };

  const importFormAcceptHandler = async (id: string) => {
    try {
      setImportFormVisible(false);
      setIsLoading(true);
      const res = await dataSchemaService.importEntityToTestDataset(branch, testDataset.name, id);
      if (res.testDataset) {
        onUpdate(res.testDataset);
      }
      setAlertMessage("Entity imported successfully.");
      setAlertSeverity("success");
      setSelectedEntity(id);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not import the entity.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const reloadEntityButtonClickHandler = async () => {
    if (!selectedEntity) {
      return;
    }
    try {
      setIsLoading(true);
      const { entityData } = await dataSchemaService.loadEntityFromTestDataset(branch, testDataset.name, selectedEntity);
      if (entityData) {
        setEntityDataJson(JSON.stringify(entityData, null, 2));
      }
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not load the entity.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedEntity) {
      reloadEntityButtonClickHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntity]);

  const dataStructurePickerActionHandler = async (dataStructure: IDataStructure) => {
    try {
      setIsLoading(true);
      setDataStructurePickerVisible(false);
      const res = await dataSchemaService.generateEntityIdInTestDataset(branch, testDataset.name, dataStructure.name);
      if (res.id && res.schemaVersion) {
        setSelectedEntity(undefined);
        setEntityDataJson(
          JSON.stringify(
            {
              ...res.defaultData,
              id: res.id,
              version: 1,
              schemaVersion: res.schemaVersion,
            },
            null,
            2,
          ),
        );
      }
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not create the entity.");
    } finally {
      setIsLoading(false);
    }
  };

  const storeEntityButtonClickHandler = async () => {
    setAlertSeverity("error");
    let entityData: IDataEntity | undefined = undefined;
    try {
      entityData = JSON.parse(entityDataJson) as IDataEntity;
    } catch (e) {
      setAlertMessage("Invalid JSON.");
      return;
    }
    if (!validateTfidField(entityData.id, setAlertMessage, "id")) return;
    if (!validateIntegerField(entityData.version as unknown as string, setAlertMessage, 1, 999, "version")) return;
    if (!validateIntegerField(entityData.schemaVersion as unknown as string, setAlertMessage, 1, 999, "schemaVersion"))
      return;
    try {
      setIsLoading(true);
      const res = await dataSchemaService.storeEntityInTestDataset(branch, testDataset.name, entityData);
      if (res.testDataset) {
        onUpdate(res.testDataset);
      }
      setAlertMessage("Entity stored successfully.");
      setAlertSeverity("success");
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not store the entity.");
    } finally {
      setIsLoading(false);
    }
    setEntityDataJson(JSON.stringify(entityData, null, 2));
    if (entityData.id !== selectedEntity) {
      setSelectedEntity(entityData.id);
    }
  };

  const deleteEntityButtonClickHandler = () => {
    setDeletePopupVisible(true);
  };

  const deletePopupNoHandler = () => {
    setDeletePopupVisible(false);
  };

  const deletePopupYesHandler = async () => {
    if (!selectedEntity) {
      return;
    }
    try {
      setDeletePopupVisible(false);
      setIsLoading(true);
      const res = await dataSchemaService.removeEntityFromTestDataset(branch, testDataset.name, selectedEntity);
      if (res.testDataset) {
        onUpdate(res.testDataset);
      }
      setAlertMessage("Entity deleted successfully.");
      setAlertSeverity("success");
      setSelectedEntity(undefined);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not delete the entity.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  const codeMirrorChangeHandler = useCallback((value: string) => {
    setEntityDataJson(value);
  }, []);

  return (
    <Dialog open={visible} onClose={dialogCloseHandler} fullScreen>
      <DialogTitle>{`Test Dataset: ${testDataset.name}`}</DialogTitle>
      <DialogContent>
        <Header>
          <Button variant="contained" startIcon={<AddIcon />} onClick={newEntityButtonClickHandler} sx={{ marginRight: 1 }}>
            New
          </Button>
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={importEntityButtonClickHandler}
            sx={{ marginRight: 1 }}
          >
            Import
          </Button>
          <FormControl sx={{ mt: 1, width: "240px" }}>
            <InputLabel id="entity-selector-label">Entity</InputLabel>
            <Select
              labelId="entity-selector-label"
              id="entity-selector"
              value={selectedEntity}
              onChange={(e) => setSelectedEntity(e.target.value)}
              label="Entity"
            >
              {testDataset.entities.map((entity) => (
                <MenuItem key={entity} value={entity}>
                  {entity}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            startIcon={<RestartAltIcon />}
            onClick={reloadEntityButtonClickHandler}
            sx={{ marginLeft: 1 }}
            disabled={selectedEntity === undefined}
          >
            Reload
          </Button>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={storeEntityButtonClickHandler}
            sx={{ marginLeft: 1 }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            startIcon={<DeleteIcon />}
            onClick={deleteEntityButtonClickHandler}
            sx={{ marginLeft: 1 }}
            disabled={selectedEntity === undefined}
          >
            Delete
          </Button>
          <div style={{ flexGrow: 1 }} />
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </Header>
        <CodeMirror
          value={entityDataJson}
          height="82vh"
          width="97vw"
          extensions={[json()]}
          onChange={codeMirrorChangeHandler}
          style={{ marginTop: 16 }}
        />
      </DialogContent>
      <DataStructurePicker
        visible={dataStructurePickerVisible}
        branch={branch}
        dialogTitle="Select the type of this new entity:"
        actionButtonLabel="Select"
        onCancel={() => setDataStructurePickerVisible(false)}
        onAction={dataStructurePickerActionHandler}
      />
      <YesNoPopup
        visible={deletePopupVisible}
        title={"Delete Entity"}
        message={`Are you sure you want to delete the Entity with ID <b>${selectedEntity}</b> from this test dataset?<br/>`}
        onNo={deletePopupNoHandler}
        onYes={deletePopupYesHandler}
      />
      <StringInputPopup
        visible={importFormVisible}
        title="Import Entity"
        message="Please enter the ID of the Entity to import:"
        onDismiss={importFormDismissHandler}
        onAccept={importFormAcceptHandler}
      />
      <Snackbar open={alertMessage !== undefined} autoHideDuration={5000} onClose={alertCloseHandler}>
        <Alert onClose={alertCloseHandler} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {isLoading && <LoadingOverlay />}
    </Dialog>
  );
};

export default TestDatasetEditor;
