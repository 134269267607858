/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridToolbarQuickFilter,
  GridTreeNode,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Alert, AlertColor, FormControlLabel, Grid, Snackbar, Switch } from "@mui/material";
import { useWindowDimensions } from "../../utils/WindowDimensions";
import DownloadIcon from "@mui/icons-material/Download";
import { LogViewer as ReactLogViewer } from "@patternfly/react-log-viewer";
import { useLoggingService } from "../../services/backend/LoggingService";
import { AppContext } from "../../utils/AppContext";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { useLocation } from "react-router-dom";
import { DateTime } from "luxon";

enum DownloadState {
  CLEAR_STATE = 0x0,
  BUTTON_CLICK,
  LOG_DATA_READY,
  READY_TO_DOWNLOAD,
}

const LogViewer = () => {
  const location = useLocation();
  const loggingService = useLoggingService();

  // Log text wrapper
  const [isTextWrapped, setIsTextWrapped] = useState(false);

  // Alerts
  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");

  // Polling log state
  const listTimeoutId = useRef<any>(undefined);
  const loadTimeoutId = useRef<any>(undefined);

  const loadNextLogChunk = async (logName: string, timestamp: number, data: string) => {
    try {
      const output = await loggingService.loadFrom(logName, timestamp);
      const i = output.indexOf(",");
      if (i > -1) {
        const newTimestamp = parseInt(output.substring(0, i)) + 1;
        if (newTimestamp > 1) {
          const newData = data + output.substring(i + 1);
          setLogData(newData);
          loadTimeoutId.current = setTimeout(loadNextLogChunk, 3000, logName, newTimestamp, newData);
        } else {
          loadTimeoutId.current = setTimeout(loadNextLogChunk, 3000, logName, timestamp, data);
        }
      } else {
        setLogData("");
      }
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not continue to load the log file.");
      setAlertSeverity("error");
    }
  };

  const alertCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(undefined);
  };

  const downloadLogFile = () => {
    const file = new Blob([logData], { type: "text/plain;charset=UTF-8" });
    const url = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.download = `${selectedRow?.id.toString() ?? "log_file"}.txt`;
    link.href = url;
    link.click();
  };

  const isReadyToDownload = () => {
    const prevRowId = previousRowId.current;
    const curRowId = selectedRow?.id.toString();

    /*
      check if the export button was clicked and the correspondent log file was fetched from backend before downloading
     */
    return (
      downloadLog === DownloadState.READY_TO_DOWNLOAD ||
      (curRowId && prevRowId === curRowId && downloadLog & DownloadState.BUTTON_CLICK)
    );
  };

  // Log Metadata and Data implementation
  const [logMetadataList, setLogMetadataList] = useState<any[]>([]);
  const [logData, setLogData] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<GridTreeNode | GridRowParams | undefined>(undefined);
  const [downloadLog, setDownloadLog] = useState<number>(DownloadState.CLEAR_STATE);
  const previousRowId = useRef<string | undefined>(undefined);

  const fetchLogMetadataList = async () => {
    try {
      const { list } = await loggingService.list();
      setLogMetadataList(list);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not fetch the list of log files.");
      setAlertSeverity("error");
      setLogMetadataList([]);
      setLogData("");
    }

    if (listTimeoutId.current) {
      clearTimeout(listTimeoutId.current);
    }
    listTimeoutId.current = setTimeout(fetchLogMetadataList, 15000);
  };

  const loadLog = async (logName: string) => {
    setLoading(true);
    try {
      const output = await loggingService.load(logName);
      const i = output.indexOf(",");
      if (i > -1) {
        const timestamp = parseInt(output.substring(0, i)) + 1;
        const data = output.substring(i + 1);
        setLogData(data);
        setDownloadLog(downloadLog | DownloadState.LOG_DATA_READY);
        loadTimeoutId.current = setTimeout(loadNextLogChunk, 3000, logName, timestamp, data);
      } else {
        setLogData("");
        setDownloadLog(DownloadState.CLEAR_STATE);
      }
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not load the log file.");
      setAlertSeverity("error");
    }
    setLoading(false);
  };

  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Playfab Logs");
    context?.setTargetAPI("environment");
    (async () => fetchLogMetadataList())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.selectedEnvironment]);

  useEffect(() => {
    if (loadTimeoutId.current) {
      clearTimeout(loadTimeoutId.current);
      loadTimeoutId.current = undefined;
    }
    if (selectedRow) {
      loadLog(selectedRow.id.toString());
    }
  }, [selectedRow?.id.toString()]);

  useEffect(() => {
    if (isReadyToDownload()) {
      downloadLogFile();
      previousRowId.current = "";
      setDownloadLog(DownloadState.CLEAR_STATE);
    }
  }, [logData, downloadLog]);

  useEffect(() => {
    return () => {
      if (listTimeoutId.current) {
        clearTimeout(listTimeoutId.current);
        listTimeoutId.current = undefined;
      }
      if (loadTimeoutId.current) {
        clearTimeout(loadTimeoutId.current);
        loadTimeoutId.current = undefined;
      }
    };
  }, [location]);

  const handleWrapTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTextWrapped(event.target.checked);
  };

  const LoggingToolbar = () => {
    return (
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid xs={8} item>
          <Box sx={{ p: 0.5, pb: 0 }}>
            <GridToolbarQuickFilter
              quickFilterParser={(searchInput: string) =>
                searchInput
                  .split(",")
                  .map((value) => value.trim())
                  .filter((value) => value !== "")
              }
            />
          </Box>
        </Grid>
        <Grid xs={4} item>
          <FormControlLabel
            control={<Switch defaultChecked size="small" onChange={handleWrapTextChange} />}
            label="Wrap Text"
          />
        </Grid>
      </Grid>
    );
  };

  const { height } = useWindowDimensions();

  const [columns] = useState<GridColDef[]>([
    { headerName: "Log Name", field: "name", flex: 1 },
    {
      headerName: "Size (MB)",
      field: "size",
      width: 100,
      type: "number",
      valueGetter: (size) => (size / 1024 / 1024).toFixed(4),
    },
    {
      headerName: "Created At",
      field: "createdAt",
      width: 120,
      type: "dateTime",
      valueFormatter: (createdAt) => {
        if (!createdAt) return;
        return DateTime.fromISO(createdAt);
      },
    },
    {
      cellClassName: "actions",
      field: "export",
      getActions: (row) => {
        return [
          <GridActionsCellItem
            key={row.id}
            label="export"
            icon={<DownloadIcon />}
            onClick={() => {
              setSelectedRow(row);
              setDownloadLog(DownloadState.BUTTON_CLICK);
            }}
          />,
        ];
      },
      headerName: "Export",
      type: "actions",
      width: 65,
    },
  ]);

  const { panelHeight } = useMemo(() => {
    const panelHeight = Math.max(height - 120, 200);
    return { panelHeight };
  }, [height]);

  return (
    <div style={{ flexDirection: "row", display: "flex", justifyContent: "space-between" }}>
      <Box style={{ width: `${logData ? "30%" : "100%"}` }}>
        <DataGrid
          onCellClick={(selectedCell) => {
            previousRowId.current = selectedRow?.id.toString();
            setSelectedRow(selectedCell.rowNode);
          }}
          getRowId={(row) => row.name}
          rowHeight={25}
          style={{ maxHeight: panelHeight, backgroundColor: "white" }}
          slots={{ toolbar: LoggingToolbar }}
          rows={logMetadataList}
          columns={columns}
          slotProps={{ toolbar: { showQuickFilter: true } }}
        />
      </Box>
      {logData && (
        <Box style={{ paddingLeft: "16px", width: "70%" }}>
          <Box
            style={{
              paddingLeft: "8px",
              backgroundColor: "white",
              border: "1px solid rgb(224, 224, 224, 1)",
              borderRadius: "4px",
            }}
          >
            <ReactLogViewer data={logData} height={panelHeight} width="100%" isTextWrapped={isTextWrapped} />
          </Box>
        </Box>
      )}
      <Snackbar open={alertMessage !== undefined} autoHideDuration={5000} onClose={alertCloseHandler}>
        <Alert onClose={alertCloseHandler} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {loading && <LoadingOverlay />}
    </div>
  );
};

export default LogViewer;
