import { createContext } from "react";
import { IEnvironment } from "../services/registry/EnvironmentService";

export type TargetAPI = "registry" | "environment";

type AppContextType = {
  title: string;
  setTitle: (newValue: string) => void;
  targetAPI: TargetAPI;
  setTargetAPI: (newValue: TargetAPI) => void;
  selectedEnvironment: IEnvironment | undefined;
  setSelectedEnvironment: (newValue: IEnvironment | undefined) => void;
};

export const AppContext = createContext<AppContextType | undefined>(undefined);
