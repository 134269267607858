import { buildGetOptions, fetchWithErrorHandling } from "../FetchUtils";
import { useContext } from "react";
import { AppContext } from "../../utils/AppContext";
import { useAuthUser } from "react-auth-kit";

export interface IIntegrationTestLogsListResponseDto {
  list: IIntegrationTestLogsIndexEntry[];
}

export interface IIntegrationTestLogsIndexEntry {
  testName: string;
  creationDate: number;
  logFile: string;
  dataFile: string;
}

export interface IIntegrationTestLogsResponseDto {
  logs: string;
}

export interface IIntegrationTestDataResponseDto {
  data: object[];
}

export const list = async (baseURL: string, accessToken: string): Promise<IIntegrationTestLogsListResponseDto> => {
  return fetchWithErrorHandling(baseURL + `v1/integration-test/logs/list`, buildGetOptions(accessToken));
};

export const log = async (
  baseURL: string,
  accessToken: string,
  testName: string,
): Promise<IIntegrationTestLogsResponseDto> => {
  return fetchWithErrorHandling(baseURL + `v1/integration-test/logs/${testName}`, buildGetOptions(accessToken));
};

export const data = async (
  baseURL: string,
  accessToken: string,
  testName: string,
): Promise<IIntegrationTestDataResponseDto> => {
  return fetchWithErrorHandling(baseURL + `v1/integration-test/data/${testName}`, buildGetOptions(accessToken));
};

export const useIntegrationTestService = () => {
  const getBaseURL = () => appContext?.selectedEnvironment?.baseURL || "http://localhost:3001/";
  const appContext = useContext(AppContext);
  const authUser = useAuthUser();
  const accessToken = authUser()?.access_token;

  return {
    list: () => list(getBaseURL(), accessToken),
    logs: (testName: string) => log(getBaseURL(), accessToken, testName),
    data: (testName: string) => data(getBaseURL(), accessToken, testName),
  };
};
