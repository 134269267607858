/* eslint-disable  @typescript-eslint/no-explicit-any */
import { IGeneratedArtIdData, useAssetToolingService } from "../../services/asset/AssetToolingService";
import { useEffect, useState } from "react";
import { AlertColor } from "@mui/material";
import { Notify } from "../../components/Notify";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import * as React from "react";
import { ArtAssetsIdsTable } from "./ArtAssetsIdsTable";
import { useNavigate } from "react-router-dom";
import routes from "../../components/routing/Routes";

const ArtAssets = () => {
  const navigate = useNavigate();
  const assetToolingService = useAssetToolingService();

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [assetIds, setAssetIds] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<IGeneratedArtIdData | undefined>(undefined);

  useEffect(() => {
    (async () => fetchRegisteredIds())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRegisteredIds = async () => {
    try {
      setLoading(true);
      const { ids } = await assetToolingService.list();
      setAssetIds(ids);
      setLoading(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to get available subcategories.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const sendClickupPending = async () => {
    try {
      setLoading(true);
      await assetToolingService.sendClickupPending();
      setLoading(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to send pending assets to clickup.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const sendClickup = async (assetId: string) => {
    try {
      setLoading(true);
      await assetToolingService.sendClickup(assetId);
      setLoading(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to send pending assets to clickup.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const syncClickup = async (assetId: string) => {
    try {
      setLoading(true);
      await assetToolingService.syncClickup(assetId);
      await fetchRegisteredIds();
      setLoading(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to sync sheet with clickup.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ArtAssetsIdsTable
        rows={assetIds}
        onAddButtonClick={() => navigate(routes.artAssetsIdsGenerator)}
        onSyncClickupClick={syncClickup}
        onSendClickupClick={sendClickup}
        onSendClickupPendingClick={sendClickupPending}
        onRefreshRowsClick={fetchRegisteredIds}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
      {loading && <LoadingOverlay />}
    </>
  );
};

export default ArtAssets;
