// Adapted for our needs from https://github.com/react-atomic/react-atomic-organism/blob/main/packages/organism-react-d3-uml/src/ui/molecules/ConnectPointDefaultLayout.jsx

import React from "react";
import { Circle } from "organism-react-graph";
import { BaseLayout } from "organism-react-d3-uml";

class ConnectPointLayout extends BaseLayout {
  getEl() {
    if (this.el) {
      return this.el;
    }
  }

  handleEl = (el) => {
    const { onGetEl } = this.props;
    if (el) {
      onGetEl(el);
      this.el = el;
    }
  };

  render() {
    const { style, ...props } = this.props;
    let thisStyle = { ...style, ...Styles.container };

    return <Circle style={thisStyle} fill="#3c5d9b" fillOpacity="0.4" r="5" {...props} refCb={this.handleEl} />;
  }
}

export default ConnectPointLayout;

const Styles = {
  container: {
    visibility: "hidden",
    cursor: "move",
  },
  visible: {
    visibility: "visible",
  },
};
