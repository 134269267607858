import { Background } from "./Background";
import { AppFrame } from "./AppFrame";
import { LoadingOverlay } from "./LoadingOverlay";
import { useIsAuthenticated } from "services/auth/AuthService";
import { useConnectionStateManager } from "../services/FetchUtils";
import { DisconnectionOverlay } from "./DisconnectionOverlay";

export const AppView = () => {
  const isAuthenticated = useIsAuthenticated();
  const [isConnected] = useConnectionStateManager();

  return (
    <>
      <Background blur="20px" opacity="25%" zIndex={-1} />
      <AppFrame />
      {!isAuthenticated && <LoadingOverlay />}
      {!isConnected && <DisconnectionOverlay />}
    </>
  );
};
