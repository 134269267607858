import React from "react";
import styled from "@emotion/styled";
import { Backdrop, CircularProgress } from "@mui/material";

const LoadingText = styled.div`
  font-family: "Helvetica Neue";
  font-weight: bold;
  font-size: x-large;
  margin-top: 12px;
`;

export const LoadingOverlay = () => {
  return (
    <Backdrop
      style={{ flexDirection: "column" }}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" size={72} />
      <LoadingText>Loading...</LoadingText>
    </Backdrop>
  );
};
