import { IIntegrationTestLogsIndexEntry } from "../../services/backend/IntegrationTestService";
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useMemo, useState } from "react";
import { IManagedParameter } from "../../services/registry/ManagedParameterService";
import { getComparator, Order, stableSort } from "../../utils/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Button, Stack } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TablePagination from "@mui/material/TablePagination";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";

interface HeadCell {
  id: keyof IIntegrationTestLogsIndexEntry;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
}

const headCells: readonly HeadCell[] = [
  {
    id: "testName",
    label: "Name",
    padding: "none",
  },
  {
    id: "logFile",
    label: "Log File",
  },
  {
    id: "dataFile",
    label: "Data File",
  },
  {
    id: "creationDate",
    label: "Creation Date",
    align: "right",
  },
];

interface IntegrationTestsTableHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IManagedParameter | string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const IntegrationTestsTableHead = ({ order, orderBy, onRequestSort }: IntegrationTestsTableHeadProps) => {
  const createSortHandler = (property: keyof IIntegrationTestLogsIndexEntry | string) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export interface IntegrationTestsTableProps {
  rows: IIntegrationTestLogsIndexEntry[];
  selectedItem: IIntegrationTestLogsIndexEntry | undefined;
  setSelectedItem: Dispatch<SetStateAction<IIntegrationTestLogsIndexEntry | undefined>>;
  onShowLogsButtonClickHandler: (row: IIntegrationTestLogsIndexEntry) => void;
  onDownloadLogsButtonClickHandler: (row: IIntegrationTestLogsIndexEntry) => void;
  onShowDataButtonClickHandler: (row: IIntegrationTestLogsIndexEntry) => void;
  onDownloadDataButtonClickHandler: (row: IIntegrationTestLogsIndexEntry) => void;
}

export const IntegrationTestsTable = ({
  rows,
  selectedItem,
  setSelectedItem,
  onShowLogsButtonClickHandler,
  onDownloadLogsButtonClickHandler,
  onShowDataButtonClickHandler,
  onDownloadDataButtonClickHandler,
}: IntegrationTestsTableProps) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof IManagedParameter | string>("creationDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [testNameFilter, setTestNameFilter] = useState<string>("");

  const requestSortHandler = (event: MouseEvent<unknown>, property: keyof IIntegrationTestLogsIndexEntry | string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = useMemo(() => {
    return rows.filter((row) => {
      return row.testName.toLowerCase().includes(testNameFilter.toLowerCase());
    });
  }, [rows, testNameFilter]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    return stableSort(filteredRows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [filteredRows, order, orderBy, page, rowsPerPage]);

  const handleClick = (row: IIntegrationTestLogsIndexEntry, action: (row: IIntegrationTestLogsIndexEntry) => void) => {
    setSelectedItem(row);
    action(row);
  };

  const isSelected = (row: IIntegrationTestLogsIndexEntry, selectedItem: IIntegrationTestLogsIndexEntry | undefined) =>
    selectedItem !== undefined && row.testName === selectedItem.testName;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, pt: { sm: 2 }, pb: { sm: 2 } }}>
          <TextField
            id="outlined-search"
            label="Search test names"
            type="search"
            onChange={(e) => setTestNameFilter(e.target.value)}
            sx={{ width: "360px" }}
          />
        </Toolbar>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="small">
            <IntegrationTestsTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={requestSortHandler}
              rowCount={filteredRows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `integration-tests-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    onClick={() => setSelectedItem(row)}
                    tabIndex={-1}
                    key={row.testName}
                    selected={isSelected(row, selectedItem)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="normal" />
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row.testName}
                    </TableCell>
                    <TableCell align="center">
                      <Stack spacing={1} direction="row">
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<VisibilityIcon />}
                          onClick={() => handleClick(row, onShowLogsButtonClickHandler)}
                        >
                          View
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<FileDownloadIcon />}
                          onClick={() => handleClick(row, onDownloadLogsButtonClickHandler)}
                        >
                          Download
                        </Button>
                      </Stack>
                    </TableCell>
                    <TableCell align="center">
                      <Stack spacing={1} direction="row">
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<VisibilityIcon />}
                          onClick={() => handleClick(row, onShowDataButtonClickHandler)}
                          disabled={!row.dataFile}
                        >
                          View
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<FileDownloadIcon />}
                          onClick={() => handleClick(row, onDownloadDataButtonClickHandler)}
                          disabled={!row.dataFile}
                        >
                          Download
                        </Button>
                      </Stack>
                    </TableCell>
                    <TableCell align="right">{new Date(row.creationDate * 1000).toUTCString()}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={pageChangeHandler}
          onRowsPerPageChange={rowsPerPageChangeHandler}
        />
      </Paper>
    </Box>
  );
};
