import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { Box } from "@mui/material";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export interface ChipArrayProps {
  data: string[];
  setChipData: (data: string[]) => void;
  showDelete: boolean;
}

export default function ChipsArray({ data, setChipData, showDelete }: ChipArrayProps) {
  const handleDelete = (chipToDelete: string) => () => {
    setChipData(data.filter((chip) => chip !== chipToDelete));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          listStyle: "none",
          p: 0.5,
          m: 0,
        }}
        component="ul"
      >
        {data.map((data) => {
          return (
            <ListItem key={data}>
              <Chip label={data} onDelete={showDelete ? handleDelete(data) : undefined} />
            </ListItem>
          );
        })}
      </Box>
    </>
  );
}
