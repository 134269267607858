/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { Alert, AlertColor, Grid, Snackbar } from "@mui/material";
import { AppContext } from "../../utils/AppContext";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { useDataPersistenceService } from "../../services/backend/DataService";
import { PlayersList } from "./PlayersList";
import { BatchOperationsMenu } from "./BatchOperationsMenu";
import { IBookmarkedPlayer } from "./IBookmarkedPlayer";
import { StringInputPopup } from "../../components/StringInputPopup";
import { IPlayerMetadata } from "./IPlayerMetadata";
import { PlayerCard } from "./PlayerCard";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";

const STORAGE_ITEM_KEY = "bookmarkedPlayers";

/* eslint-disable  @typescript-eslint/no-unused-vars */
const Players = () => {
  const context = useContext(AppContext);
  const dataPersistenceService = useDataPersistenceService();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [bookmarkedPlayers, setBookmarkedPlayers] = useState<IBookmarkedPlayer[]>([]);
  const [selectedItem, setSelectedItem] = useState<IBookmarkedPlayer | undefined>(undefined);
  const [allPlayerMetadata, setAllPlayerMetadata] = useState<IPlayerMetadata[]>([]);
  const [selectedPlayerMetadata, setSelectedPlayerMetadata] = useState<IPlayerMetadata | undefined>(undefined);
  const [playerData, setPlayerData] = useState("");
  const [batchOperationsMenuVisible, setBatchOperationsMenuVisible] = useState<boolean>(false);
  const [playerLabelsEditPopupVisible, setPlayerLabelsEditPopupVisible] = useState<boolean>(false);

  useEffect(() => {
    setPlayerData("");
  }, [selectedItem]);

  const knownPlayerIds = useMemo(() => {
    const playerIds: string[] = [];
    allPlayerMetadata.forEach((t) => {
      if (t.playerId) {
        playerIds.push(t.playerId);
      }
    });
    return playerIds;
  }, [allPlayerMetadata]);

  const alertCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(undefined);
  };

  const fetchRows = async () => {
    setIsLoading(true);
    try {
      const { list } = await dataPersistenceService.loadAllActive("PlayerMetadata");
      setAllPlayerMetadata(list as IPlayerMetadata[]);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not fetch all player metadata.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    context?.setTitle("Players Management");
    context?.setTargetAPI("environment");
    const bookmarkedPlayersJson = localStorage.getItem(STORAGE_ITEM_KEY);
    try {
      setBookmarkedPlayers(JSON.parse(bookmarkedPlayersJson || "[]"));
    } catch (e) {
      setBookmarkedPlayers([]);
    }
    (async () => fetchRows())();
  }, [context]);

  useEffect(() => {
    if (bookmarkedPlayers && bookmarkedPlayers.length > 0) {
      localStorage.setItem(STORAGE_ITEM_KEY, JSON.stringify(bookmarkedPlayers));
    }
  }, [bookmarkedPlayers]);

  useEffect(() => {
    if (selectedItem) {
      const playerMetadata = allPlayerMetadata.find((t) => t.playerId === selectedItem.id);
      if (playerMetadata) {
        setSelectedPlayerMetadata(playerMetadata);
      } else {
        setSelectedPlayerMetadata(undefined);
        setAlertMessage(`Unknown player "${selectedItem.id}".`);
        setAlertSeverity("error");
      }
    }
  }, [selectedItem]);

  const playerAddButtonClickHandler = (id: string) => {
    setBookmarkedPlayers((players) =>
      players.findIndex((t) => t.id === id) > -1 ? players : players.concat({ id, labels: "" }),
    );
  };

  const playerRemoveButtonClickHandler = (player: IBookmarkedPlayer) => {
    setBookmarkedPlayers((players) => players.filter((t) => t.id !== player.id));
  };

  const playerLabelsEditAcceptHandler = (newLabels: string) => {
    setPlayerLabelsEditPopupVisible(false);
    if (!selectedItem) {
      return;
    }
    setBookmarkedPlayers((players) => {
      const t = players.find((t) => t.id === selectedItem.id);
      if (t) {
        t.labels = newLabels;
      }
      return Array.from(players);
    });
  };

  const resetUserAgreementForPlayer = async (playerMetadataId: string, index: number, count: number) => {
    setIsLoading(true);
    try {
      const playerMetadata = (await dataPersistenceService.loadDataEntity(
        "PlayerMetadata",
        playerMetadataId,
      )) as IPlayerMetadata;
      playerMetadata.hasAcceptedUserAgreement = false;
      await dataPersistenceService.storeDataEntity("PlayerMetadata", playerMetadata);
      setAlertMessage(`Updated player metadata for "${playerMetadataId}" (${index}/${count}).`);
      setAlertSeverity("success");
    } catch (e: any) {
      setAlertMessage(e.debugMessage || `Could not update player metadata for "${playerMetadataId}".`);
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const setUnsetQaUser = async (playerMetadataId: string, index: number, count: number) => {
    setIsLoading(true);
    try {
      const playerMetadata = (await dataPersistenceService.loadDataEntity(
        "PlayerMetadata",
        playerMetadataId,
      )) as IPlayerMetadata;
      playerMetadata.isQaUser = !playerMetadata.isQaUser;
      await dataPersistenceService.storeDataEntity("PlayerMetadata", playerMetadata);
      setSelectedPlayerMetadata(playerMetadata);
      setAlertMessage(`Updated player metadata for "${playerMetadataId}" (${index}/${count}).`);
      setAlertSeverity("success");
    } catch (e: any) {
      setAlertMessage(e.debugMessage || `Could not update player metadata for "${playerMetadataId}".`);
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const loadPlayerData = async (playerId: string) => {
    setIsLoading(true);
    try {
      const playerData = (await dataPersistenceService.loadDataEntity("player", playerId)) as any;
      setPlayerData(JSON.stringify(playerData, null, 2));
      setAlertSeverity("success");
    } catch (e: any) {
      setAlertMessage(e.debugMessage || `Could not load player data for "${playerId}".`);
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const executeBatchOperation = async (
    playerIds: string[] | undefined,
    functionHandler: (id: string, index: number, count: number) => Promise<void>,
  ) => {
    const filteredPlayerMetadata = playerIds
      ? allPlayerMetadata.filter((t) => t.playerId && playerIds.includes(t.playerId))
      : allPlayerMetadata.filter((t) => t.playerId);
    let index = 1;
    const count = filteredPlayerMetadata.length;
    for (const playerMetadata of filteredPlayerMetadata) {
      await functionHandler(playerMetadata.id, index++, count);
    }
    await fetchRows();
    setBatchOperationsMenuVisible(false);
  };

  return (
    <div style={{ flexDirection: "row", display: "flex", justifyContent: "space-between" }}>
      <Box style={{ width: "20%" }}>
        <PlayersList
          rows={bookmarkedPlayers}
          knownPlayerIds={knownPlayerIds}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          onAddButtonClick={playerAddButtonClickHandler}
          onEditButtonClick={() => setPlayerLabelsEditPopupVisible(true)}
          onRemoveButtonClick={playerRemoveButtonClickHandler}
          onBatchOperationsMenuButtonClick={() => setBatchOperationsMenuVisible(true)}
        />
      </Box>
      {selectedPlayerMetadata !== undefined && (
        <Box style={{ paddingLeft: "16px", width: "80%" }}>
          <PlayerCard
            metadata={selectedPlayerMetadata}
            onResetUserAgreement={async () => {
              if (!selectedPlayerMetadata) {
                return;
              }
              await resetUserAgreementForPlayer(selectedPlayerMetadata.id, 1, 1);
              await fetchRows();
            }}
            onBanUnban={async () => {
              window.open("https://dashboard.intrinsicapi.com/auth/");
            }}
            onLoadPlayerData={async () => {
              if (!selectedPlayerMetadata) {
                return;
              }
              if (selectedPlayerMetadata.playerId) {
                await loadPlayerData(selectedPlayerMetadata.playerId);
              }
            }}
            onSetUnsetQa={async () => {
              if (!selectedPlayerMetadata) {
                return;
              }
              await setUnsetQaUser(selectedPlayerMetadata.id, 1, 1);
              await fetchRows();
            }}
          />
          <Grid item xs={12} justifyItems={"center"} style={{ paddingTop: "16px" }}>
            <CodeMirror value={playerData} height="60vh" extensions={[json()]} onChange={setPlayerData} />
          </Grid>
        </Box>
      )}
      <BatchOperationsMenu
        visible={batchOperationsMenuVisible}
        onClose={() => setBatchOperationsMenuVisible(false)}
        onResetUserAgreement={(playerIds: string[] | undefined) =>
          executeBatchOperation(playerIds, resetUserAgreementForPlayer)
        }
        onSetUnsetQa={(playerIds: string[] | undefined) => executeBatchOperation(playerIds, setUnsetQaUser)}
      />
      <StringInputPopup
        visible={playerLabelsEditPopupVisible}
        title="Edit Labels"
        fieldName="Labels"
        onDismiss={() => setPlayerLabelsEditPopupVisible(false)}
        onAccept={playerLabelsEditAcceptHandler}
      />
      <Snackbar open={alertMessage !== undefined} autoHideDuration={5000} onClose={alertCloseHandler}>
        <Alert onClose={alertCloseHandler} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {isLoading && <LoadingOverlay />}
    </div>
  );
};

export default Players;
