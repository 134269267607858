import { useAuthUser } from "react-auth-kit";
import { buildGetOptions, buildPostOptions, fetchWithErrorHandling } from "../FetchUtils";

export interface UsedCustomFieldTypeConfigOption {
  id: string;
  name?: string;
  label?: string;
  color: string;
  orderindex: number;
}

export interface IGeneratedArtIdData {
  AssetID: string;
  Root: string;
  Category: string;
  SubCategory: string;
  ModelVariant: string;
  TextureVariant: string;
  BundleID?: string;
  FriendlyName?: string;
  ClickupName?: string;
  Reference?: string;
  TaskType?: string;
  RootParent?: string;
  Tags?: string;
  Status?: string;
  Complexity?: string;
  NewRigg?: string;
  IsSkinned?: string;
  IsAnimated?: string;
  NeedsFX?: string;
  ClickupTask?: string;
  ClickupTaskID?: string;
  DepotPathPerforce?: string;
  DepotPathPerforceValue?: string;
  UnityAssetPath?: string;
  ReleaseVersion?: string;
  ReleaseDate?: string;
  LastBuildDate?: string;
  LastBuildStatus?: string;
}

const artToolUrl = process.env.REACT_APP_ART_TOOL_URL ?? "https://dev-art-tool-poc.tfk.area1.app";

export const getSubcategories = async (accessToken: string) => {
  return fetchWithErrorHandling(
    artToolUrl + "/v1/asset-tooling/art-id-generator/subcategories",
    buildGetOptions(accessToken),
  );
};

export const getUsedCustomFields = async (accessToken: string) => {
  return fetchWithErrorHandling(
    artToolUrl + "/v1/asset-tooling/art-id-generator/used-custom-fields",
    buildGetOptions(accessToken),
  );
};

export const generateIds = async (
  accessToken: string,
  subcategory: string,
  startModel: number,
  endModel: number,
  numberOfIds: number,
  complexity: string,
  taskType: string,
  customFieldSubcategory: string,
) => {
  const options = buildPostOptions(accessToken, {
    subcategory,
    numberOfIds,
    startModel,
    endModel,
    complexityId: complexity,
    taskTypeId: taskType,
    subcategoryId: customFieldSubcategory,
  });
  return fetchWithErrorHandling(artToolUrl + "/v1/asset-tooling/art-id-generator/generate-ids", options);
};

export const sendClickupPending = async (accessToken: string) => {
  const options = buildPostOptions(accessToken, {});
  return fetchWithErrorHandling(artToolUrl + "/v1/asset-tooling/art-id-generator/send-clickup-pending", options);
};

export const sendClickup = async (assetId: string, accessToken: string) => {
  const options = buildPostOptions(accessToken, {});
  return fetchWithErrorHandling(artToolUrl + `/v1/asset-tooling/art-id-generator/send-clickup/${assetId}`, options);
};

export const list = async (accessToken: string) => {
  return fetchWithErrorHandling(artToolUrl + "/v1/asset-tooling/art-id-generator/list", buildGetOptions(accessToken));
};

export const syncClickup = (assetId: string, accessToken: string) => {
  const options = buildPostOptions(accessToken, {});
  return fetchWithErrorHandling(artToolUrl + `/v1/asset-tooling/art-id-generator/sync-clickup/${assetId}`, options);
};

export const useAssetToolingService = () => {
  const authUser = useAuthUser();

  return {
    getSubcategories: () => getSubcategories(authUser()?.access_token),
    getUsedCustomFields: () => getUsedCustomFields(authUser()?.access_token),
    generateIds: (
      subcategory: string,
      startModel: number,
      endModel: number,
      numberOfIds: number,
      complexity: string,
      taskType: string,
      customFieldSubcategory: string,
    ) =>
      generateIds(
        authUser()?.access_token,
        subcategory,
        startModel,
        endModel,
        numberOfIds,
        complexity,
        taskType,
        customFieldSubcategory,
      ),
    list: () => list(authUser()?.access_token),
    sendClickupPending: () => sendClickupPending(authUser()?.access_token),
    sendClickup: (assetId: string) => sendClickup(assetId, authUser()?.access_token),
    syncClickup: (assetId: string) => syncClickup(assetId, authUser()?.access_token),
  };
};
