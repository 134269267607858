import React, { ReactNode } from "react";
import gradient from "assets/img/TF_gradient_BG.png";
import logo from "assets/img/TOWNSFOLK_logo.png";
import styled from "@emotion/styled";

const BackgroundGradient = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${gradient});
  background-repeat: no-repeat;
  background-size: cover;
`;

const TownsfolkLogo = styled.div`
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  min-width: 70vw;
  min-height: 70vh;
  max-width: 90vw;
  max-height: 90vh;
`;

export interface BackgroundProps {
  children?: ReactNode;
  opacity?: string;
  blur?: string;
  zIndex?: number;
}

export const Background = ({ children, opacity, blur, zIndex }: BackgroundProps) => {
  return (
    <BackgroundGradient style={{ filter: opacity ? `opacity(${opacity})` : undefined, zIndex }}>
      <TownsfolkLogo style={{ filter: blur ? `blur(${blur})` : undefined }} />
      <>{children}</>
    </BackgroundGradient>
  );
};
