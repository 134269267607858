import * as React from "react";
import { ChangeEvent, useRef, useState } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { validateStringField } from "../../utils/Validation";
import { IServerImage } from "../../services/registry/ServerImageService";
import { ServerImagePicker } from "../../components/ServerImagePicker";

export interface GameServerSpawnPopupProps {
  visible: boolean;
  onCancel: () => void;
  onAction: (containerName: string, serverImage: IServerImage) => Promise<void>;
}

export const GameServerSpawnPopup = ({ visible, onCancel, onAction }: GameServerSpawnPopupProps) => {
  const refContainerName = useRef<TextFieldProps>(null);
  const [containerNameError, setContainerNameError] = useState<string | undefined>(undefined);

  const validateContainerName = (value: string) => validateStringField(value, setContainerNameError, 3, 999);
  const containerNameChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    containerNameError && validateContainerName(event.target.value);

  const actionButtonClickHandler = (serverImage: IServerImage) => {
    if (!refContainerName.current) {
      return;
    }

    const containerName = validateContainerName(refContainerName.current.value as string);
    if (!containerName) {
      return;
    }

    onAction(containerName, serverImage);
  };

  return (
    <ServerImagePicker
      visible={visible}
      dialogTitle="Spawn Game Server"
      actionButtonLabel="Spawn"
      onCancel={onCancel}
      onAction={actionButtonClickHandler}
    >
      <TextField
        margin="dense"
        fullWidth
        variant="standard"
        inputRef={refContainerName}
        id="name"
        label="Name"
        type="text"
        error={containerNameError !== undefined}
        helperText={containerNameError}
        onChange={containerNameChangeHandler}
      />
    </ServerImagePicker>
  );
};
