import * as React from "react";
import { useState, useMemo, MouseEvent, ChangeEvent, Dispatch, SetStateAction } from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import Toolbar from "@mui/material/Toolbar";
import { Button, Grid, Stack, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import { getComparator, Order, stableSort } from "../../../utils/Table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IManagedParameter } from "../../../services/registry/ManagedParameterService";

interface HeadCell {
  id: keyof IManagedParameter | string;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
}

const headCells: readonly HeadCell[] = [
  {
    id: "key",
    label: "Key",
    padding: "none",
  },
  {
    id: "env",
    label: "Environment",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "value",
    label: "Value",
  },
  {
    id: "actions",
    label: "",
  },
];

interface ManagedParametersTableHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IManagedParameter | string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const ManagedParametersTableHead = ({ order, orderBy, onRequestSort }: ManagedParametersTableHeadProps) => {
  const createSortHandler = (property: keyof IManagedParameter | string) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export interface ManagedParametersTableProps {
  rows: IManagedParameter[];
  selectedItem: IManagedParameter | undefined;
  setSelectedItem: Dispatch<SetStateAction<IManagedParameter | undefined>>;
  onShowButtonClick: () => void;
  onAddButtonClick: () => void;
  onEditButtonClick: () => void;
  onRemoveButtonClick: () => void;
}

export const ManagedParametersTable = ({
  rows,
  selectedItem,
  setSelectedItem,
  onShowButtonClick,
  onAddButtonClick,
  onEditButtonClick,
  onRemoveButtonClick,
}: ManagedParametersTableProps) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof IManagedParameter | string>("creationDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [managedParameterFilter, setManagedParameterFilter] = useState<string>("");

  const requestSortHandler = (event: MouseEvent<unknown>, property: keyof IManagedParameter | string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = useMemo(() => {
    return rows.filter((row) => {
      return row.key.toLowerCase().includes(managedParameterFilter.toLowerCase());
    });
  }, [rows, managedParameterFilter]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    return stableSort(filteredRows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [filteredRows, order, orderBy, page, rowsPerPage]);

  const handleClick = (row: IManagedParameter, action: () => void) => {
    setSelectedItem(row);
    action();
  };

  const isSelected = (row: IManagedParameter, selectedItem: IManagedParameter | undefined) =>
    selectedItem !== undefined && row.name === selectedItem.name;

  const excerptFromString = (text: string): string => {
    if (!text) {
      return "N/A";
    }

    const maxLength = 40;
    if (text.length < maxLength + 1) {
      return text;
    }

    return `${text.slice(0, maxLength - 3).trim()}...`;
  };

  const hiddenFieldValue = "*****";

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, pt: { xs: 1, sm: 1 } }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button variant="outlined" startIcon={<AddIcon />} onClick={onAddButtonClick}>
                Add
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-search"
                label="Search managed parameters"
                type="search"
                onChange={(e) => setManagedParameterFilter(e.target.value)}
                sx={{ width: "360px" }}
              />
            </Grid>
          </Grid>
        </Toolbar>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="small">
            <ManagedParametersTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={requestSortHandler}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `managed-parameters-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    onClick={() => setSelectedItem(row)}
                    tabIndex={-1}
                    key={row.name}
                    selected={isSelected(row, selectedItem)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="normal" />
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row.key}
                    </TableCell>
                    <TableCell align="left">{row.env ?? "-"}</TableCell>
                    <TableCell align="left">{excerptFromString(row.description)}</TableCell>
                    <TableCell align="left">{row.type}</TableCell>
                    <TableCell align="left">
                      {row.type === "SecureString" ? hiddenFieldValue : excerptFromString(row.value)}
                    </TableCell>
                    <TableCell>
                      <Stack spacing={1} direction="row" justifyContent="flex-end">
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<VisibilityIcon />}
                          onClick={() => handleClick(row, onShowButtonClick)}
                        >
                          Show
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<EditIcon />}
                          onClick={() => handleClick(row, onEditButtonClick)}
                        >
                          Edit
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<DeleteIcon />}
                          color="error"
                          onClick={() => handleClick(row, onRemoveButtonClick)}
                        >
                          Remove
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={pageChangeHandler}
          onRowsPerPageChange={rowsPerPageChangeHandler}
        />
      </Paper>
    </Box>
  );
};
