import * as React from "react";
import { DateTime } from "luxon";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import { Button, Card, CardContent } from "@mui/material";
import { IPlayerMetadata, ReportStatus } from "./IPlayerMetadata";

const PlayerCardTitle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 44px;
`;

const PlayerCardLine = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 32px;
`;

export interface PlayerCardProps {
  metadata: IPlayerMetadata;
  onResetUserAgreement: () => Promise<void>;
  onBanUnban: () => Promise<void>;
  onLoadPlayerData: () => Promise<void>;
  onSetUnsetQa: () => Promise<void>;
}

export const PlayerCard = ({
  metadata,
  onResetUserAgreement,
  onBanUnban,
  onLoadPlayerData,
  onSetUnsetQa,
}: PlayerCardProps) => {
  const registrationDate = metadata.registrationDate
    ? DateTime.fromSeconds(metadata.registrationDate).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
    : undefined;
  const lastLoginDate = metadata.lastLoginDate
    ? DateTime.fromSeconds(metadata.lastLoginDate).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
    : undefined;
  return (
    <Card sx={{ maxWidth: 540 }}>
      <CardContent>
        <PlayerCardTitle>
          <Typography gutterBottom variant="h5" component="div">
            <b>{metadata.playerId}</b>
          </Typography>
        </PlayerCardTitle>
        <PlayerCardLine>
          <Typography variant="body2" color="text.secondary">
            Registration date:
          </Typography>
          <Typography variant="body2" color="text.secondary" textAlign="right" style={{ marginBottom: 2 }}>
            {registrationDate || "unknown"}
          </Typography>
        </PlayerCardLine>
        <PlayerCardLine style={{ marginTop: "-6px" }}>
          <Typography variant="body2" color="text.secondary">
            Last login date:
          </Typography>
          <Typography variant="body2" color="text.secondary" textAlign="right" style={{ marginBottom: 2 }}>
            {lastLoginDate || "unknown"}
          </Typography>
        </PlayerCardLine>
        <PlayerCardLine style={{ marginTop: "-6px" }}>
          <Typography variant="body2" color="text.secondary">
            Server ID:
          </Typography>
          <Typography variant="body2" color="text.secondary" textAlign="right" style={{ marginBottom: 2 }}>
            {metadata.serverId || "unknown"}
          </Typography>
        </PlayerCardLine>
        <PlayerCardLine>
          <Typography variant="body1" color="text.secondary">
            Accepted User Agreement: <b>{metadata.hasAcceptedUserAgreement ? "true" : "false"}</b>
          </Typography>
          <Button size="small" onClick={onResetUserAgreement}>
            Reset
          </Button>
        </PlayerCardLine>
        <PlayerCardLine>
          <Typography variant="body1" color="text.secondary">
            Report Status: <b>{metadata.reportStatus}</b>
          </Typography>
          <Button size="small" onClick={onBanUnban}>
            {metadata.reportStatus === ReportStatus.Banned ? "Unban" : "Ban"}
          </Button>
        </PlayerCardLine>
        <PlayerCardLine>
          <Typography variant="body1" color="text.secondary">
            Is QA user: <b>{metadata.isQaUser ? "true" : "false"}</b>
          </Typography>
          <Button size="small" onClick={onSetUnsetQa}>
            {metadata.isQaUser ? "Unset" : "Set"}
          </Button>
        </PlayerCardLine>
        <PlayerCardLine>
          <Button size="small" onClick={onLoadPlayerData}>
            Load Player Data
          </Button>
        </PlayerCardLine>
      </CardContent>
    </Card>
  );
};
