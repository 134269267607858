import * as React from "react";
import { ChangeEvent, MouseEvent, useMemo, useState } from "react";
import { DateTime } from "luxon";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import Toolbar from "@mui/material/Toolbar";
import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import { getComparator, Order, stableSort } from "../../utils/Table";
import { IGameServerAction, GameServerActionState } from "../../services/backend/GameServerService";
import Checkbox from "@mui/material/Checkbox";
import RefreshIcon from "@mui/icons-material/Refresh";
import SendIcon from "@mui/icons-material/Send";

interface HeadCell {
  id: keyof IGameServerAction;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    label: "ID",
    padding: "none",
  },
  {
    id: "action",
    label: "Action",
  },
  {
    id: "playerIds",
    label: "Player IDs",
  },
  {
    id: "statesByServerId",
    label: "States by Server",
  },
  {
    id: "extraArgs",
    label: "Extra Args",
  },
  {
    id: "initTimeMs",
    label: "Init Time",
    align: "right",
  },
  {
    id: "updateTimeMs",
    label: "Update Time",
    align: "right",
  },
];

interface GameServerActionsTableHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IGameServerAction) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const GameServerActionsTableHead = ({ order, orderBy, onRequestSort }: GameServerActionsTableHeadProps) => {
  const createSortHandler = (property: keyof IGameServerAction) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="normal" />
      </TableRow>
    </TableHead>
  );
};

interface GameServerActionsTableToolbarProps {
  onSendButtonClick: () => void;
  onRefreshButtonClick: () => void;
}

const GameServerActionsTableToolbar = ({ onSendButtonClick, onRefreshButtonClick }: GameServerActionsTableToolbarProps) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Button variant="outlined" startIcon={<SendIcon />} onClick={onSendButtonClick}>
        Send
      </Button>
      <Button variant="outlined" startIcon={<RefreshIcon />} onClick={onRefreshButtonClick} sx={{ marginLeft: 1 }}>
        Refresh
      </Button>
    </Toolbar>
  );
};

export interface GameServerActionsTableProps {
  rows: IGameServerAction[];
  onSendButtonClick: () => void;
  onRefreshButtonClick: () => void;
  onViewStatesByServerButtonClick: (actionData: IGameServerAction) => void;
}

export const GameServerActionsTable = ({
  rows,
  onSendButtonClick,
  onRefreshButtonClick,
  onViewStatesByServerButtonClick,
}: GameServerActionsTableProps) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IGameServerAction>("initTimeMs");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [includeCompleted, setIncludeCompleted] = useState(false);

  const requestSortHandler = (event: MouseEvent<unknown>, property: keyof IGameServerAction) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredRows = useMemo(() => {
    if (includeCompleted) {
      return rows;
    } else {
      return rows.filter(
        (t) =>
          Object.values(t.statesByServerId).findIndex(
            (s) => s !== GameServerActionState.SUCCEEDED && s !== GameServerActionState.FAILED,
          ) > -1,
      );
    }
  }, [rows, includeCompleted]);

  const visibleRows = useMemo(() => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    return stableSort(filteredRows as any, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [filteredRows, order, orderBy, page, rowsPerPage]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <GameServerActionsTableToolbar onSendButtonClick={onSendButtonClick} onRefreshButtonClick={onRefreshButtonClick} />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="small">
            <GameServerActionsTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={requestSortHandler}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `game-server-actions-checkbox-${index}`;
                const initTime = DateTime.fromMillis(row.initTimeMs).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
                const updateTime = DateTime.fromMillis(row.updateTimeMs).toLocaleString(
                  DateTime.DATETIME_SHORT_WITH_SECONDS,
                );
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id} sx={{ cursor: "pointer" }}>
                    <TableCell padding="normal" />
                    <TableCell component="th" id={labelId} scope="row" padding="none" style={{ fontFamily: "monospace" }}>
                      {row.id}
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      {row.action}
                    </TableCell>
                    <TableCell component="th" style={{ fontFamily: "monospace" }} scope="row" padding="none">
                      {(row.playerIds || []).join(",")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button onClick={() => onViewStatesByServerButtonClick(row)}>Show</Button>
                    </TableCell>
                    <TableCell component="th" style={{ fontFamily: "monospace" }} scope="row" padding="none">
                      {(row.extraArgs || []).join(",")}
                    </TableCell>
                    <TableCell align="right">{initTime}</TableCell>
                    <TableCell align="right">{updateTime}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center" }}>
            <Checkbox color="primary" checked={includeCompleted} onChange={() => setIncludeCompleted(!includeCompleted)} />
            <Typography variant="body2">Show completed actions</Typography>
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={pageChangeHandler}
            onRowsPerPageChange={rowsPerPageChangeHandler}
          />
        </div>
      </Paper>
    </Box>
  );
};
