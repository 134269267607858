import Routes from "../../components/routing/Routes";
import { fetchWithErrorHandling } from "../FetchUtils";

const cognitoBaseUrl = process.env.REACT_APP_AWS_COGNITO_URL || "";
const cognitoClientId = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID || "";
const redirectUri = window.location.origin + Routes.loginCallback;

const commonOptions = {
  mode: "cors",
  cache: "no-cache",
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json",
  },
  redirect: "follow",
  referrerPolicy: "no-referrer",
} as RequestInit;

export interface ICognitoTokens {
  id_token: string;
  access_token: string;
  refresh_token?: string;
  expires_in: number;
  token_type: string;
}

/**
 * Returns the AWS Cognito login URL.
 *
 * This URL will redirect the user to the Okta login page and after success
 * will send the user to the /login/callback to get the tokens.
 *
 * Docs can be found here:
 * https://docs.aws.amazon.com/cognito/latest/developerguide/authorization-endpoint.html
 */
export const getAuthorizationURL = async (state: string, codeChallenge: string): Promise<string> => {
  const endpoint = new URL("/oauth2/authorize", cognitoBaseUrl);

  const params = new URLSearchParams([
    ["client_id", cognitoClientId],
    ["response_type", "code"],
    ["state", state],
    ["code_challenge_method", "S256"],
    ["code_challenge", codeChallenge],
    ["redirect_uri", redirectUri],
  ]);

  // url encoding the scopes (email+openid+profile) returns an error
  // to circumvent this the scope parameter is being added manually
  return `${endpoint}?${params.toString()}&scope=email+openid+profile`;
};

/**
 * Returns the tokens based on the authorization code from login.
 *
 * Docs can be found here:
 * https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
 *
 * @param authorizationCode
 * @param codeVerifier
 */
export const getTokens = async (authorizationCode: string, codeVerifier: string): Promise<ICognitoTokens> => {
  const endpoint = new URL("/oauth2/token", cognitoBaseUrl);

  const payload = new URLSearchParams({
    grant_type: "authorization_code",
    client_id: cognitoClientId,
    redirect_uri: redirectUri,
    code: authorizationCode,
    code_verifier: codeVerifier,
  });

  const config: RequestInit = {
    ...commonOptions,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: payload,
  };

  console.log("getTokens", { config });

  return await fetchWithErrorHandling(endpoint, config);
};

/**
 * Returns refreshed token based on refresh token.
 *
 * Docs can be found here:
 * https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
 *
 * @param refreshToken
 */
export const getRefreshedTokens = async (refreshToken: string): Promise<ICognitoTokens> => {
  const endpoint = new URL("/oauth2/token", cognitoBaseUrl);

  const payload = new URLSearchParams({
    grant_type: "refresh_token",
    client_id: cognitoClientId,
    refresh_token: refreshToken,
  });

  const config: RequestInit = {
    ...commonOptions,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: payload,
  };

  console.log("getRefreshedTokens", { config });

  return await fetchWithErrorHandling(endpoint, config);
};

export interface ICognitoUserInfo {
  sub?: string;
  identities?: string;
  email_verified?: string;
  email?: string;
  username?: string;
}

/**
 * Get the user information with the access token.
 *
 * Docs can be found here: https://docs.aws.amazon.com/cognito/latest/developerguide/userinfo-endpoint.html
 *
 * @param accessToken
 */
export const getUserInfo = async (accessToken: string): Promise<ICognitoUserInfo> => {
  const endpoint = new URL("/oauth2/userInfo", cognitoBaseUrl);
  const config: RequestInit = {
    ...commonOptions,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchWithErrorHandling(endpoint, config);
};

/**
 * Revoke all tokens associated with a refresh token.
 *
 * Docs can be found here:
 * https://docs.aws.amazon.com/cognito/latest/developerguide/revocation-endpoint.html
 *
 * @param refreshToken
 */
export const revoke = async (refreshToken: string): Promise<void> => {
  const endpoint = new URL("/oauth2/revoke", cognitoBaseUrl);
  const payload = new URLSearchParams({
    token: refreshToken,
    client_id: cognitoClientId,
  });

  const config: RequestInit = {
    ...commonOptions,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: payload,
  };

  // this endpoint has an empty response which was causing the error:
  // Unexpected end of JSON input
  await fetch(endpoint, config);
};
