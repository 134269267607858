import * as React from "react";
import { useState, useMemo, MouseEvent, ChangeEvent, Dispatch, SetStateAction } from "react";
import { DateTime } from "luxon";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import Toolbar from "@mui/material/Toolbar";
import { Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import { getComparator, Order, stableSort } from "../../../utils/Table";
import { IServerImage } from "../../../services/registry/ServerImageService";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";

interface HeadCell {
  id: keyof IServerImage | string;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    label: "ID",
    padding: "none",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "branch",
    label: "Branch",
  },
  {
    id: "version",
    label: "Version",
  },
  {
    id: "environment",
    label: "Environment",
  },
  {
    id: "creationDate",
    label: "Creation Date",
  },
  {
    id: "actions",
    label: "",
  },
];

interface ServerImagesTableHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IServerImage | string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const ServerImagesTableHead = ({ order, orderBy, onRequestSort }: ServerImagesTableHeadProps) => {
  const createSortHandler = (property: keyof IServerImage | string) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface ServerImagesTableToolbarProps {
  onAddButtonClick: () => void;
}

const ServerImagesTableToolbar = ({ onAddButtonClick }: ServerImagesTableToolbarProps) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Button variant="outlined" startIcon={<AddIcon />} onClick={onAddButtonClick}>
        Add
      </Button>
    </Toolbar>
  );
};

export interface ServerImagesTableProps {
  rows: IServerImage[];
  selectedItem: IServerImage | undefined;
  setSelectedItem: Dispatch<SetStateAction<IServerImage | undefined>>;
  onAddButtonClick: () => void;
  onEditButtonClick: () => void;
  onRemoveButtonClick: () => void;
  onPromoteButtonClick: () => void;
}

export const ServerImagesTable = ({
  rows,
  selectedItem,
  setSelectedItem,
  onAddButtonClick,
  onEditButtonClick,
  onRemoveButtonClick,
  onPromoteButtonClick,
}: ServerImagesTableProps) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof IServerImage | string>("creationDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const requestSortHandler = (event: MouseEvent<unknown>, property: keyof IServerImage | string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [rows, order, orderBy, page, rowsPerPage]);

  const handleClick = (row: IServerImage, action: () => void) => {
    setSelectedItem(row);
    action();
  };

  const isSelected = (row: IServerImage, selectedItem: IServerImage | undefined) =>
    selectedItem !== undefined && row.id === selectedItem.id;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <ServerImagesTableToolbar onAddButtonClick={onAddButtonClick} />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="small">
            <ServerImagesTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={requestSortHandler}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `server-images-table-checkbox-${index}`;
                const creationDate = DateTime.fromSeconds(row.creationDate).toLocaleString(
                  DateTime.DATETIME_SHORT_WITH_SECONDS,
                );
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    onClick={() => setSelectedItem(row)}
                    tabIndex={-1}
                    key={row.id}
                    selected={isSelected(row, selectedItem)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="normal" />
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row.id}
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.branch}</TableCell>
                    <TableCell align="left">{row.version}</TableCell>
                    <TableCell align="left">{row.environment ? row.environment : "Unpromoted"}</TableCell>
                    <TableCell align="left">{creationDate}</TableCell>
                    <TableCell>
                      <Stack spacing={1} direction="row" justifyContent="flex-end">
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<EditIcon />}
                          onClick={() => handleClick(row, onEditButtonClick)}
                          disabled={row.environment !== undefined}
                        >
                          Edit
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<DeleteIcon />}
                          color="error"
                          onClick={() => handleClick(row, onRemoveButtonClick)}
                          disabled={row.environment !== undefined}
                        >
                          Remove
                        </Button>
                        <Button
                          aria-label="promote"
                          size="small"
                          variant="outlined"
                          startIcon={<PublishIcon />}
                          onClick={() => handleClick(row, onPromoteButtonClick)}
                          disabled={row.environment !== undefined}
                        >
                          Promote
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={pageChangeHandler}
          onRowsPerPageChange={rowsPerPageChangeHandler}
        />
      </Paper>
    </Box>
  );
};
