/* eslint-disable  @typescript-eslint/no-explicit-any */
import { IIntegrationTestLogsIndexEntry, useIntegrationTestService } from "../../services/backend/IntegrationTestService";
import { useEffect, useState } from "react";
import { IntegrationTestsTable } from "./IntegrationTestsTable";
import { AlertColor } from "@mui/material";
import { Notify } from "../../components/Notify";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import * as React from "react";
import { downloadFile } from "../../utils/Download";
import { IntegrationTestsLogDataViewer } from "./IntegrationTestsLogDataViewer";
import { AppContext } from "../../utils/AppContext";

const IntegrationTests = () => {
  const integrationTestService = useIntegrationTestService();

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [integrationTestsLogsList, setIntegrationTestsLogsList] = useState<IIntegrationTestLogsIndexEntry[]>([]);
  const [selectedItem, setSelectedItem] = useState<IIntegrationTestLogsIndexEntry | undefined>(undefined);
  const [logs, setLogs] = useState<string | undefined>(undefined);
  const [logViewerVisible, setLogViewerVisible] = useState<boolean>(false);
  const [data, setData] = useState<string | undefined>(undefined);
  const [dataViewerVisible, setDataViewerVisible] = useState<boolean>(false);

  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Integration Tests");
    context?.setTargetAPI("registry");
  }, [context]);

  useEffect(() => {
    (async () => fetchIntegrationTestsList())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchIntegrationTestsList = async () => {
    try {
      setLoading(true);
      const { list } = await integrationTestService.list();
      setIntegrationTestsLogsList(list);
      setLoading(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to list Managed Parameters.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const onShowLogsButtonClickHandler = async (row: IIntegrationTestLogsIndexEntry) => {
    if (!selectedItem) {
      return;
    }

    try {
      setLoading(true);
      const logs = await integrationTestService.logs(row.testName);
      setLogs(logs.logs);
      setLogViewerVisible(true);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to get integration test logs.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const onDownloadLogsButtonClickHandler = async (row: IIntegrationTestLogsIndexEntry) => {
    if (!selectedItem) {
      return;
    }

    try {
      setLoading(true);
      const logs = await integrationTestService.logs(row.testName);

      const bytes = new TextEncoder().encode(logs.logs);
      const blob = new Blob([bytes], {
        type: "application/json;charset=utf-8",
      });
      const timestamp = new Date(row.creationDate * 1000);
      downloadFile(blob, `${row.testName}-logs-${timestamp.toISOString()}.logs`);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to download integration test logs.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const onShowDataButtonClickHandler = async (row: IIntegrationTestLogsIndexEntry) => {
    if (!selectedItem) {
      return;
    }

    try {
      setLoading(true);
      const data = await integrationTestService.data(row.testName);
      setData(JSON.stringify(data.data, null, 2));
      setDataViewerVisible(true);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to get integration test data.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const onDownloadDataButtonClickHandler = async (row: IIntegrationTestLogsIndexEntry) => {
    if (!selectedItem) {
      return;
    }

    try {
      setLoading(true);
      const data = await integrationTestService.data(row.testName);

      const content = JSON.stringify(data);
      const bytes = new TextEncoder().encode(content);
      const blob = new Blob([bytes], {
        type: "application/json;charset=utf-8",
      });
      const timestamp = new Date(row.creationDate * 1000);
      downloadFile(blob, `${row.testName}-data-dump-${timestamp.toISOString()}.json`);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to download integration test data dump.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <IntegrationTestsTable
        rows={integrationTestsLogsList}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        onShowLogsButtonClickHandler={onShowLogsButtonClickHandler}
        onDownloadLogsButtonClickHandler={onDownloadLogsButtonClickHandler}
        onShowDataButtonClickHandler={onShowDataButtonClickHandler}
        onDownloadDataButtonClickHandler={onDownloadDataButtonClickHandler}
      />
      <IntegrationTestsLogDataViewer
        indexLogEntry={selectedItem}
        title={`Logs for for ${selectedItem?.testName}` || ""}
        logsOrData={logs || ""}
        isData={false}
        visible={logViewerVisible}
        onDownloadButtonClickHandler={onDownloadLogsButtonClickHandler}
        onClose={() => setLogViewerVisible(false)}
      ></IntegrationTestsLogDataViewer>
      <IntegrationTestsLogDataViewer
        indexLogEntry={selectedItem}
        title={`Data dump for ${selectedItem?.testName}` || ""}
        logsOrData={data || ""}
        isData={true}
        visible={dataViewerVisible}
        onDownloadButtonClickHandler={onDownloadDataButtonClickHandler}
        onClose={() => setDataViewerVisible(false)}
      ></IntegrationTestsLogDataViewer>
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
      {loading && <LoadingOverlay />}
    </>
  );
};

export default IntegrationTests;
