export const isRunningLocally = () => {
  const topDomain = new URL(document.location.href).hostname.split(".").pop();
  switch (topDomain) {
    case "localhost":
      return true;
    default:
      return false;
  }
};

export const isRunningOnProd = () => {
  return (
    // TODO: remove the old validation when rollout to new prod env
    document.location.href.includes("a707f364") || document.location.href.includes("admin.beetle.prod.beetleboatgames.com")
  );
};
