const ArtAssetsIdsRawSheet = () => {
  return (
    <div>
      <iframe
        src="https://docs.google.com/spreadsheets/d/1lTvSrd31W25hen6MTk_OoCqQrCz4JfzYGTP8UkmVoXM/edit?gid=389466922"
        width="99%"
        height="720"
      ></iframe>
    </div>
  );
};

export default ArtAssetsIdsRawSheet;
