import * as React from "react";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GameServerAction, IGameServerAction } from "services/backend/GameServerService";
import { isValidPlayFabId } from "../../utils/Validation";

export interface IGameServerActionFormData {
  action: GameServerAction;
  serverIds: string[] | undefined;
  playerIds: string[] | undefined;
  extraArgs: string[] | undefined;
}

export interface GameServerActionSendFormProps {
  visible: boolean;
  initialData?: IGameServerAction;
  onDismiss: () => void;
  onAccept: (data: IGameServerActionFormData) => void;
}

const GameServerActionFormField = (props: Omit<TextFieldProps, "variant">) => {
  return <TextField autoFocus margin="dense" fullWidth variant="standard" {...props} />;
};

export const GameServerActionSendForm = ({ visible, initialData, onDismiss, onAccept }: GameServerActionSendFormProps) => {
  const refServerIds = useRef<TextFieldProps>(null);
  const refPlayerIds = useRef<TextFieldProps>(null);
  const refExtraArgs = useRef<TextFieldProps>(null);

  const [selectedAction, setSelectedAction] = useState<GameServerAction>(GameServerAction.BAN_ACCOUNT);
  const [serverIdsError, setServerIdsError] = useState<string | undefined>(undefined);
  const [playerIdsError, setPlayerIdsError] = useState<string | undefined>(undefined);

  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  const validateOptionalArray = (value: string | undefined) => {
    if (value) {
      return value.split(",");
    }
    return undefined;
  };

  const validateOptionalPlayFabIdsArray = (
    value: string | undefined,
    setError: Dispatch<SetStateAction<string | undefined>>,
  ) => {
    if (value) {
      const list = value.split(",").map((t) => t.trim());
      for (const item of list) {
        if (!isValidPlayFabId(item)) {
          setError(`"${item}" is not a valid PlayFab ID.`);
          throw new Error();
        }
      }
    }
    setError(undefined);
    return undefined;
  };

  const okButtonClickHandler = () => {
    if (!refServerIds.current || !refPlayerIds.current || !refExtraArgs.current) {
      return;
    }

    try {
      const serverIds = validateOptionalPlayFabIdsArray(refServerIds.current.value as string, setServerIdsError);
      const playerIds = validateOptionalPlayFabIdsArray(refPlayerIds.current.value as string, setPlayerIdsError);
      const extraArgs = validateOptionalArray(refExtraArgs.current.value as string);

      onAccept({
        action: selectedAction,
        serverIds,
        playerIds,
        extraArgs,
      });
    } catch (e) {
      // ignore
    }
  };

  return (
    <Dialog open={visible} onClose={dialogCloseHandler}>
      <DialogTitle>{"New Game-Server Action"}</DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill up the information below:</DialogContentText>
        <FormControl sx={{ mt: 1, width: "240px", marginTop: 3 }}>
          <InputLabel id="action-selector-label">Action</InputLabel>
          <Select
            labelId="action-selector-label"
            id="action-selector"
            value={selectedAction}
            onChange={(e) => setSelectedAction(e.target.value as GameServerAction)}
            label="Action"
          >
            {Object.values(GameServerAction).map((t) => (
              <MenuItem key={t} value={t}>
                {t}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <GameServerActionFormField
          inputRef={refServerIds}
          id="serverIds"
          label="Server IDs"
          type="text"
          defaultValue={Object.keys(initialData?.statesByServerId || {}).join(",")}
          error={serverIdsError !== undefined}
          helperText={serverIdsError || "Optional server IDs to target (comma separated)"}
        />
        <GameServerActionFormField
          inputRef={refPlayerIds}
          id="playerIds"
          label="Player IDs"
          type="text"
          defaultValue={initialData?.playerIds?.join(",") || ""}
          error={playerIdsError !== undefined}
          helperText={playerIdsError || "Optional player IDs to target (comma separated)"}
        />
        <GameServerActionFormField
          inputRef={refExtraArgs}
          id="extraArgs"
          label="Extra Arguments"
          type="text"
          defaultValue={initialData?.extraArgs?.join(",") || ""}
          helperText={"Extra Arguments (comma separated)"}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss}>Cancel</Button>
        <Button onClick={okButtonClickHandler}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};
