import { fetchWithErrorHandling, buildGetOptions } from "../FetchUtils";
import { useAuthUser } from "react-auth-kit";
import { useContext } from "react";
import { AppContext } from "utils/AppContext";

export const list = async (accessToken: string, baseURL: string) => {
  return fetchWithErrorHandling(baseURL + "v1/logging/list", buildGetOptions(accessToken));
};

export const load = async (accessToken: string, baseURL: string, logName: string) => {
  return fetchWithErrorHandling(baseURL + `v1/logging/load/${logName}`, buildGetOptions(accessToken), false, true);
};

export const loadFrom = async (accessToken: string, baseURL: string, logName: string, timestamp: number) => {
  return fetchWithErrorHandling(
    baseURL + `v1/logging/load/${logName}/from/${timestamp}`,
    buildGetOptions(accessToken),
    false,
    true,
  );
};

export const listEnvironmentStreams = async (accessToken: string, baseURL: string, environmentName: string) => {
  return fetchWithErrorHandling(baseURL + `v1/logging/environment/${environmentName}`, buildGetOptions(accessToken));
};
export const listEnvironmentEventLogs = async (
  accessToken: string,
  baseURL: string,
  environmentName: string,
  streamName: string,
) => {
  return fetchWithErrorHandling(
    baseURL + `v1/logging/environment/${environmentName}/${streamName}`,
    buildGetOptions(accessToken),
  );
};

export const useLoggingService = () => {
  const authUser = useAuthUser();
  const accessToken = authUser()?.access_token;
  const appContext = useContext(AppContext);

  const getBaseURL = () => appContext?.selectedEnvironment?.baseURL || "http://localhost:3001/";

  return {
    list: () => list(accessToken, getBaseURL()),
    load: (logName: string) => load(accessToken, getBaseURL(), logName),
    loadFrom: (logName: string, timestamp: number) => loadFrom(accessToken, getBaseURL(), logName, timestamp),
    listEnvironmentStreams: (environmentName: string) => listEnvironmentStreams(accessToken, getBaseURL(), environmentName),
    listEnvironmentEventLogs: (environmentName: string, streamName: string) =>
      listEnvironmentEventLogs(accessToken, getBaseURL(), environmentName, streamName),
  };
};
