import React, { useRef, useState } from "react";
import { Button, Dialog, DialogContent, DialogProps, DialogTitle, TextField } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { TextFieldProps } from "@mui/material/TextField";
import { IDataEntity } from "../../services/backend/DataSchemaService";

export interface DataImportDialogProps {
  visible: boolean;
  title: string;
  onAccept: (entities: IDataEntity[]) => Promise<void>;
  onDismiss: () => void;
}

export const DataImportDialog = ({ visible, title, onAccept, onDismiss }: DataImportDialogProps) => {
  const [validationError, setValidationError] = useState("");

  const maxWidth: DialogProps["maxWidth"] = "md";
  const refEntities = useRef<TextFieldProps>(null);

  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  const importFromInput = async () => {
    if (!refEntities.current) {
      return;
    }

    try {
      const data = JSON.parse(refEntities.current.value as string);

      if (!(data instanceof Array)) {
        setValidationError("Data is not an array");
        return;
      }

      await onAccept(data);
    } catch (e) {
      setValidationError("Invalid array of data-entities");
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth={maxWidth} open={visible} onClose={dialogCloseHandler}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <p>Import data from a JSON Array of strings format.</p>
        <TextField
          helperText={validationError}
          color={validationError ? "error" : "primary"}
          onChange={() => setValidationError("")}
          inputRef={refEntities}
          id="import-data-textarea"
          label="Data Entities"
          placeholder="Entities"
          multiline
          rows={10}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={importFromInput} autoFocus>
          Import
        </Button>
        <Button variant="contained" color="error" onClick={onDismiss} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
