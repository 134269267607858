import React, { CSSProperties, useMemo } from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { LoadTesterState } from "../../services/backend/LoadTesterService";
import { LedIndicator, LedIndicatorColor } from "../../components/LedIndicator";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export interface LoadTesterStateIndicatorProps {
  state: LoadTesterState;
  style?: CSSProperties;
}

export const LoadTesterStateIndicator = ({ state, style }: LoadTesterStateIndicatorProps) => {
  const [label, color] = useMemo(() => {
    switch (state) {
      case LoadTesterState.PENDING_INITIALIZATION:
        return ["Pending initialization...", LedIndicatorColor.BLUE];
      case LoadTesterState.PENDING_TERMINATION:
        return ["Pending termination...", LedIndicatorColor.BLUE];
      case LoadTesterState.TERMINATED:
        return ["Terminated", LedIndicatorColor.GRAY];
      case LoadTesterState.RUNNING:
        return ["Running", LedIndicatorColor.GREEN];
      case LoadTesterState.UNKNOWN:
      default:
        return ["Unknown", LedIndicatorColor.YELLOW];
    }
  }, [state]);
  return (
    <Container style={style}>
      <Typography variant="inherit" style={{ marginRight: 8 }}>
        {label}
      </Typography>
      <LedIndicator color={color} scale={0.2} />
    </Container>
  );
};
