import * as React from "react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { formatError, validateSelectField, validateStringField } from "../../../utils/Validation";
import { CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import { IManagedParameter } from "../../../services/registry/ManagedParameterService";
import IconButton from "@mui/material/IconButton";
import { HelpOutline } from "@mui/icons-material";
import { EnvironmentSelector } from "../../../components/EnvironmentSelector";
import { IEnvironment } from "../../../services/registry/EnvironmentService";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

export type ManagedParameterFormType = "new" | "edit";

export interface ManagedParameterFormData {
  key: string;
  env: string | undefined;
  description: string;
  value: string;
  type: string;
}

export interface ManagedParameterFormProps {
  visible: boolean;
  type: ManagedParameterFormType;
  initialData?: IManagedParameter;
  onDismiss: () => void;
  onAccept: (data: ManagedParameterFormData) => Promise<void>;
}

export const ManagedParameterFormField = (props: Omit<TextFieldProps, "variant">) => {
  return <TextField autoFocus margin="dense" fullWidth variant="standard" {...props} />;
};

export const ManagedParameterForm = ({ visible, type, initialData, onDismiss, onAccept }: ManagedParameterFormProps) => {
  const [loading, setLoading] = useState(false);
  const [useEnvironment, setUseEnvironment] = useState(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState<IEnvironment | undefined>(undefined);

  useEffect(() => {
    if (initialData?.env) {
      setUseEnvironment(true);
      setSelectedEnvironment({ name: initialData.env } as IEnvironment);
    } else {
      setUseEnvironment(false);
      setSelectedEnvironment(undefined);
    }
  }, [initialData]);

  const refKey = useRef<TextFieldProps>(null);
  const refDescription = useRef<TextFieldProps>(null);
  const refValue = useRef<TextFieldProps>(null);
  const refType = useRef<TextFieldProps>(null);

  const [keyError, setKeyError] = useState<string | undefined>(undefined);
  const [envError, setEnvError] = useState<string | undefined>(undefined);
  const [descriptionError, setDescriptionError] = useState<string | undefined>(undefined);
  const [valueError, setValueError] = useState<string | undefined>(undefined);
  const [typeError, setTypeError] = useState<string | undefined>(undefined);

  const parameterTypes = ["String", "SecureString", "StringList"];
  const parameterTypeToolTips = [
    "String: Any string value",
    "StringList: Separate strings using commas",
    "SecureString: Encrypt sensitive data using KMS keys",
  ];

  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  const validateKey = (value: string | undefined) => {
    value = validateStringField(value, setKeyError, 3, 100);
    if (value) {
      const allowedChars = /^[\da-zA-Z_-]+$/;
      if (!allowedChars.test(value)) {
        formatError("Can only have alpha-numeric characters, underscores, and dashes.", setKeyError, "key");
        return undefined;
      }
    }
    return value;
  };
  const validateDescription = (value: string) => validateStringField(value, setDescriptionError, 1, 100);
  const validateValue = (value: string) => validateStringField(value, setValueError, 1, 999);
  const validateType = (value: string) => validateSelectField(value, setTypeError, parameterTypes);

  const keyChangeHandler = (event: ChangeEvent<HTMLInputElement>) => keyError && validateKey(event.target.value);
  const descriptionChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    descriptionError && validateDescription(event.target.value);
  const valueChangeHandler = (event: ChangeEvent<HTMLInputElement>) => valueError && validateValue(event.target.value);
  const typeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => typeError && validateType(event.target.value);

  const okButtonClickHandler = async () => {
    if (!refKey.current || !refDescription.current || !refValue.current || !refType.current) {
      return;
    }

    const key = validateKey(refKey.current.value as string);
    if (useEnvironment && !selectedEnvironment) {
      setEnvError("Must be selected.");
    }
    const description = validateDescription(refDescription.current.value as string);
    const value = validateValue(refValue.current.value as string);
    const type = validateType(refType.current.value as string);
    if (!key || !selectedEnvironment || !description || !value || !type) {
      return;
    }

    setLoading(true);
    await onAccept({ key, env: useEnvironment ? selectedEnvironment.name : undefined, description, value, type });
    setLoading(false);
  };

  return (
    <Dialog open={visible} onClose={dialogCloseHandler}>
      <DialogTitle>{(type === "new" ? "New" : "Edit") + " Managed Parameter"}</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <DialogContentText>Please fill up the information below:</DialogContentText>
            <ManagedParameterFormField
              inputRef={refKey}
              id="key"
              label="Key"
              type="text"
              defaultValue={initialData?.key}
              error={keyError !== undefined}
              helperText={keyError}
              onChange={keyChangeHandler}
              disabled={type !== "new"}
            />
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                marginTop: 12,
                marginLeft: -12,
              }}
            >
              <Checkbox
                color="primary"
                checked={useEnvironment}
                onChange={() => setUseEnvironment(!useEnvironment)}
                disabled={type !== "new"}
              />
              <Typography variant="body2">Environment specific</Typography>
              <div style={{ display: "flex", flexGrow: 1 }} />
              <EnvironmentSelector
                id="managed-parameter"
                selectedEnvironment={selectedEnvironment}
                onEnvironmentSelected={setSelectedEnvironment}
                errorMessage={envError}
                disabled={!useEnvironment || type !== "new"}
                useStorage={false}
              />
            </div>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="type-label">
                Type
                <Tooltip title={parameterTypeToolTips.join("; ")} placement="right">
                  <IconButton aria-label="managed-parameter-type-tooltip" size="small">
                    <HelpOutline fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </InputLabel>
              <Select
                labelId="type-label"
                id="type-selector"
                defaultValue={initialData?.type}
                inputRef={refType}
                label="Type"
                error={typeError !== undefined}
                onChange={() => typeChangeHandler}
              >
                {parameterTypes.map((parameterType) => (
                  <MenuItem key={parameterType} value={parameterType}>
                    {parameterType}
                  </MenuItem>
                ))}
              </Select>
              {typeError && <FormHelperText error={true}>{typeError}</FormHelperText>}
            </FormControl>
            <ManagedParameterFormField
              inputRef={refDescription}
              id="description"
              label="Description"
              type="text"
              defaultValue={initialData?.description}
              error={descriptionError !== undefined}
              helperText={descriptionError}
              onChange={descriptionChangeHandler}
            />
            <ManagedParameterFormField
              inputRef={refValue}
              id="value"
              label="Value"
              type="text"
              multiline
              defaultValue={initialData?.value}
              error={valueError !== undefined}
              helperText={valueError}
              onChange={valueChangeHandler}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss} color="error" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={okButtonClickHandler} disabled={loading}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
