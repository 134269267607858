import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

export interface SimpleDialogProps {
  title: string;
  listItems: string[] | undefined;
  emptyListMessage: string;
  open: boolean;
  onClose: () => void;
}

export default function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open, listItems } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{props.title}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {listItems && listItems.length ? (
          listItems?.map((it) => (
            <ListItem disableGutters key={it}>
              <ListItemButton>
                <ListItemText primary={it} />
              </ListItemButton>
            </ListItem>
          ))
        ) : (
          <ListItem disableGutters>
            <ListItemButton>
              <ListItemText primary={props.emptyListMessage} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Dialog>
  );
}
