import { ChangeEvent, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  styled,
  Autocomplete,
  IconButton,
} from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import ChipsArray from "./ChipsArray";

export interface FileUploadData {
  file: File | undefined;
  labels: string[] | undefined;
}

export interface FileUploadConf {
  showExistingFiles: boolean;
  existingFiles: string[];
  showSetLabels: boolean;
  initialLabels: string[];
}

export interface FileUploadProps {
  visible: boolean;
  title: string;
  filters?: string;
  fileUploadConf: FileUploadConf;
  onDismiss: () => void;
  onAccept: (data: FileUploadData) => Promise<void>;
}

export const FileUpload = ({ visible, title, filters, fileUploadConf, onDismiss, onAccept }: FileUploadProps) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<string[]>(fileUploadConf.existingFiles);
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [labels, setLabels] = useState<string[] | undefined>(
    fileUploadConf.initialLabels ? [...fileUploadConf.initialLabels] : [],
  );
  const refNewLabel = useRef<TextFieldProps>(null);

  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  const okButtonClickHandler = async () => {
    setLoading(true);
    await onAccept({ file: selectedFile, labels });
    setFiles([...files, selectedFile?.name ?? "UNDEFINED"]);
    setSelectedFile(undefined);
    setLoading(false);
  };

  interface FileListProps {
    content: string[];
  }

  const FileList = ({ content }: FileListProps) => {
    return (
      <List dense={true}>
        {content && content.length ? (
          content.map((s, idx) => {
            return (
              <ListItem key={idx}>
                <ListItemText primary={s} />
              </ListItem>
            );
          })
        ) : (
          <ListItem disableGutters>
            <ListItemText primary="No files to display" />
          </ListItem>
        )}
      </List>
    );
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  function InputFileUpload() {
    return (
      <Button size="small" component="label" variant="contained">
        Select file
        <VisuallyHiddenInput id="file-upload-input" type="file" onChange={handleFileChange} accept={filters} />
      </Button>
    );
  }

  function handleFileChange(event: ChangeEvent<HTMLInputElement>): void {
    const file = event.target.files ? event.target.files[0] : undefined;
    if (file) {
      setSelectedFile(file);
    }
  }

  const addLabel = () => {
    if (!refNewLabel.current?.value) {
      return;
    }

    const selectedLabels = labels ?? [];
    const newValue = refNewLabel.current.value as string;

    if (selectedLabels.includes(newValue)) return;

    selectedLabels.push(newValue);
    setLabels([...selectedLabels]);
  };

  return (
    <Dialog open={visible} onClose={dialogCloseHandler}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <DialogContentText>Please fill up the information below:</DialogContentText>
            <Typography component={"div"} variant="body1" color="text.secondary">
              <fieldset>
                <legend>Files</legend>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <InputFileUpload />
                  </Grid>
                  <Grid item xs={12}>
                    To Upload: {selectedFile ? selectedFile?.name : "--"}
                  </Grid>
                  {fileUploadConf.showExistingFiles && (
                    <>
                      <Grid item xs={12}>
                        Uploaded:
                      </Grid>
                      <Grid item xs={12}>
                        <FileList content={fileUploadConf.existingFiles} />
                      </Grid>
                    </>
                  )}
                </Grid>
              </fieldset>
            </Typography>
            {fileUploadConf.showSetLabels && (
              <Typography component={"div"} variant="body1" color="text.secondary">
                <fieldset>
                  <legend>Labels</legend>
                  <Grid container alignItems="center" justifyContent={"flex-end"}>
                    <Grid item xs={10}>
                      <Autocomplete
                        id="file-upload-labels"
                        freeSolo
                        options={fileUploadConf.initialLabels}
                        renderInput={(params) => <TextField {...params} label="Label" inputRef={refNewLabel} />}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={addLabel}>
                        {" "}
                        <AddIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                      <ChipsArray data={labels ?? []} setChipData={setLabels} showDelete={true} />
                    </Grid>
                  </Grid>
                </fieldset>
              </Typography>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onDismiss} color="error" disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={okButtonClickHandler}
          disabled={loading || (files.length === 0 && !selectedFile)}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};
