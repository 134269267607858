import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Dialog, DialogContent } from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import styled from "@emotion/styled";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { json } from "@codemirror/lang-json";
import { IIntegrationTestLogsIndexEntry } from "../../services/backend/IntegrationTestService";

export interface IntegrationTestsLogDataViewerProps {
  indexLogEntry: IIntegrationTestLogsIndexEntry | undefined;
  title: string;
  logsOrData: string;
  isData: boolean;
  visible: boolean;
  onDownloadButtonClickHandler: (indexLogEntry: IIntegrationTestLogsIndexEntry) => void;
  onClose: () => void;
}

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const IntegrationTestsLogDataViewer = ({
  indexLogEntry,
  title,
  logsOrData,
  isData,
  visible,
  onDownloadButtonClickHandler,
  onClose,
}: IntegrationTestsLogDataViewerProps) => {
  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  const downloadButtonClick = () => {
    if (indexLogEntry) {
      onDownloadButtonClickHandler(indexLogEntry);
    }
  };

  return (
    <Dialog open={visible} onClose={dialogCloseHandler} fullScreen>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Header>
          <Button
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={downloadButtonClick}
            sx={{ marginLeft: 1 }}
            disabled={!indexLogEntry}
          >
            Download
          </Button>
          <div style={{ flexGrow: 1 }} />
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </Header>
        <CodeMirror
          readOnly={true}
          value={logsOrData}
          extensions={isData ? [json()] : []}
          height="82vh"
          width="97vw"
          style={{ marginTop: 16 }}
        />
      </DialogContent>
    </Dialog>
  );
};
