import React, { useState } from "react";
import styled from "@emotion/styled";
import { Backdrop, Button } from "@mui/material";

const DisconnectionText = styled.div`
  font-family: "Helvetica Neue";
  font-weight: bold;
  font-size: x-large;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const DisconnectionOverlay = () => {
  const [open, setOpen] = useState<boolean>(true);
  return (
    <Backdrop
      style={{ flexDirection: "column" }}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <DisconnectionText>
        You have been logged out due to inactivity.
        <br />
        Please back up your work and refresh this page to continue.
      </DisconnectionText>
      <Button variant="contained" onClick={() => setOpen(false)}>
        OK
      </Button>
    </Backdrop>
  );
};
