import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogActions from "@mui/material/DialogActions";
import { downloadFile } from "../../utils/Download";
import { IDataEntity } from "../../services/backend/DataSchemaService";

export interface DataExportDialogProps {
  visible: boolean;
  title: string;
  data: { id: string; name: string }[];
  exportData: () => Promise<IDataEntity[]>;
  onDismiss: () => void;
}

export const DataExportDialog = ({ visible, title, data, exportData, onDismiss }: DataExportDialogProps) => {
  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  const exportToFile = async () => {
    const data = await exportData();

    if (!data) {
      return;
    }

    const content = JSON.stringify(data);
    const bytes = new TextEncoder().encode(content);
    const blob = new Blob([bytes], {
      type: "application/json;charset=utf-8",
    });
    const timestamp = new Date();
    downloadFile(blob, `data-export-${timestamp.toISOString()}.json`);
  };

  const exportToClipboard = async () => {
    const data = await exportData();

    if (!data) {
      return;
    }

    await navigator.clipboard.writeText(JSON.stringify(data, null, 2));
  };

  return (
    <Dialog open={visible} onClose={dialogCloseHandler}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <List dense={true}>
          {data.map((entry) => (
            <ListItem key={entry.id}>
              <ListItemText primary={entry.id} secondary={entry.name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={exportToFile}>
          Export To File
        </Button>
        <Button variant="contained" onClick={exportToClipboard} autoFocus>
          Copy to Clipboard
        </Button>
        <Button variant="contained" color="error" onClick={onDismiss} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
