import { fetchWithErrorHandling, buildPostOptions } from "../FetchUtils";
import { useAuthUser } from "react-auth-kit";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/AppContext";
import { getUserInfo } from "../aws/CognitoService";

export interface ITestDataset {
  name: string;
  creationDate: number;
  entities: string[]; // ids of all entities in the dataset
}

export interface IDataEntity {
  id: string;
  version: number;
  schemaVersion: number;
  archived?: boolean;
}

export interface IPersistenceLayers {
  type: PersistenceLayerType;
}

export interface JSONSchemaProperty {
  type: string;
  format?: string;
  title?: string;
  description?: string;
}

export interface JSONSchemaDefinition {
  description: string;
  type: string | string[];
  required: string[];
  properties: { [key: string]: JSONSchemaProperty };
}

export interface JSONSchema {
  id: string;
  title: string;
  type: string;
  definition: { [key: string]: JSONSchemaDefinition };
  properties: { [key: string]: JSONSchemaProperty };
}

export enum DataSchemaState {
  DRAFT = "DRAFT",
  IN_REVIEW = "IN_REVIEW",
  APPROVED = "APPROVED",
  MERGED = "MERGED",
  DISCARDED = "DISCARDED",
}

export interface PullRequestData {
  id: string;
  state: string;
  merged_at?: string;
  number: number;
  title: string;
}

export enum DataStructureCategory {
  KEY_VALUE = 0,
  ORDERED_LIST = 1,
  ARRAY_2D = 2,
  SORTED_SET = 3,
  STREAM = 4,
  BITMAP = 5,
}

export enum PersistenceLayerType {
  REDIS = 0,
  S3 = 1,
  FORTIS_PLAYER_DATA_SERVICE = 2,
}

export interface IDataStructure {
  name: string;
  creationDate: number;
  category: DataStructureCategory;
  tfidType: number;
  persistenceLayers: IPersistenceLayers[];
  archived: boolean;
  schema: JSONSchema; // a valid JSON schema (see https://www.jsonschemavalidator.net/)
  version: number;
  readAccessGrants: string[];
  writeAccessGrants: string[];
}

export enum AvailableTargetLanguages {
  CSHARP = "csharp",
  TYPESCRIPT = "typescript",
}

export const listDataSchemaBranches = async (accessToken: string, userEmail: string, userName: string, baseURL: string) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/branch/list",
    buildPostOptions(accessToken, { userEmail, userName }),
  );
};

export const createDataSchemaBranch = async (
  accessToken: string,
  userEmail: string,
  userName: string,
  baseURL: string,
  branch: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/branch/create",
    buildPostOptions(accessToken, { userEmail, userName, branch }),
  );
};

export const loadBranch = async (
  accessToken: string,
  userEmail: string,
  userName: string,
  baseURL: string,
  branch: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/branch/load",
    buildPostOptions(accessToken, { userEmail, userName, branch }),
  );
};

export const submitBranch = async (
  accessToken: string,
  userEmail: string,
  userName: string,
  baseURL: string,
  branch: string,
  title: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/branch/submit",
    buildPostOptions(accessToken, { userEmail, userName, branch, title }),
  );
};

export const compileBranch = async (
  accessToken: string,
  userEmail: string,
  userName: string,
  baseURL: string,
  branch: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/branch/compile",
    buildPostOptions(accessToken, { userEmail, userName, branch }),
  );
};

export const mergeBranch = async (
  accessToken: string,
  userEmail: string,
  userName: string,
  baseURL: string,
  branch: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/branch/merge",
    buildPostOptions(accessToken, { userEmail, userName, branch }),
  );
};

export const generateDtos = async (
  baseURL: string,
  accessToken: string,
  userEmail: string,
  userName: string,
  branch: string,
  dataStructures: string[],
  targetLanguage: AvailableTargetLanguages,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/branch/generate-dtos",
    buildPostOptions(accessToken, { userEmail, userName, branch, dataStructures, targetLanguage }),
    false,
  );
};

export const createTestDataset = async (
  accessToken: string,
  userEmail: string,
  userName: string,
  baseURL: string,
  branch: string,
  name: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/test-dataset/create",
    buildPostOptions(accessToken, { userEmail, userName, branch, name }),
  );
};

export const deleteTestDataset = async (
  accessToken: string,
  userEmail: string,
  userName: string,
  baseURL: string,
  branch: string,
  name: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/test-dataset/delete",
    buildPostOptions(accessToken, { userEmail, userName, branch, name }),
  );
};

export const listTestDatasets = async (
  accessToken: string,
  userEmail: string,
  userName: string,
  baseURL: string,
  branch: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/test-dataset/list",
    buildPostOptions(accessToken, { userEmail, userName, branch }),
  );
};

export const generateEntityIdInTestDataset = async (
  accessToken: string,
  userEmail: string,
  userName: string,
  baseURL: string,
  branch: string,
  dataset: string,
  entityType: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/test-dataset/generate-entity-id",
    buildPostOptions(accessToken, { userEmail, userName, branch, dataset, entityType }),
  );
};

export const storeEntityInTestDataset = async (
  accessToken: string,
  userEmail: string,
  userName: string,
  baseURL: string,
  branch: string,
  dataset: string,
  entityData: IDataEntity,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/test-dataset/store-entity",
    buildPostOptions(accessToken, { userEmail, userName, branch, dataset, entityData }),
  );
};

export const loadEntityFromTestDataset = async (
  accessToken: string,
  userEmail: string,
  userName: string,
  baseURL: string,
  branch: string,
  dataset: string,
  entityId: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/test-dataset/load-entity",
    buildPostOptions(accessToken, { userEmail, userName, branch, dataset, entityId }),
  );
};

export const importEntityToTestDataset = async (
  accessToken: string,
  userEmail: string,
  userName: string,
  baseURL: string,
  branch: string,
  dataset: string,
  entityId: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/test-dataset/import-entity",
    buildPostOptions(accessToken, { userEmail, userName, branch, dataset, entityId }),
  );
};

export const removeEntityFromTestDataset = async (
  accessToken: string,
  userEmail: string,
  userName: string,
  baseURL: string,
  branch: string,
  dataset: string,
  entityId: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/test-dataset/remove-entity",
    buildPostOptions(accessToken, { userEmail, userName, branch, dataset, entityId }),
  );
};

export const runTestDataset = async (
  accessToken: string,
  userEmail: string,
  userName: string,
  baseURL: string,
  branch: string,
  name: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/test-dataset/run",
    buildPostOptions(accessToken, { userEmail, userName, branch, name }),
  );
};

//data-structure related  methods

export const updateDataStructure = async (
  baseURL: string,
  accessToken: string,
  userEmail: string,
  userName: string,
  branch: string,
  name: string,
  category: DataStructureCategory,
  tfidType: number,
  persistenceLayers: IPersistenceLayers[],
  schema: object,
  readAccessGrants: string[],
  writeAccessGrants: string[],
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/data-structure/update",
    buildPostOptions(accessToken, {
      userEmail,
      userName,
      branch,
      name,
      category,
      tfidType,
      persistenceLayers,
      schema,
      readAccessGrants,
      writeAccessGrants,
    }),
  );
};

export const archiveDataStructure = async (
  baseURL: string,
  accessToken: string,
  userEmail: string,
  userName: string,
  branch: string,
  name: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/data-structure/archive",
    buildPostOptions(accessToken, { userEmail, userName, branch, name }),
  );
};

export const createDataStructure = async (
  baseURL: string,
  accessToken: string,
  userEmail: string,
  userName: string,
  branch: string,
  name: string,
  category: DataStructureCategory,
  tfidType: number,
  persistenceLayers: IPersistenceLayers[],
  schema: object,
  readAccessGrants: string[],
  writeAccessGrants: string[],
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/data-structure/create",
    buildPostOptions(accessToken, {
      userEmail,
      userName,
      branch,
      name,
      category,
      tfidType,
      persistenceLayers,
      schema,
      readAccessGrants,
      writeAccessGrants,
    }),
  );
};

export const reviveDataStructure = async (
  baseURL: string,
  accessToken: string,
  userEmail: string,
  userName: string,
  branch: string,
  name: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/data-structure/revive",
    buildPostOptions(accessToken, { userEmail, userName, branch, name }),
  );
};

export const getMigrationScript = async (
  baseURL: string,
  accessToken: string,
  userEmail: string,
  userName: string,
  branch: string,
  dataStructureName: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/migration/get",
    buildPostOptions(accessToken, { userEmail, userName, branch, dataStructureName }),
  );
};

export const setMigrationScript = async (
  baseURL: string,
  accessToken: string,
  userEmail: string,
  userName: string,
  branch: string,
  dataStructureName: string,
  script: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/data-schema/migration/set",
    buildPostOptions(accessToken, { userEmail, userName, branch, dataStructureName, script }),
  );
};

export const useDataSchemaService = () => {
  const authUser = useAuthUser();
  const accessToken = authUser()?.access_token;
  const [userEmail, setUserEmail] = useState("unknown@fortis.com");
  const [userName, setUserName] = useState("unknown");

  useEffect(() => {
    (async () => {
      try {
        const fetchedUserInfo = await getUserInfo(accessToken);
        if (fetchedUserInfo.email) {
          setUserEmail(fetchedUserInfo.email);
        }
        if (fetchedUserInfo.username) {
          setUserName(fetchedUserInfo.username);
        }
      } catch (e) {
        // ignore
      }
    })();
  }, [accessToken]);
  const appContext = useContext(AppContext);

  const getBaseURL = () => appContext?.selectedEnvironment?.baseURL || "http://localhost:3001/";

  return {
    listDataSchemaBranches: () => listDataSchemaBranches(accessToken, userEmail, userName, getBaseURL()),
    createDataSchemaBranch: (name: string) => createDataSchemaBranch(accessToken, userEmail, userName, getBaseURL(), name),
    loadBranch: (branch: string) => loadBranch(accessToken, userEmail, userName, getBaseURL(), branch),
    submitBranch: (branch: string, prTitle: string) =>
      submitBranch(accessToken, userEmail, userName, getBaseURL(), branch, prTitle),
    compileBranch: (branch: string) => compileBranch(accessToken, userEmail, userName, getBaseURL(), branch),
    mergeBranch: (branch: string) => mergeBranch(accessToken, userEmail, userName, getBaseURL(), branch),
    generateDtos: (branch: string, dataStructures: string[], targetLanguage: AvailableTargetLanguages) =>
      generateDtos(getBaseURL(), accessToken, userEmail, userName, branch, dataStructures, targetLanguage),
    createTestDataset: (branch: string, name: string) =>
      createTestDataset(accessToken, userEmail, userName, getBaseURL(), branch, name),
    deleteTestDataset: (branch: string, name: string) =>
      deleteTestDataset(accessToken, userEmail, userName, getBaseURL(), branch, name),
    listTestDatasets: (branch: string) => listTestDatasets(accessToken, userEmail, userName, getBaseURL(), branch),
    generateEntityIdInTestDataset: (branch: string, dataset: string, entityType: string) =>
      generateEntityIdInTestDataset(accessToken, userEmail, userName, getBaseURL(), branch, dataset, entityType),
    storeEntityInTestDataset: (branch: string, dataset: string, entityData: IDataEntity) =>
      storeEntityInTestDataset(accessToken, userEmail, userName, getBaseURL(), branch, dataset, entityData),
    loadEntityFromTestDataset: (branch: string, dataset: string, entityId: string) =>
      loadEntityFromTestDataset(accessToken, userEmail, userName, getBaseURL(), branch, dataset, entityId),
    importEntityToTestDataset: (branch: string, dataset: string, entityId: string) =>
      importEntityToTestDataset(accessToken, userEmail, userName, getBaseURL(), branch, dataset, entityId),
    removeEntityFromTestDataset: (branch: string, dataset: string, entityId: string) =>
      removeEntityFromTestDataset(accessToken, userEmail, userName, getBaseURL(), branch, dataset, entityId),
    runTestDataset: (branch: string, name: string) =>
      runTestDataset(accessToken, userEmail, userName, getBaseURL(), branch, name),
    updateDataStructure: (
      branch: string,
      name: string,
      category: DataStructureCategory,
      tfidType: number,
      persistenceLayers: IPersistenceLayers[],
      schema: object,
      readAccessGrants: string[],
      writeAccessGrants: string[],
    ) =>
      updateDataStructure(
        getBaseURL(),
        accessToken,
        userEmail,
        userName,
        branch,
        name,
        category,
        tfidType,
        persistenceLayers,
        schema,
        readAccessGrants,
        writeAccessGrants,
      ),
    archiveDataStructure: (branch: string, name: string) =>
      archiveDataStructure(getBaseURL(), accessToken, userEmail, userName, branch, name),
    createDataStructure: (
      branch: string,
      name: string,
      category: DataStructureCategory,
      tfidType: number,
      persistenceLayers: IPersistenceLayers[],
      schema: object,
      readAccessGrants: string[],
      writeAccessGrants: string[],
    ) =>
      createDataStructure(
        getBaseURL(),
        accessToken,
        userEmail,
        userName,
        branch,
        name,
        category,
        tfidType,
        persistenceLayers,
        schema,
        readAccessGrants,
        writeAccessGrants,
      ),
    reviveDataStructure: (branch: string, name: string) =>
      reviveDataStructure(getBaseURL(), accessToken, userEmail, userName, branch, name),
    getMigrationScript: (branch: string, name: string) =>
      getMigrationScript(getBaseURL(), accessToken, userEmail, userName, branch, name),
    setMigrationScript: (branch: string, name: string, script: string) =>
      setMigrationScript(getBaseURL(), accessToken, userEmail, userName, branch, name, script),
  };
};
