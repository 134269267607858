import * as React from "react";
import { ChangeEvent, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { validateStringField, validateHexField } from "../../../utils/Validation";
import { IBackendImage } from "../../../services/registry/BackendImageService";

export interface BackendImageFormData {
  name: string;
  commitHash: string;
  branch: string;
}

export interface BackendImageFormProps {
  visible: boolean;
  initialData?: IBackendImage;
  onDismiss: () => void;
  onAccept: (data: BackendImageFormData) => void;
}

export const BackendImageFormField = (props: Omit<TextFieldProps, "variant">) => {
  return <TextField autoFocus margin="dense" fullWidth variant="standard" {...props} />;
};

export const BackendImageForm = ({ visible, initialData, onDismiss, onAccept }: BackendImageFormProps) => {
  const refName = useRef<TextFieldProps>(null);
  const refCommitHash = useRef<TextFieldProps>(null);
  const refBranch = useRef<TextFieldProps>(null);

  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [commitHashError, setCommitHashError] = useState<string | undefined>(undefined);
  const [branchError, setBranchError] = useState<string | undefined>(undefined);

  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  const validateName = (value: string) => validateStringField(value, setNameError, 3, 999);
  const validateCommitHash = (value: string) => validateHexField(value, setCommitHashError, 40, 40);
  const validateBranch = (value: string) => validateStringField(value, setBranchError, 1, 999);

  const nameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => nameError && validateName(event.target.value);
  const commitHashChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    commitHashError && validateCommitHash(event.target.value);
  const branchChangeHandler = (event: ChangeEvent<HTMLInputElement>) => branchError && validateBranch(event.target.value);

  const okButtonClickHandler = () => {
    if (!refName.current || !refCommitHash.current || !refBranch.current) {
      return;
    }

    const name = validateName(refName.current.value as string);
    const commitHash = validateCommitHash(refCommitHash.current.value as string);
    const branch = validateBranch(refBranch.current.value as string);
    if (!name || !commitHash || !branch) {
      return;
    }

    onAccept({ name, commitHash, branch });
  };

  return (
    <Dialog open={visible} onClose={dialogCloseHandler}>
      <DialogTitle>{"New Backend Image"}</DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill up the information below:</DialogContentText>
        <BackendImageFormField
          inputRef={refName}
          id="name"
          label="Name"
          type="text"
          defaultValue={initialData?.name}
          error={nameError !== undefined}
          helperText={nameError}
          onChange={nameChangeHandler}
        />
        <BackendImageFormField
          inputRef={refCommitHash}
          id="commitHash"
          label="Commit Hash"
          type="text"
          defaultValue={initialData?.commitHash}
          error={commitHashError !== undefined}
          helperText={commitHashError}
          onChange={commitHashChangeHandler}
        />
        <BackendImageFormField
          inputRef={refBranch}
          id="branch"
          label="Branch"
          type="text"
          defaultValue={initialData?.branch}
          error={branchError !== undefined}
          helperText={branchError}
          onChange={branchChangeHandler}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss}>Cancel</Button>
        <Button onClick={okButtonClickHandler}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};
