import * as React from "react";
import { ChangeEvent, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@mui/material";
import { validateStringField } from "../../../utils/Validation";
import { IServerImage } from "../../../services/registry/ServerImageService";
export type ServerImageFormType = "new" | "edit";

export interface ServerImageFormData {
  id: string;
  name: string;
  version: string;
  branch: string;
}
export interface ServerImageFormProps {
  visible: boolean;
  type: ServerImageFormType;
  initialData?: IServerImage;
  onDismiss: () => void;
  onAccept: (data: ServerImageFormData) => Promise<void>;
}

export const ServerImageFormField = (props: Omit<TextFieldProps, "variant">) => {
  return <TextField autoFocus margin="dense" fullWidth variant="standard" {...props} />;
};

export const ServerImageForm = ({ visible, type, initialData, onDismiss, onAccept }: ServerImageFormProps) => {
  const [loading, setLoading] = useState(false);

  const refName = useRef<TextFieldProps>(null);
  const refVersion = useRef<TextFieldProps>(null);
  const refBranch = useRef<TextFieldProps>(null);

  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [versionError, setVersionError] = useState<string | undefined>(undefined);
  const [branchError, setBranchError] = useState<string | undefined>(undefined);

  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  const validateName = (value: string) => validateStringField(value, setNameError, 3, 999);
  const validateVersion = (value: string) => validateStringField(value, setVersionError, 1, 999);
  const validateBranch = (value: string) => validateStringField(value, setBranchError, 1, 999);

  const nameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => nameError && validateName(event.target.value);
  const versionChangeHandler = (event: ChangeEvent<HTMLInputElement>) => versionError && validateVersion(event.target.value);
  const branchChangeHandler = (event: ChangeEvent<HTMLInputElement>) => branchError && validateBranch(event.target.value);

  const okButtonClickHandler = async () => {
    if (!refName.current || !refVersion.current || !refBranch.current) {
      return;
    }

    const id = initialData?.id ?? "";
    const name = validateName(refName.current.value as string);
    const version = validateVersion(refVersion.current.value as string);
    const branch = validateBranch(refBranch.current.value as string);
    if (!name || !version || !branch) {
      return;
    }

    setLoading(true);
    await onAccept({ id, name, version, branch });
    setLoading(false);
  };

  return (
    <Dialog open={visible} onClose={dialogCloseHandler}>
      <DialogTitle>{(type === "new" ? "New" : "Edit") + " Server Image"}</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <DialogContentText>Please fill up the information below:</DialogContentText>
            <ServerImageFormField
              inputRef={refName}
              id="name"
              label="Name"
              type="text"
              defaultValue={initialData?.name}
              error={nameError !== undefined}
              helperText={nameError}
              onChange={nameChangeHandler}
            />
            <ServerImageFormField
              inputRef={refVersion}
              id="version"
              label="Version"
              type="text"
              defaultValue={initialData?.version}
              error={versionError !== undefined}
              helperText={versionError}
              onChange={versionChangeHandler}
            />
            <ServerImageFormField
              inputRef={refBranch}
              id="branch"
              label="Branch"
              type="text"
              defaultValue={initialData?.branch}
              error={branchError !== undefined}
              helperText={branchError}
              onChange={branchChangeHandler}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss} color="error" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={okButtonClickHandler} disabled={loading}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
