import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useState } from "react";

export interface MultiSelectProps {
  names: string[];
  label: string;
  setHandler: (data: string[]) => void;
}
export default function MultipleSelectCheckmarks(props: MultiSelectProps) {
  const [itemName, setItemName] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof itemName>) => {
    const {
      target: { value },
    } = event;
    setItemName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
    props.setHandler(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 400 }}>
        <InputLabel id="multiple-checkbox-label">{props.label}</InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={itemName}
          onChange={handleChange}
          input={<OutlinedInput label={props.label} />}
          renderValue={(selected) => selected.join(", ")}
        >
          {props.names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={itemName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
