import React, { useEffect } from "react";
import { AppContext } from "utils/AppContext";

const Home = () => {
  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Home");
    context?.setTargetAPI("registry");
  }, [context]);

  return <></>;
};

export default Home;
