import { getRegistryURL } from "./Registry";
import { useAuthUser } from "react-auth-kit";
import { buildDeleteOptions, buildGetOptions, buildPostOptions, fetchWithErrorHandling } from "../FetchUtils";

export interface IManagedParameter {
  key: string;
  env: string | undefined;
  name: string;
  description: string;
  value: string;
  type: string;
  version: number;
}

const endpoint = (action: string): string => {
  return `${getRegistryURL()}/v1/registry/managed-parameter/${action}`;
};

export const listManagedParameters = async (accessToken: string, env: string | undefined) => {
  return fetchWithErrorHandling(endpoint("list" + (env ? `/${env}` : "")), buildGetOptions(accessToken));
};

export const addManagedParameter = async (
  accessToken: string,
  key: string,
  env: string | undefined,
  description: string,
  value: string,
  type: string,
) => {
  const options = buildPostOptions(accessToken, { key, env, description, value, type });
  return fetchWithErrorHandling(endpoint("add"), options);
};

export const editManagedParameter = async (
  accessToken: string,
  key: string,
  env: string | undefined,
  description: string,
  value: string,
  type: string,
) => {
  const options = buildPostOptions(accessToken, { key, env, description, value, type });
  return fetchWithErrorHandling(endpoint("edit"), options);
};

export const removeManagedParameter = async (accessToken: string, key: string, env: string | undefined) => {
  return fetchWithErrorHandling(endpoint(`remove/${key}` + (env ? `/${env}` : "")), buildDeleteOptions(accessToken));
};

export const useManagedParameterService = () => {
  const authUser = useAuthUser();
  const accessToken = authUser()?.access_token;
  return {
    listManagedParameters: (env: string | undefined) => listManagedParameters(accessToken, env),
    addManagedParameter: (key: string, env: string | undefined, description: string, value: string, type: string) =>
      addManagedParameter(accessToken, key, env, description, value, type),
    editManagedParameter: (key: string, env: string | undefined, description: string, value: string, type: string) =>
      editManagedParameter(accessToken, key, env, description, value, type),
    removeManagedParameter: (key: string, env: string | undefined) => removeManagedParameter(accessToken, key, env),
  };
};
