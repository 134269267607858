import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ChangeEvent, useRef, useState } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { validateStringField } from "../utils/Validation";

export interface StringInputPopup {
  visible: boolean;
  title: string;
  message?: string;
  defaultValue?: string;
  fieldName?: string;
  minLength?: number;
  maxLength?: number;
  onDismiss: () => void;
  onAccept: (value: string) => void;
}

export const StringInputPopup = ({
  visible,
  title,
  message,
  defaultValue,
  fieldName,
  minLength,
  maxLength,
  onDismiss,
  onAccept,
}: StringInputPopup) => {
  const refValue = useRef<TextFieldProps>(null);
  const [valueError, setValueError] = useState<string | undefined>(undefined);
  const validateValue = (value: string) => validateStringField(value, setValueError, minLength || 0, maxLength || 999);
  const valueChangeHandler = (event: ChangeEvent<HTMLInputElement>) => valueError && validateValue(event.target.value);

  const popupCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  const okButtonClickHandler = () => {
    if (!refValue.current) {
      return;
    }

    const value = validateValue(refValue.current.value as string);
    if (!value) {
      return;
    }

    onAccept(value);
  };

  return (
    <Dialog open={visible} onClose={popupCloseHandler}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-message"
          dangerouslySetInnerHTML={message !== undefined ? { __html: message } : undefined}
        />
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          variant="standard"
          inputRef={refValue}
          id="alert-dialog-input-field"
          label={fieldName ?? "Name"}
          type="text"
          defaultValue={defaultValue}
          error={valueError !== undefined}
          helperText={valueError}
          onChange={valueChangeHandler}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss}>Cancel</Button>
        <Button onClick={okButtonClickHandler}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};
