import * as React from "react";
import { useState, useMemo, MouseEvent, ChangeEvent, Dispatch, SetStateAction, useEffect } from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import Toolbar from "@mui/material/Toolbar";
import { Button, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import { getComparator, Order, stableSort } from "../../utils/Table";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SendIcon from "@mui/icons-material/Send";
import SyncIcon from "@mui/icons-material/Sync";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IGeneratedArtIdData } from "../../services/asset/AssetToolingService";

interface HeadCell {
  id: keyof IGeneratedArtIdData | string;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
}

const headCells: readonly HeadCell[] = [
  {
    id: "asset-id",
    label: "Asset ID",
    padding: "none",
  },
  {
    id: "subcategory",
    label: "Subcategory",
  },
  {
    id: "taskName",
    label: "Task Name",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "taskType",
    label: "Task Type",
  },
  {
    id: "complexity",
    label: "Complexity",
  },
  {
    id: "actions",
    label: "Actions",
    align: "center",
  },
];

interface ArtAssetsIdsTableHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IGeneratedArtIdData | string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const ArtAssetsIdsTableHead = ({ order, orderBy, onRequestSort }: ArtAssetsIdsTableHeadProps) => {
  const createSortHandler = (property: keyof IGeneratedArtIdData | string) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface ArtAssetsIdsTableToolbarProps {
  subcategories: string[];
  selectedSubcategory: string;
  setSelectedSubcategory: (subcategory: string) => void;
  onAddButtonClick: () => void;
  onSendClickupPendingClick: () => void;
  onRefreshRowsClick: () => void;
}

const ArtAssetsIdsTableToolbar = ({
  subcategories,
  selectedSubcategory,
  setSelectedSubcategory,
  onAddButtonClick,
  onSendClickupPendingClick,
  onRefreshRowsClick,
}: ArtAssetsIdsTableToolbarProps) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <FormControl variant="standard" sx={{ mt: 1, mb: 1, width: "220px" }}>
        <InputLabel id="subcategory-selector-label">Subcategory</InputLabel>
        <Select
          labelId="subcategory-selector-label"
          id="subcategory-selector"
          value={selectedSubcategory}
          onChange={(e) => setSelectedSubcategory(e.target.value)}
          label="Subcategory"
        >
          <MenuItem key="show-all" value={undefined}>
            Show All
          </MenuItem>
          {subcategories.map((subcat) => (
            <MenuItem key={subcat} value={subcat}>
              {subcat}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button variant="outlined" startIcon={<AddIcon />} onClick={onAddButtonClick} sx={{ ml: 2 }}>
        Create New IDs
      </Button>

      <Button variant="outlined" startIcon={<SendIcon />} onClick={onSendClickupPendingClick} sx={{ ml: 2 }}>
        Send Pending to ClickUp
      </Button>

      <Button variant="outlined" startIcon={<RefreshIcon />} onClick={onRefreshRowsClick} sx={{ ml: 2 }}>
        Refresh Rows
      </Button>
    </Toolbar>
  );
};

export interface ArtAssetsIdsTableProps {
  rows: IGeneratedArtIdData[];
  selectedItem: IGeneratedArtIdData | undefined;
  setSelectedItem: Dispatch<SetStateAction<IGeneratedArtIdData | undefined>>;
  onAddButtonClick: () => void;
  onSyncClickupClick: (assetId: string) => void;
  onSendClickupClick: (assetId: string) => void;
  onSendClickupPendingClick: () => void;
  onRefreshRowsClick: () => Promise<void>;
}

export const ArtAssetsIdsTable = ({
  rows,
  onAddButtonClick,
  onSyncClickupClick,
  onSendClickupClick,
  onSendClickupPendingClick,
  selectedItem,
  setSelectedItem,
  onRefreshRowsClick,
}: ArtAssetsIdsTableProps) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof IGeneratedArtIdData | string>("creationDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>("");
  const [filteredRows, setFilteredRows] = useState<IGeneratedArtIdData[]>(rows);

  useEffect(() => {
    setFilteredRows(rows.filter((row) => selectedSubcategory === "" || row.SubCategory === selectedSubcategory));
  }, [rows, selectedSubcategory]);

  const subcategories = () => {
    const subcategories = rows.map((row) => row.SubCategory);

    return Array.from(new Set(subcategories));
  };

  const requestSortHandler = (_: MouseEvent<unknown>, property: keyof IGeneratedArtIdData | string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const pageChangeHandler = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    return stableSort(filteredRows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [filteredRows, order, orderBy, page, rowsPerPage]);

  const isSelected = (row: IGeneratedArtIdData, selectedItem: IGeneratedArtIdData | undefined) =>
    selectedItem !== undefined && row.AssetID === selectedItem.AssetID;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <ArtAssetsIdsTableToolbar
          subcategories={subcategories()}
          selectedSubcategory={selectedSubcategory}
          setSelectedSubcategory={setSelectedSubcategory}
          onAddButtonClick={onAddButtonClick}
          onSendClickupPendingClick={onSendClickupPendingClick}
          onRefreshRowsClick={onRefreshRowsClick}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="small">
            <ArtAssetsIdsTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={requestSortHandler}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `managed-parameters-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    onClick={() => setSelectedItem(row)}
                    tabIndex={-1}
                    key={row.AssetID}
                    selected={isSelected(row, selectedItem)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="normal" />
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row.AssetID}
                    </TableCell>
                    <TableCell align="left">{row.SubCategory}</TableCell>
                    <TableCell align="left">{row.ClickupName}</TableCell>
                    <TableCell align="left">{row.Status}</TableCell>
                    <TableCell align="left">{row.TaskType}</TableCell>
                    <TableCell align="left">{row.Complexity}</TableCell>
                    <TableCell>
                      <Stack spacing={1} direction="row" justifyContent="flex-end">
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<SyncIcon />}
                          onClick={() => onSyncClickupClick(row.AssetID)}
                          disabled={row.ClickupTaskID === undefined}
                        >
                          Sync with ClickUp
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<SendIcon />}
                          onClick={() => onSendClickupClick(row.AssetID)}
                          disabled={row.ClickupTaskID !== undefined}
                        >
                          Send To ClickUp
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<VisibilityIcon />}
                          onClick={() => window.open(row.ClickupTask)}
                          disabled={row.ClickupTaskID === undefined}
                        >
                          Go to Task
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={pageChangeHandler}
          onRowsPerPageChange={rowsPerPageChangeHandler}
        />
      </Paper>
    </Box>
  );
};
