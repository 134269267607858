import * as React from "react";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import { Button, Card, CardActions, CardContent, Chip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";
import { DateTime } from "luxon";
import { IClientBuild } from "../../../services/registry/ClientBuildService";

const ClientBuildCardLine = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 32px;
`;

export interface ClientBuildCardProps {
  content: IClientBuild;
  onEditButtonClick: () => void;
  onDeleteButtonClick: () => void;
  onPromoteButtonClick: () => void;
}

export const ClientBuildCard = ({
  content,
  onEditButtonClick,
  onDeleteButtonClick,
  onPromoteButtonClick,
}: ClientBuildCardProps) => {
  const creationDate = DateTime.fromSeconds(content.creationDate).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);

  const environmentChip = () => {
    if (content.environment === undefined) {
      return <Chip label="Unpromoted" variant="outlined" size="small" color="error" />;
    }

    return <Chip label={content.environment} variant="outlined" size="small" color="success" />;
  };

  return (
    <Card sx={{ maxWidth: 540 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {content.name} {environmentChip()}
        </Typography>
        <Typography variant="body2" color="text.secondary" textAlign="right">
          {creationDate}
        </Typography>
      </CardContent>
      <CardContent>
        <ClientBuildCardLine>
          <Typography variant="body1" color="text.secondary">
            ID: <b>{content.id}</b>
          </Typography>
        </ClientBuildCardLine>
        <ClientBuildCardLine>
          <Typography variant="body1" color="text.secondary">
            Version: <b>{content.version}</b>
          </Typography>
        </ClientBuildCardLine>
        <ClientBuildCardLine>
          <Typography variant="body1" color="text.secondary">
            Branch: <b>{content.branch}</b>
          </Typography>
        </ClientBuildCardLine>
        <ClientBuildCardLine>
          <Typography variant="body1" color="text.secondary">
            Platform: <b>{content.platform}</b>
          </Typography>
        </ClientBuildCardLine>
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          onClick={onEditButtonClick}
          disabled={content.environment !== undefined}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={onDeleteButtonClick}
          disabled={content.environment !== undefined}
        >
          Remove
        </Button>
        <div style={{ flexGrow: 1 }} />
        <Button
          variant="outlined"
          startIcon={<PublishIcon />}
          onClick={onPromoteButtonClick}
          disabled={content.environment !== undefined}
        >
          Promote
        </Button>
      </CardActions>
    </Card>
  );
};
