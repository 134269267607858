export const downloadFile = (content: Blob, fileName: string): void => {
  const virtualAnchor = document.createElement("a");
  virtualAnchor.setAttribute("download", fileName);
  const href = URL.createObjectURL(content);
  virtualAnchor.href = href;
  virtualAnchor.setAttribute("target", "_blank");
  virtualAnchor.click();
  URL.revokeObjectURL(href);
};

export const fileNameFromContentDisposition = (contentDispositionHeader: string): string => {
  const parts = contentDispositionHeader.split(";");

  if (parts.length === 1) {
    return "";
  }

  // in case the filename is wrapped in double quotes
  const quotesWrapped = parts[1].split('"');
  if (quotesWrapped.length == 3) {
    return quotesWrapped[1];
  }

  return parts.length >= 2 ? parts[1].split("=")[1] : "";
};
