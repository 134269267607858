import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export interface YesNoPopupProps {
  visible: boolean;
  title: string;
  message?: string;
  onNo: () => void;
  onYes: () => void;
}

export const YesNoPopup = ({ visible, title, message, onNo, onYes }: YesNoPopupProps) => {
  return (
    <Dialog open={visible} onClose={onNo}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {message !== undefined && (
        <DialogContent>
          <DialogContentText id="alert-dialog-message" dangerouslySetInnerHTML={{ __html: message }} />
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onNo} color="error" autoFocus>
          No
        </Button>
        <Button onClick={onYes}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};
