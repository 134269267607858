import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IManagedParameter } from "../../../services/registry/ManagedParameterService";

export interface ManagedParameterShowProps {
  visible: boolean;
  data: IManagedParameter | undefined;
  onDismiss: () => void;
}

export const ManagedParameterShow = ({ visible, data, onDismiss }: ManagedParameterShowProps) => {
  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  return (
    <Dialog open={visible} onClose={dialogCloseHandler} fullWidth={true} maxWidth="sm">
      <DialogTitle>{data?.name}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mt: 2 }}>Key: {data?.key}</DialogContentText>
        <DialogContentText sx={{ mt: 2 }}>Environment: {data?.env ?? "NOT SET"}</DialogContentText>
        <DialogContentText sx={{ mt: 2 }}>Type: {data?.type}</DialogContentText>
        <DialogContentText sx={{ mt: 2 }}>Description:</DialogContentText>
        <DialogContentText>{data?.description}</DialogContentText>
        <DialogContentText sx={{ mt: 2 }}>Value:</DialogContentText>
        <DialogContentText>{data?.value}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss} color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
