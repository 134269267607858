/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import {
  AlertColor,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { IEnvironment, useEnvironmentService } from "../services/registry/EnvironmentService";
import { Notify } from "./Notify";

export interface EnvironmentPickerProps {
  visible: boolean;
  dialogTitle: string;
  actionButtonLabel: string;
  onCancel: () => void;
  onAction: (environment: IEnvironment) => Promise<void>;
}

export const EnvironmentPicker = ({
  visible,
  dialogTitle,
  actionButtonLabel,
  onCancel,
  onAction,
}: EnvironmentPickerProps) => {
  const environmentService = useEnvironmentService();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [environments, setEnvironments] = useState<IEnvironment[]>([]);
  const refEnvironment = useRef<SelectProps>(null);
  const [environmentError, setEnvironmentError] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => fetchEnvironments())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEnvironments = async () => {
    try {
      const { list } = await environmentService.listEnvironments();
      setEnvironments(list);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to load available environments.");
      setAlertSeverity("error");
    }
  };

  const actionButtonClickHandler = async () => {
    if (!refEnvironment.current?.value) {
      setEnvironmentError("You must select an Environment");
      return;
    } else {
      setLoading(true);
      setEnvironmentError(undefined);
      const selectedEnvironmentName = refEnvironment.current.value as string;
      const environment = environments.find((env) => env.name === selectedEnvironmentName);
      if (environment) {
        await onAction(environment);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={visible}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <FormControl sx={{ m: 1, minWidth: 540 }}>
                <InputLabel id="environment-label">Environment</InputLabel>
                <Select
                  labelId="environment-label"
                  id="environment-selector"
                  defaultValue=""
                  inputRef={refEnvironment}
                  label="Environment"
                  error={environmentError !== undefined}
                >
                  {environments
                    .filter((env) => env.terminationDate === undefined)
                    .map((env) => (
                      <MenuItem key={env.name} value={env.name}>
                        {env.name}
                      </MenuItem>
                    ))}
                </Select>
                {environmentError && <FormHelperText error={true}>{environmentError}</FormHelperText>}
              </FormControl>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel} color="error" disabled={loading}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={actionButtonClickHandler} disabled={loading}>
            {actionButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
    </>
  );
};
