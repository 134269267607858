import { useAuthUser } from "react-auth-kit";
import { buildDeleteOptions, buildPostOptions, fetchWithErrorHandling } from "services/FetchUtils";
import { getRegistryURL } from "services/registry/Registry";

export enum WadState {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

export interface IWadFile {
  name: string;
  crc32: string;
}

export interface IWad {
  id: string;
  labels: string[];
  files: IWadFile[];
  createdTime: number;
  state: WadState;
}
const endpoint = (action: string): string => {
  return `${getRegistryURL()}/v1/registry/wad/${action}`;
};

export const listWads = async (accessToken: string, labels: string[]) => {
  const options = buildPostOptions(accessToken, { labels });
  return fetchWithErrorHandling(endpoint("list"), options);
};

export const setLabels = async (accessToken: string, id: string, labels: string[]) => {
  const options = buildPostOptions(accessToken, {
    id,
    labels,
  });
  return fetchWithErrorHandling(endpoint("set-labels"), options);
};

export const create = async (accessToken: string) => {
  const options = buildPostOptions(accessToken, {});
  return fetchWithErrorHandling(endpoint("create"), options);
};

export const remove = async (accessToken: string, id: string) => {
  const options = buildDeleteOptions(accessToken);
  return fetchWithErrorHandling(endpoint(`remove/${id}`), options);
};

export const publish = async (accessToken: string, id: string) => {
  const options = buildPostOptions(accessToken, { id });
  return fetchWithErrorHandling(endpoint("publish"), options);
};

export const fileUpload = async (accessToken: string, id: string, file: File) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("file", file);
  const options = buildPostOptions(accessToken, null, formData);
  return fetchWithErrorHandling(endpoint("upload-file"), options);
};

export const useWadService = () => {
  const authUser = useAuthUser();
  const accessToken = authUser()?.access_token;
  return {
    listWads: (labels: string[]) => listWads(accessToken, labels),
    setLabels: (id: string, labels: string[]) => setLabels(accessToken, id, labels),
    create: () => create(accessToken),
    remove: (id: string) => remove(accessToken, id),
    publish: (id: string) => publish(accessToken, id),
    fileUpload: (id: string, file: File) => fileUpload(accessToken, id, file),
  };
};
