import React, { useState } from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";

export interface INotify {
  severity: AlertColor;
  message: string;
}

export const Notify = ({ severity, message }: INotify) => {
  const [open, setOpen] = useState(true);

  const handleClose = async (): Promise<void> => {
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity={severity} sx={{ width: "100%" }} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};
