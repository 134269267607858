let devRegistryURL = process.env.REACT_APP_DEV_REGISTRY_URL;
if (!devRegistryURL) {
  console.warn('The environment variable "REACT_APP_DEV_REGISTRY_URL" must be defined, using the default one.');
  devRegistryURL = "https://dev-registry.tfk.area1.app";
}

// Since we don't have a Registry for the Registry, that's basically how the Admin Panel should know where to make its requests.
export const getRegistryURL = () => {
  const topDomain = new URL(document.location.href).hostname.split(".").pop();
  switch (topDomain) {
    case "localhost":
      return "http://localhost:3001";
    default:
      return devRegistryURL;
  }
};
