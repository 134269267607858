import * as React from "react";
import { ChangeEvent, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { validateIntegerField, validateStringField, validateStrongPasswordField } from "../../utils/Validation";
import { ILoadTesterInfo } from "../../services/backend/LoadTesterService";
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

export interface ILoadTesterFormData {
  name: string;
  scriptArgs: string;
  townsfolkClientZip: string;
  batchScriptZip: string;
  instanceType: string;
  username: string;
  autoShutdownDelay: number;
  password: string;
  vpcId: string;
  subnetId: string;
  keypair: string;
  branch: string;
  batchCreate: boolean;
  firstMachineNumber: number;
  lastMachineNumber: number;
  firstPlayerNumber: number;
  lastPlayerNumber: number;
}

export interface LoadTesterFormProps {
  visible: boolean;
  initialData?: ILoadTesterInfo;
  onDismiss: () => void;
  onAccept: (data: ILoadTesterFormData) => void;
}

export const LoadTesterFormField = (props: Omit<TextFieldProps, "variant">) => {
  return <TextField autoFocus margin="dense" fullWidth variant="standard" {...props} />;
};

export const LoadTesterForm = ({ visible, initialData, onDismiss, onAccept }: LoadTesterFormProps) => {
  const refName = useRef<TextFieldProps>(null);
  const refTownsfolkClientZip = useRef<TextFieldProps>(null);
  const refBatchScriptZip = useRef<TextFieldProps>(null);
  const refScriptArgs = useRef<TextFieldProps>(null);
  const refInstanceType = useRef<TextFieldProps>(null);
  const refUsername = useRef<TextFieldProps>(null);
  const refPassword = useRef<TextFieldProps>(null);
  const refVpcId = useRef<TextFieldProps>(null);
  const refSubnetId = useRef<TextFieldProps>(null);
  const refKeypair = useRef<TextFieldProps>(null);
  const refAutoShutdownDelay = useRef<TextFieldProps>(null);
  const refBranch = useRef<TextFieldProps>(null);
  const refFirstMachineNumber = useRef<TextFieldProps>(null);
  const refLastMachineNumber = useRef<TextFieldProps>(null);
  const refFirstPlayerNumber = useRef<TextFieldProps>(null);
  const refLastPlayerNumber = useRef<TextFieldProps>(null);

  const [selectedType, setSelectedType] = useState<string>("proto");
  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [townsfolkClientZipError, setTownsfolkClientZipError] = useState<string | undefined>(undefined);
  const [batchScriptZipError, setBatchScriptZipError] = useState<string | undefined>(undefined);
  const [scriptArgsError, setScriptArgsError] = useState<string | undefined>(undefined);
  const [instanceTypeError, setInstanceTypeError] = useState<string | undefined>(undefined);
  const [usernameError, setUsernameError] = useState<string | undefined>(undefined);
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined);
  const [vpcIdError, setVpcIdError] = useState<string | undefined>(undefined);
  const [subnetIdError, setSubnetIdError] = useState<string | undefined>(undefined);
  const [keypairError, setKeypairError] = useState<string | undefined>(undefined);
  const [autoShutdownDelayError, setAutoShutdownDelayError] = useState<string | undefined>(undefined);
  const [branchError, setBranchError] = useState<string | undefined>(undefined);
  const [batchCreate, setBatchCreate] = useState<boolean>(false);
  const [firstMachineNumberError, setFirstMachineNumberError] = useState<string | undefined>(undefined);
  const [lastMachineNumberError, setLastMachineNumberError] = useState<string | undefined>(undefined);
  const [firstPlayerNumberError, setFirstPlayerNumberError] = useState<string | undefined>(undefined);
  const [lastPlayerNumberError, setLastPlayerNumberError] = useState<string | undefined>(undefined);

  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  const validateName = (value: string | undefined) => {
    value = validateStringField(value, setNameError, 5, 60);
    if (value) {
      if (batchCreate && value.indexOf("%mid%") === -1) {
        setNameError('Must contain "%mid%" in batch create mode.');
        return undefined;
      }
    }
    return value;
  };
  const validateTownsfolkClientZip = (value: string) => validateStringField(value, setTownsfolkClientZipError, 4, 40);
  const validateBatchScriptZip = (value: string) => validateStringField(value, setBatchScriptZipError, 4, 40);
  const validateScriptArgs = (value: string | undefined) => {
    value = validateStringField(value, setScriptArgsError, 20, 160);
    if (value) {
      if (batchCreate && (value.indexOf("%psid%") === -1 || value.indexOf("%peid%") === -1)) {
        setScriptArgsError('Must contain "%psid%" and "%peid%" in batch create mode.');
        return undefined;
      }
    }
    return value;
  };
  const validateInstanceType = (value: string) => validateStringField(value, setInstanceTypeError, 6, 40);
  const validateUsername = (value: string) => validateStringField(value, setUsernameError, 6, 30);
  const validatePassword = (value: string) => validateStrongPasswordField(value, setPasswordError);
  const validateVpcId = (value: string) => validateStringField(value, setVpcIdError, 12, 36);
  const validateSubnetId = (value: string) => validateStringField(value, setSubnetIdError, 16, 48);
  const validateKeypair = (value: string) => validateStringField(value, setKeypairError, 4, 40);
  const validateAutoShutdownDelay = (value: string) => validateIntegerField(value, setAutoShutdownDelayError, 0, 9999);
  const validateBranch = (value: string) => validateStringField(value, setBranchError, 3, 80);
  const validateFirstMachineNumber = (value: string) => validateIntegerField(value, setFirstMachineNumberError, 0, 100);
  const validateLastMachineNumber = (value: string) => validateIntegerField(value, setLastMachineNumberError, 0, 100);
  const validateFirstPlayerNumber = (value: string) => validateIntegerField(value, setFirstPlayerNumberError, 0, 1000);
  const validateLastPlayerNumber = (value: string) => validateIntegerField(value, setLastPlayerNumberError, 0, 1000);

  const batchCreateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setBatchCreate(event.target.checked);
    if (!refName.current || !refScriptArgs.current) {
      return;
    }
    if (event.target.checked) {
      refName.current.value = "WindowsLoadTester%mid%";
      refScriptArgs.current.value = "Test Player %psid% %peid% https://dev-proto.tfk.area1.app/v1/logging/append";
    } else {
      refName.current.value = "WindowsLoadTester0";
      refScriptArgs.current.value = "Test Player 1 10 https://dev-proto.tfk.area1.app/v1/logging/append";
    }
  };

  const nameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => nameError && validateName(event.target.value);
  const townsfolkClientZipChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    townsfolkClientZipError && validateTownsfolkClientZip(event.target.value);
  const batchScriptZipChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    batchScriptZipError && validateBatchScriptZip(event.target.value);
  const scriptArgsChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    scriptArgsError && validateScriptArgs(event.target.value);
  const instanceTypeChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    instanceTypeError && validateInstanceType(event.target.value);
  const usernameChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    usernameError && validateUsername(event.target.value);
  const passwordChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    passwordError && validatePassword(event.target.value);
  const vpcIdChangeHandler = (event: ChangeEvent<HTMLInputElement>) => vpcIdError && validateVpcId(event.target.value);
  const subnetIdChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    subnetIdError && validateSubnetId(event.target.value);
  const keypairChangeHandler = (event: ChangeEvent<HTMLInputElement>) => keypairError && validateKeypair(event.target.value);
  const autoShutdownDelayChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    autoShutdownDelayError && validateAutoShutdownDelay(event.target.value);
  const branchChangeHandler = (event: ChangeEvent<HTMLInputElement>) => branchError && validateBranch(event.target.value);
  const firstMachineNumberChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    firstMachineNumberError && validateFirstMachineNumber(event.target.value);
  const lastMachineNumberChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    lastMachineNumberError && validateLastMachineNumber(event.target.value);
  const firstPlayerNumberChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    firstPlayerNumberError && validateFirstPlayerNumber(event.target.value);
  const lastPlayerNumberChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    lastPlayerNumberError && validateLastPlayerNumber(event.target.value);

  const okButtonClickHandler = () => {
    if (
      !refName.current ||
      !refTownsfolkClientZip.current ||
      !refBatchScriptZip.current ||
      !refScriptArgs.current ||
      !refInstanceType.current ||
      !refUsername.current ||
      !refPassword.current ||
      !refVpcId.current ||
      !refSubnetId.current ||
      !refKeypair.current ||
      !refAutoShutdownDelay.current ||
      !refBranch.current
    ) {
      return;
    }

    const name = validateName(refName.current.value as string);
    const townsfolkClientZip = validateTownsfolkClientZip(refTownsfolkClientZip.current.value as string);
    const batchScriptZip = validateBatchScriptZip(refBatchScriptZip.current.value as string);
    const scriptArgs = validateScriptArgs(refScriptArgs.current.value as string);
    const instanceType = validateInstanceType(refInstanceType.current.value as string);
    const username = validateUsername(refUsername.current.value as string);
    const password = validatePassword(refPassword.current.value as string);
    const vpcId = validateVpcId(refVpcId.current.value as string);
    const subnetId = validateSubnetId(refSubnetId.current.value as string);
    const keypair = validateKeypair(refKeypair.current.value as string);
    const autoShutdownDelay = validateAutoShutdownDelay(refAutoShutdownDelay.current.value as string);
    const branch = validateBranch(refBranch.current.value as string);
    if (
      !name ||
      !instanceType ||
      !townsfolkClientZip ||
      !batchScriptZip ||
      !scriptArgs ||
      !instanceType ||
      !username ||
      !password ||
      !vpcId ||
      !subnetId ||
      !keypair ||
      autoShutdownDelay === undefined ||
      !branch
    ) {
      return;
    }

    let firstMachineNumber: number | undefined = 1;
    let lastMachineNumber: number | undefined = 3;
    let firstPlayerNumber: number | undefined = 1;
    let lastPlayerNumber: number | undefined = 30;

    if (batchCreate) {
      if (
        !refFirstMachineNumber.current ||
        !refLastMachineNumber.current ||
        !refFirstPlayerNumber.current ||
        !refLastPlayerNumber.current
      ) {
        return;
      }

      firstMachineNumber = validateFirstMachineNumber(refFirstMachineNumber.current.value as string);
      lastMachineNumber = validateLastMachineNumber(refLastMachineNumber.current.value as string);
      firstPlayerNumber = validateFirstPlayerNumber(refFirstPlayerNumber.current.value as string);
      lastPlayerNumber = validateLastPlayerNumber(refLastPlayerNumber.current.value as string);
      if (!firstMachineNumber || !lastMachineNumber || !firstPlayerNumber || !lastPlayerNumber) {
        return;
      }
    }

    onAccept({
      name,
      townsfolkClientZip,
      batchScriptZip,
      scriptArgs,
      instanceType,
      username,
      password,
      vpcId,
      subnetId,
      keypair,
      autoShutdownDelay,
      branch,
      batchCreate,
      firstMachineNumber,
      lastMachineNumber,
      firstPlayerNumber,
      lastPlayerNumber,
    });
  };

  return (
    <Dialog open={visible} onClose={dialogCloseHandler}>
      <DialogTitle>{"New Load Tester"}</DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill up the information below:</DialogContentText>
        <FormControl sx={{ mt: 1, width: "240px", marginTop: 3 }}>
          <InputLabel id="load-tester-type-selector-label">Load Tester Type</InputLabel>
          <Select
            labelId="load-tester-type-selector-label"
            id="load-tester-type-selector"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            label="Load Tester Type"
            disabled
          >
            <MenuItem key={"proto"} value={"proto"}>
              Prototype
            </MenuItem>
            <MenuItem key={"prod"} value={"prod"}>
              Production
            </MenuItem>
          </Select>
        </FormControl>
        <div>
          <FormControlLabel
            sx={{ width: "100%", marginTop: 1 }}
            control={<Checkbox autoFocus id="batchCreate" onChange={batchCreateChangeHandler} checked={batchCreate} />}
            label="Batch Create"
          />
        </div>
        {batchCreate && (
          <>
            <LoadTesterFormField
              inputRef={refFirstMachineNumber}
              id="firstMachineNumber"
              label="First machine #"
              type="text"
              defaultValue="1"
              error={firstMachineNumberError !== undefined}
              helperText={firstMachineNumberError}
              onChange={firstMachineNumberChangeHandler}
            />
            <LoadTesterFormField
              inputRef={refLastMachineNumber}
              id="lastMachineNumber"
              label="Last machine #"
              type="text"
              defaultValue="3"
              error={lastMachineNumberError !== undefined}
              helperText={lastMachineNumberError}
              onChange={lastMachineNumberChangeHandler}
            />
            <LoadTesterFormField
              inputRef={refFirstPlayerNumber}
              id="firstPlayerNumber"
              label="First player #"
              type="text"
              defaultValue="1"
              error={firstPlayerNumberError !== undefined}
              helperText={firstPlayerNumberError}
              onChange={firstPlayerNumberChangeHandler}
            />
            <LoadTesterFormField
              inputRef={refLastPlayerNumber}
              id="lastPlayerNumber"
              label="Last player #"
              type="text"
              defaultValue="30"
              error={lastPlayerNumberError !== undefined}
              helperText={lastPlayerNumberError}
              onChange={lastPlayerNumberChangeHandler}
            />
          </>
        )}
        <LoadTesterFormField
          inputRef={refName}
          id="name"
          label="Name"
          type="text"
          defaultValue={initialData?.instanceName || "WindowsLoadTester0"}
          error={nameError !== undefined}
          helperText={nameError}
          onChange={nameChangeHandler}
        />
        <LoadTesterFormField
          inputRef={refTownsfolkClientZip}
          id="townsfolkClientZip"
          label="Townsfolk Client ZIP"
          type="text"
          defaultValue={initialData?.townsfolkClientZip || "TF-1724442306.zip"}
          error={townsfolkClientZipError !== undefined}
          helperText={townsfolkClientZipError || "Name of the Townsfolk.zip file uploaded to S3"}
          onChange={townsfolkClientZipChangeHandler}
        />
        <LoadTesterFormField
          inputRef={refBatchScriptZip}
          id="batchScriptZip"
          label="Batch Script ZIP"
          type="text"
          defaultValue={initialData?.batchScriptZip || "Script-1724759509.zip"}
          error={batchScriptZipError !== undefined}
          helperText={batchScriptZipError || "Name of the zip containing the script.bat file on S3"}
          onChange={batchScriptZipChangeHandler}
        />
        <LoadTesterFormField
          inputRef={refInstanceType}
          id="instanceType"
          label="Instance Type"
          type="text"
          defaultValue={initialData?.instanceType || "c7i.2xlarge"}
          error={instanceTypeError !== undefined}
          helperText={instanceTypeError || "EC2 instance type"}
          onChange={instanceTypeChangeHandler}
        />
        <LoadTesterFormField
          inputRef={refScriptArgs}
          id="scriptArgs"
          label="Script Args"
          type="text"
          defaultValue={
            initialData?.scriptArgs ||
            "none MoverUser_ 1 1 PlayFabPRODSettings -Routines RandomWalk;SendChatMessage;TeleportLoop https://dev-proto.tfk.area1.app/v1/logging/append"
          }
          error={scriptArgsError !== undefined}
          helperText={scriptArgsError || "Arguments to pass to script.bat"}
          onChange={scriptArgsChangeHandler}
        />
        <LoadTesterFormField
          inputRef={refUsername}
          id="username"
          label="Username"
          type="text"
          defaultValue={"LoadTester"}
          error={usernameError !== undefined}
          helperText={usernameError || "Username to access the machine with RDP (cannot be changed)"}
          onChange={usernameChangeHandler}
          disabled
        />
        <LoadTesterFormField
          inputRef={refPassword}
          id="password"
          label="Password"
          type="text"
          defaultValue={"bkeXL7n.g0IRY%xKq*nNjMX=l7Z.KD3i"}
          error={passwordError !== undefined}
          helperText={passwordError || "Password to access the machine with RDP"}
          onChange={passwordChangeHandler}
        />
        <LoadTesterFormField
          inputRef={refVpcId}
          id="vpcId"
          label="VPC ID"
          type="text"
          defaultValue={"vpc-02ae43ee7d7ef141c"}
          error={vpcIdError !== undefined}
          helperText={vpcIdError || "VPC to attach the load-tester instance to"}
          onChange={vpcIdChangeHandler}
        />
        <LoadTesterFormField
          inputRef={refSubnetId}
          id="subnetId"
          label="Subnet ID"
          type="text"
          defaultValue={"subnet-0692c03c3d9166057"}
          error={subnetIdError !== undefined}
          helperText={subnetIdError || "Subnet to attach the load-tester instance to"}
          onChange={subnetIdChangeHandler}
        />
        <LoadTesterFormField
          inputRef={refKeypair}
          id="keypair"
          label="Key-pair"
          type="text"
          defaultValue={"tfk-dev-backend"}
          error={keypairError !== undefined}
          helperText={keypairError || "Key-pair to decrypt the Administrator password of the load-tester"}
          onChange={keypairChangeHandler}
        />
        <LoadTesterFormField
          inputRef={refAutoShutdownDelay}
          id="autoShutdownDelay"
          label="Auto-shutdown delay"
          type="text"
          defaultValue={"120"}
          error={autoShutdownDelayError !== undefined}
          helperText={
            autoShutdownDelayError || "Delay before shutting-down the load-tester automatically (in minutes, 0 to disable)"
          }
          onChange={autoShutdownDelayChangeHandler}
        />
        <LoadTesterFormField
          inputRef={refBranch}
          id="branch"
          label="Branch"
          type="text"
          defaultValue={"FOLK-17246_load-testers-improvements"}
          error={branchError !== undefined}
          helperText={branchError || "Branch where the GitHub workflow file is"}
          onChange={branchChangeHandler}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss}>Cancel</Button>
        <Button onClick={okButtonClickHandler}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};
