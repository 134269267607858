/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Alert, AlertColor, Button, ButtonGroup, Chip, Grid, MenuItem, Snackbar } from "@mui/material";
import {
  AvailableTargetLanguages,
  DataSchemaState,
  IDataStructure,
  ITestDataset,
  PullRequestData,
  useDataSchemaService,
} from "../../services/backend/DataSchemaService";
import { AppContext } from "utils/AppContext";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import UndoIcon from "@mui/icons-material/Undo";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import JavascriptIcon from "@mui/icons-material/Javascript";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import BoltIcon from "@mui/icons-material/Bolt";
import BuildIcon from "@mui/icons-material/Build";
import { StringInputPopup } from "../../components/StringInputPopup";
import { ConnectPoint, UMLGraph } from "organism-react-d3-uml";
import LineLayout from "./LineLayout";
import ConnectPointLayout from "./ConnectPointLayout";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import ConnectPointDefaultLayout from "organism-react-d3-uml/types/ui/molecules/ConnectPointDefaultLayout";
import { YesNoPopup } from "../../components/YesNoPopup";
import TestDatasetEditor from "./TestDatasetEditor";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Routes from "components/routing/Routes";
import Typography from "@mui/material/Typography";
import MigrationScriptEditor from "./MigrationScriptEditor";
import { BackendError } from "services/FetchUtils";
import RunTestDatasetResult, { IRunTestDatasetEntityReport } from "./RunTestDatasetResult";
import { downloadFile, fileNameFromContentDisposition } from "../../utils/Download";

interface UMLTable {
  name: string;
  cols: string[];
}

interface UMLConnectionPoint {
  table: string;
  col: string;
}

interface UMLConnection {
  from: UMLConnectionPoint;
  to: UMLConnectionPoint;
}

interface UMLData {
  tables: UMLTable[];
  conns: UMLConnection[];
}

export interface EditDataStructureProps extends IDataStructure {
  branch: string;
}

export interface CreateDataStructureProps {
  branch: string;
  tfidTypes: number[];
}

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const DataSchemaEditor = () => {
  const dataSchemaService = useDataSchemaService();

  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [branches, setBranches] = useState<string[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<string | undefined>(undefined);
  const [createBranchFormVisible, setCreateBranchFormVisible] = useState<boolean>(false);
  const [structures, setStructures] = useState<IDataStructure[]>([]);
  const [branchState, setBranchState] = useState<DataSchemaState | undefined>(undefined);
  const [pullRequest, setPullRequest] = useState<PullRequestData | undefined>(undefined);
  const [submitBranchFormVisible, setSubmitBranchFormVisible] = useState<boolean>(false);
  const [umlData, setUmlData] = useState<UMLTable | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [testDatasets, setTestDatasets] = useState<ITestDataset[]>([]);
  const [selectedTestDataset, setSelectedTestDataset] = useState<ITestDataset | undefined>(undefined);
  const [createTestDatasetFormVisible, setCreateTestDatasetFormVisible] = useState<boolean>(false);
  const [deleteTestDatasetPopupVisible, setDeleteTestDatasetPopupVisible] = useState<boolean>(false);
  const [testDatasetEditorVisible, setTestDatasetEditorVisible] = useState<boolean>(false);
  const [selectedStructure, setSelectedStructure] = useState<IDataStructure | undefined>(undefined);
  const [migrationScriptEditorVisible, setMigrationScriptEditorVisible] = useState(false);
  const [runTestDatasetResultReport, setRunTestDatasetResultReport] = useState<IRunTestDatasetEntityReport[]>([]);
  const [runTestDatasetResultVisible, setRunTestDatasetResultVisible] = useState(false);
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const location = useLocation();
  const [scriptData, setScriptData] = useState("");

  const migrationScriptTemplate = `
/* Sample Migration Script
(db) => ({
    migrate: (entity) => {
      if (entity.newProp === undefined) {
        entity.newProp = false;
      }
      return entity;
    },
    rollback: (entity) => {
        return entity;
    }
})
*/`;
  const fetchBranches = async () => {
    try {
      setIsLoading(true);
      const { list } = await dataSchemaService.listDataSchemaBranches();
      setBranches(["main"].concat(...list));
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not fetch the list of data-schema branches.");
      setAlertSeverity("error");
      setBranches([]);
    } finally {
      setIsLoading(false);
    }
  };

  const loadBranch = async (branch: string) => {
    try {
      setIsLoading(true);
      const { dataStructures, state, pullRequest } = await dataSchemaService.loadBranch(branch);
      setStructures(dataStructures);
      setBranchState(state as DataSchemaState);
      setPullRequest(pullRequest);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not load data-schema structures.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTestDatasets = async (branch: string) => {
    try {
      setIsLoading(true);
      const { list } = await dataSchemaService.listTestDatasets(branch);
      setTestDatasets(list);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not list test datasets.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (appContext?.selectedEnvironment) {
      (async () => fetchBranches())();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext?.selectedEnvironment]);

  useEffect(() => {
    if (branches.length > 0 && selectedBranch !== undefined) {
      (async () => loadBranch(selectedBranch))();
      (async () => fetchTestDatasets(selectedBranch))();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches, selectedBranch]);

  useEffect(() => {
    if (branches.length > 0 && selectedBranch !== undefined) {
      (async () => loadBranch(selectedBranch))();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  const uml = useRef<UMLGraph | null>(null);
  const handleUml = (element: UMLGraph) => {
    uml.current = element;
  };

  useEffect(() => {
    if (structures === undefined) {
      return undefined;
    }
    const data: UMLData = {
      tables: [] as UMLTable[],
      conns: [] as UMLConnection[],
    };
    for (const s of structures) {
      if (!s.archived) {
        const cols = [];
        if (s.schema && s.schema.properties) {
          for (const pName in s.schema.properties) {
            cols.push(pName);
            const property = s.schema.properties[pName];
            if (property.description) {
              const i = property.description.indexOf("key:");
              if (i > -1) {
                const [linkedTable, linkedCol] = property.description.substring(i + 4).split(".");
                data.conns.push({
                  from: { table: s.name, col: pName },
                  to: { table: linkedTable, col: linkedCol },
                });
              }
            }
          }
        }
        data.tables.push({ name: s.name, cols });
      }
      setUmlData(undefined);
      // UMLGraph doesn't properly refresh the connection lines when umlData is updated directly.
      // This workaround forces the recreation of the component with updated data.
      setTimeout(setUmlData, 100, data);
    }
  }, [structures]);

  const alertCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(undefined);
  };

  const createBranchButtonClickHandler = () => {
    setCreateBranchFormVisible(true);
  };

  const createBranchFormDismissHandler = () => {
    setCreateBranchFormVisible(false);
  };

  const createBranchFormAcceptHandler = async (name: string) => {
    try {
      setIsLoading(true);
      setCreateBranchFormVisible(false);
      await dataSchemaService.createDataSchemaBranch(name);
      await fetchBranches();
      setTimeout(setSelectedBranch, 1000, name); // Cannot be done immediately, causes errors in D3
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not create the new data-schema branch.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const viewTestDatasetButtonClickHandler = () => {
    setTestDatasetEditorVisible(true);
  };

  const runTestDatasetButtonClickHandler = async () => {
    if (!selectedBranch || !selectedTestDataset) {
      return;
    }

    try {
      setIsLoading(true);
      const res = await dataSchemaService.runTestDataset(selectedBranch, selectedTestDataset.name);
      setRunTestDatasetResultReport(res.list);
      setRunTestDatasetResultVisible(true);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not test the dataset.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const createTestDatasetButtonClickHandler = () => {
    setCreateTestDatasetFormVisible(true);
  };

  const submitBranchButtonClickHandler = async () => {
    setSubmitBranchFormVisible(true);
  };

  const submitBranchFormDismissHandler = () => {
    setSubmitBranchFormVisible(false);
  };

  const submitBranchFormAcceptHandler = async (prTitle: string) => {
    if (!selectedBranch) {
      return;
    }
    try {
      setIsLoading(true);
      setSubmitBranchFormVisible(false);
      await dataSchemaService.submitBranch(selectedBranch, prTitle);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not submit this branch.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
    await loadBranch(selectedBranch);
  };

  const compileBranchButtonClickHandler = async () => {
    if (!selectedBranch) {
      return;
    }
    try {
      setIsLoading(true);
      await dataSchemaService.compileBranch(selectedBranch);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not compile this branch.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const mergeBranchButtonClickHandler = async () => {
    if (!selectedBranch) {
      return;
    }
    try {
      setIsLoading(true);
      await dataSchemaService.mergeBranch(selectedBranch);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not merge this branch.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
    await loadBranch(selectedBranch);
  };

  const createTestDatasetFormDismissHandler = () => {
    setCreateTestDatasetFormVisible(false);
  };

  const createTestDatasetFormAcceptHandler = async (name: string) => {
    if (!selectedBranch) {
      return;
    }
    try {
      setIsLoading(true);
      setCreateTestDatasetFormVisible(false);
      await dataSchemaService.createTestDataset(selectedBranch, name);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not create the new test dataset.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
    await fetchTestDatasets(selectedBranch);
  };

  const deleteTestDatasetButtonClickHandler = () => {
    setDeleteTestDatasetPopupVisible(true);
  };

  const deleteTestDatasetFormNoHandler = () => {
    setDeleteTestDatasetPopupVisible(false);
  };

  const editDataStructureHandler = (dataStructure: IDataStructure | undefined) => {
    if (dataStructure) {
      if (dataStructure.archived) {
        setAlertMessage("Data structure is archived.");
        setAlertSeverity("warning");
        return;
      }
      navigate(Routes.dataStructureEditor, dataStructure ? { state: { branch: selectedBranch, ...dataStructure } } : {});
    }
  };

  const archiveOrReviveDataStructureHandler = async (dataStructure: IDataStructure | undefined) => {
    if (dataStructure && selectedBranch) {
      try {
        setIsLoading(true);
        if (!dataStructure.archived) {
          await dataSchemaService.archiveDataStructure(selectedBranch, dataStructure.name);
        } else {
          await dataSchemaService.reviveDataStructure(selectedBranch, dataStructure.name);
        }
        setSelectedStructure({ ...dataStructure, archived: !dataStructure.archived });
        await loadBranch(selectedBranch);
      } catch (e: any) {
        setAlertMessage(e.debugMessage || "Could not update the data structure.");
        setAlertSeverity("error");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchMigrationScript = async (branch: string, name: string) => {
    try {
      const { content } = await dataSchemaService.getMigrationScript(branch, name);
      setScriptData(content ? content : migrationScriptTemplate);
    } catch (error) {
      const backError = error as BackendError;
      if (backError.getResponse().status == 404) {
        setScriptData(migrationScriptTemplate);
      } else {
        console.log(error);
      }
    }
  };

  const saveMigrationScriptHandler = async () => {
    try {
      if (selectedBranch && selectedStructure) {
        setIsLoading(true);
        await dataSchemaService.setMigrationScript(selectedBranch, selectedStructure.name, scriptData);
        setMigrationScriptEditorVisible(false);
      }
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to set/update migration script.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const editMigrationScriptHandler = async (dataStructure: IDataStructure | undefined) => {
    if (dataStructure && selectedBranch) {
      if (dataStructure.archived) {
        setAlertMessage("Data structure is archived.");
        setAlertSeverity("warning");
        return;
      }
      setIsLoading(true);
      await fetchMigrationScript(selectedBranch, dataStructure.name);
      setIsLoading(false);
      setMigrationScriptEditorVisible(true);
    }
  };

  const createDataStructureHandler = () => {
    navigate(Routes.dataStructureEditor, {
      state: { branch: selectedBranch, tfidTypes: structures.map((s) => s.tfidType) },
    });
  };

  const deleteTestDatasetFormYesHandler = async () => {
    if (!selectedBranch || !selectedTestDataset) {
      return;
    }
    try {
      setIsLoading(true);
      setDeleteTestDatasetPopupVisible(false);
      await dataSchemaService.deleteTestDataset(selectedBranch, selectedTestDataset.name);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not delete the new test dataset.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
    await fetchTestDatasets(selectedBranch);
  };

  const testDatasetUpdateHandler = (testDataset: ITestDataset) => {
    const updatedDatasets: ITestDataset[] = [];
    for (const t of testDatasets) {
      if (t.name === testDataset.name) {
        updatedDatasets.push(testDataset);
      } else {
        updatedDatasets.push(t);
      }
    }
    setTestDatasets(updatedDatasets);
    if (selectedTestDataset && selectedTestDataset.name === testDataset.name) {
      setSelectedTestDataset(testDataset);
    }
  };

  const downloadDtoHandler = async (
    dataStructures: string[],
    targetLanguage: AvailableTargetLanguages,
    extension: string,
  ) => {
    if (!selectedBranch) {
      return;
    }

    try {
      setIsLoading(true);

      const res = await dataSchemaService.generateDtos(selectedBranch, dataStructures, targetLanguage);

      let fileName = `dtos-${extension}.zip`;
      if (dataStructures.length === 1) {
        fileName = `${dataStructures[0]}.${extension}`;
      }

      // try to get file name from content-disposition header
      const contentDispositionHeader = res.headers.get("content-disposition");
      if (contentDispositionHeader) {
        const contentDispositionFileName = fileNameFromContentDisposition(contentDispositionHeader);
        fileName = contentDispositionFileName ? contentDispositionFileName : fileName;
      }

      const content = await res.blob();
      downloadFile(content, fileName);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not download the DTOs for this data-structure.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const context = useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Data Schema Editor");
    context?.setTargetAPI("environment");
  }, [context]);

  // This is the only way to override the ConnectPointDefaultLayout to my knowledge.
  // cf. https://github.com/react-atomic/react-atomic-organism/blob/main/packages/organism-react-d3-uml/src/ui/organisms/ConnectPoint.jsx#L17
  ConnectPoint.defaultProps = {
    component: ConnectPointLayout as typeof ConnectPointDefaultLayout,
  };

  const navigateToPullRequest = (pr: PullRequestData | undefined) => {
    if (pr) {
      window.open(`https://github.com/FortisGames/proj-townsfolk-data-schema/pull/${pr.number}`);
    }
  };

  const branchChip = useMemo(() => {
    switch (branchState) {
      case DataSchemaState.DRAFT:
        return <Chip label="Draft" variant="filled" size="small" color="warning" />;
      case DataSchemaState.IN_REVIEW:
        return (
          <Chip
            label="In review"
            variant="filled"
            size="small"
            color="warning"
            onClick={() => navigateToPullRequest(pullRequest)}
          />
        );
      case DataSchemaState.APPROVED:
        return (
          <Chip
            label="Approved"
            variant="filled"
            size="small"
            color="success"
            onClick={() => navigateToPullRequest(pullRequest)}
          />
        );
      case DataSchemaState.MERGED:
        return (
          <Chip
            label="Merged"
            variant="filled"
            size="small"
            color="success"
            onClick={() => navigateToPullRequest(pullRequest)}
          />
        );
      case DataSchemaState.DISCARDED:
        return (
          <Chip
            label="Discarded"
            variant="filled"
            size="small"
            color="error"
            onClick={() => navigateToPullRequest(pullRequest)}
          />
        );
      default:
        return <Chip label="Unknown" variant="filled" size="small" color="warning" />;
    }
  }, [branchState, pullRequest]);

  if (location.pathname.includes(Routes.dataStructureEditor)) {
    return <Outlet />;
  }

  return (
    <>
      <Header>
        <Button variant="contained" startIcon={<AddIcon />} onClick={createBranchButtonClickHandler} sx={{ marginRight: 1 }}>
          Create
        </Button>
        <TextField
          id="branch-selector"
          select
          label="Branch"
          value={selectedBranch ?? ""}
          onChange={(event) => setSelectedBranch(event.target.value)}
          sx={{
            background: "white",
            minWidth: "200px",
          }}
          variant="filled"
        >
          <MenuItem key={""} value={""} />
          {branches.map((branch) => (
            <MenuItem key={branch} value={branch}>
              {branch}
            </MenuItem>
          ))}
        </TextField>
        {branchState !== undefined && selectedBranch !== "main" && (
          <>
            <Typography variant="body1" component="div" sx={{ marginLeft: 1, marginRight: 1 }}>
              Branch state:
            </Typography>
            {branchChip}
            {((branchState === DataSchemaState.APPROVED || branchState === DataSchemaState.IN_REVIEW) && (
              <Button
                variant="contained"
                startIcon={<KeyboardTabIcon />}
                onClick={mergeBranchButtonClickHandler}
                sx={{ marginLeft: 1 }}
                disabled={branchState !== DataSchemaState.APPROVED}
              >
                Merge
              </Button>
            )) || (
              <Button
                variant="contained"
                startIcon={<KeyboardTabIcon />}
                onClick={submitBranchButtonClickHandler}
                sx={{ marginLeft: 1 }}
                disabled={branchState !== DataSchemaState.DRAFT && branchState !== DataSchemaState.DISCARDED}
              >
                Submit
              </Button>
            )}
          </>
        )}
        <ButtonGroup variant="contained" sx={{ marginLeft: 1 }}>
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={() => downloadDtoHandler([], AvailableTargetLanguages.TYPESCRIPT, "ts")}
            disabled={selectedBranch === undefined}
          >
            DTOS (TS)
          </Button>
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={() => downloadDtoHandler([], AvailableTargetLanguages.CSHARP, "cs")}
            disabled={selectedBranch === undefined}
          >
            DTOS (CS)
          </Button>
          <Button
            variant="contained"
            startIcon={<BuildIcon />}
            onClick={compileBranchButtonClickHandler}
            sx={{ marginLeft: 1 }}
            disabled={selectedBranch === undefined}
          >
            Compile
          </Button>
        </ButtonGroup>
        <div style={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={createTestDatasetButtonClickHandler}
          sx={{ marginRight: 1 }}
          disabled={selectedBranch === undefined || selectedBranch === "main"}
        >
          Create
        </Button>
        <TextField
          id="dataset-selector"
          select
          label="Test Dataset"
          value={selectedTestDataset?.name ?? ""}
          onChange={(event) => {
            console.log({ testDatasets });
            const testDataset = testDatasets.find((t) => t.name === event.target.value);
            console.log({ testDataset });
            setSelectedTestDataset(testDataset);
          }}
          sx={{
            background: "white",
            minWidth: "200px",
          }}
          variant="filled"
        >
          <MenuItem key={""} value={""} />
          {testDatasets.map((testDataset) => (
            <MenuItem key={testDataset.name} value={testDataset.name}>
              {testDataset.name}
            </MenuItem>
          ))}
        </TextField>
        <Button
          variant="contained"
          startIcon={<TextSnippetIcon />}
          onClick={viewTestDatasetButtonClickHandler}
          sx={{ marginLeft: 1 }}
          disabled={selectedBranch === undefined || selectedTestDataset === undefined}
        >
          View
        </Button>
        <Button
          variant="contained"
          startIcon={<BoltIcon />}
          onClick={runTestDatasetButtonClickHandler}
          sx={{ marginLeft: 1 }}
          disabled={selectedBranch === undefined || selectedTestDataset === undefined}
        >
          Test
        </Button>
        <Button
          variant="contained"
          startIcon={<DeleteIcon />}
          onClick={deleteTestDatasetButtonClickHandler}
          sx={{ marginLeft: 1 }}
          disabled={selectedBranch === undefined || selectedTestDataset === undefined}
        >
          Delete
        </Button>
      </Header>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {umlData !== undefined ? (
            <UMLGraph
              autoArrange={true}
              ref={handleUml}
              data={umlData}
              lineDefaultProps={{ curve: true, component: LineLayout }}
              connsLocator={(d: UMLData) => d.conns}
              connFromBoxGroupLocator={(d: UMLConnection) => d.from.table}
              connFromBoxLocator={(d: UMLConnection) => d.from.col}
              connToBoxGroupLocator={(d: UMLConnection) => d.to.table}
              connToBoxLocator={(d: UMLConnection) => d.to.col}
              style={{
                backgroundColor: "#ffffff3f",
                border: "2px solid #7773",
                borderRadius: 8,
                marginTop: 16,
                height: "68vh",
              }}
              onEdit={(e: unknown) => {
                if (selectedBranch === "main") {
                  return;
                }
                const dataStructure = structures.find((t) => t.name === e);
                setSelectedStructure(dataStructure);
                editDataStructureHandler(dataStructure);
              }}
              onDel={(e: unknown) => {
                if (selectedBranch === "main") {
                  return;
                }
                const dataStructure = structures.find((t) => t.name === e);
                if (dataStructure && !dataStructure.archived) {
                  setSelectedStructure(dataStructure);
                  archiveOrReviveDataStructureHandler(dataStructure);
                }
              }}
            />
          ) : (
            <div
              style={{
                backgroundColor: "#ffffff3f",
                border: "2px solid #7773",
                borderRadius: 8,
                marginTop: 16,
                height: "68vh",
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} container justifyContent={"flex-start"}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={createDataStructureHandler}
            sx={{ marginRight: 1 }}
            disabled={selectedBranch === "main"}
          >
            New Data Structure
          </Button>
          <TextField
            id="datastructure-selector"
            select
            label="Data Structures"
            onChange={(event) => {
              const dataStructure = structures.find((t) => t.name === event.target.value);
              setSelectedStructure(dataStructure);
            }}
            sx={{
              background: "white",
              minWidth: "240px",
            }}
            variant="filled"
            value={selectedStructure?.name ?? ""}
          >
            <MenuItem key={""} value={""} />
            {structures.map((structure) => (
              <MenuItem key={structure.name} value={structure.name}>
                {structure.name}
              </MenuItem>
            ))}
          </TextField>
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() => editDataStructureHandler(selectedStructure)}
            sx={{ marginLeft: 1 }}
            disabled={selectedStructure === undefined || selectedStructure.archived || selectedBranch === "main"}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            startIcon={selectedStructure?.archived ? <UndoIcon /> : <ArchiveIcon />}
            onClick={() => archiveOrReviveDataStructureHandler(selectedStructure)}
            sx={{ marginLeft: 1 }}
            disabled={selectedStructure === undefined || selectedBranch === "main"}
          >
            {selectedStructure?.archived ? "Revive" : "Archive"}
          </Button>
          <Button
            variant="contained"
            startIcon={<JavascriptIcon />}
            onClick={() => editMigrationScriptHandler(selectedStructure)}
            sx={{ marginLeft: 1 }}
            disabled={selectedStructure === undefined || selectedBranch === "main"}
          >
            Migration Script
          </Button>
          <ButtonGroup variant="contained" sx={{ marginLeft: 1 }}>
            <Button
              disableElevation
              startIcon={<DownloadIcon />}
              onClick={() => downloadDtoHandler([selectedStructure?.name ?? ""], AvailableTargetLanguages.TYPESCRIPT, "ts")}
              disabled={selectedStructure === undefined}
            >
              DTO (TS)
            </Button>
            <Button
              disableElevation
              startIcon={<DownloadIcon />}
              onClick={() => downloadDtoHandler([selectedStructure?.name ?? ""], AvailableTargetLanguages.CSHARP, "cs")}
              disabled={selectedStructure === undefined}
            >
              DTO (CS)
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      <StringInputPopup
        visible={createBranchFormVisible}
        title="New Data-Schema Branch"
        message="Please enter a name for the branch:"
        onDismiss={createBranchFormDismissHandler}
        onAccept={createBranchFormAcceptHandler}
      />
      <StringInputPopup
        visible={submitBranchFormVisible}
        title="Submit Data-Schema Branch"
        message="Please enter a PR title for this submission:"
        onDismiss={submitBranchFormDismissHandler}
        onAccept={submitBranchFormAcceptHandler}
      />
      <StringInputPopup
        visible={createTestDatasetFormVisible}
        title="New Test Dataset"
        message="Please enter a name for the test dataset:"
        onDismiss={createTestDatasetFormDismissHandler}
        onAccept={createTestDatasetFormAcceptHandler}
      />
      {selectedTestDataset !== undefined && (
        <YesNoPopup
          visible={deleteTestDatasetPopupVisible}
          title={"Delete Test Dataset"}
          message={`Are you sure you want to delete the test dataset named <b>${selectedTestDataset.name}</b>?`}
          onNo={deleteTestDatasetFormNoHandler}
          onYes={deleteTestDatasetFormYesHandler}
        />
      )}
      {testDatasetEditorVisible && selectedBranch !== undefined && selectedTestDataset !== undefined && (
        <TestDatasetEditor
          visible={true}
          branch={selectedBranch}
          testDataset={selectedTestDataset}
          onClose={() => setTestDatasetEditorVisible(false)}
          onUpdate={testDatasetUpdateHandler}
        />
      )}
      {selectedStructure !== undefined && selectedBranch !== undefined && (
        <MigrationScriptEditor
          visible={migrationScriptEditorVisible}
          script={scriptData}
          dataStructure={selectedStructure}
          onClose={() => setMigrationScriptEditorVisible(false)}
          onSave={saveMigrationScriptHandler}
          setScriptData={setScriptData}
        />
      )}
      {selectedBranch !== undefined && selectedTestDataset !== undefined && (
        <RunTestDatasetResult
          visible={runTestDatasetResultVisible}
          branch={selectedBranch}
          dataset={selectedTestDataset?.name || ""}
          report={runTestDatasetResultReport}
          onClose={() => setRunTestDatasetResultVisible(false)}
        />
      )}
      <Snackbar open={alertMessage !== undefined} autoHideDuration={5000} onClose={alertCloseHandler}>
        <Alert onClose={alertCloseHandler} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {isLoading && <LoadingOverlay />}
    </>
  );
};

export default DataSchemaEditor;
