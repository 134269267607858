/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  AlertColor,
  Collapse,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { AppContext } from "../../../utils/AppContext";
import { EnvironmentSelector } from "components/EnvironmentSelector";
import { IEnvironment } from "services/registry/EnvironmentService";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useLoggingService } from "services/backend/LoggingService";
import LogEventTable from "views/Logging/CloudWatch/LogEventTable";
import { stableSort, getComparator } from "utils/Table";

const LogViewer = () => {
  const loggingService = useLoggingService();
  const [selectedEnvironment, setSelectedEnvironment] = useState<IEnvironment | undefined>(undefined);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [logStreamList, setLogStreamList] = useState<any[]>([]);

  const alertCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(undefined);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(() => {
    return stableSort(logStreamList, getComparator("desc", "creationTime")).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [logStreamList, page, rowsPerPage]);

  const fetchLogStreamList = async () => {
    try {
      const envName = selectedEnvironment?.name;
      if (envName) {
        const { list } = await loggingService.listEnvironmentStreams(envName ?? "");
        setLogStreamList(list);
      }
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not fetch the list of log streams.");
      setAlertSeverity("error");
      setLogStreamList([]);
    }
  };

  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Backend Logs");
    context?.setTargetAPI("environment");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.selectedEnvironment]);

  useEffect(() => {
    if (selectedEnvironment && context?.selectedEnvironment) {
      (async () => fetchLogStreamList())();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEnvironment, context?.selectedEnvironment]);

  function Row(props: { row: any }) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    const toggleCollapse = (event: React.MouseEvent) => {
      event.stopPropagation();
      setOpen(!open);
    };

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell width={15}>
            <IconButton aria-label="expand row" size="small" onClick={toggleCollapse}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" align="left">
            {row.logStreamName}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} align="left">
            <Collapse in={open} timeout="auto" unmountOnExit>
              <LogEventTable
                streamName={row.logStreamName}
                environmentName={selectedEnvironment?.name ?? ""}
              ></LogEventTable>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <Box style={{ width: "100%" }}>
        <EnvironmentSelector
          id="logging-view"
          selectedEnvironment={selectedEnvironment}
          onEnvironmentSelected={setSelectedEnvironment}
          useStorage={false}
        />
        <TableContainer component={Paper} sx={{ marginTop: 1 }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="left">Log Stream</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows.map((row: any) => (
                <Row key={row.logStreamName} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={logStreamList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={pageChangeHandler}
          onRowsPerPageChange={rowsPerPageChangeHandler}
        />
      </Box>
      <Snackbar open={alertMessage !== undefined} autoHideDuration={5000} onClose={alertCloseHandler}>
        <Alert onClose={alertCloseHandler} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LogViewer;
